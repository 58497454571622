import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { Box, Grid, IconButton } from "@material-ui/core";
import HtSearchBar from "../components/HtSearchBar";
import HtTable from "../components/HtTable";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import { assetFilterOps, HtTableHead } from "./HtTableHead";
import { EmptyCollection, PageLoader, AccessDenied, } from "../../../../shared_elements";
import HtList from "../components/HtList";
import { deleteHtAssetDetailRow, fillHtEditFormApi, getHtApi, importHtFileApi, UpdateHtTaskFormApi, uploadHtFileApi, exportHtAssetDetailListApi, DownloadAllApi, } from "../apiService";
import BasicDetailHeader from "../../BasicDetailHeader";
import { Link } from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import { Tooltip } from "@material-ui/core";
import { assetType } from "../../../../constants";
import FlightIcon from '@material-ui/icons/Flight';
import config from '../../../../config'

import { trackActivity } from '../../../../utils/mixpanel';

class HardTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addFormDialog: false,
			sidebarOpen: true,
			tabIndex: 0,
			pageLoader: false,
			sort: "",
			sort_by: "",
			assetsDetail: {
				list: [],
				pagination: {},
				asset_count: {},
				new_task_last_used_folder_uuid: '',
				last_used_folder_uuid_link_document: '',
				checkbox_last_used_folder_uuid: '',
				checkbox_last_used_folder_name: ''
			},
			headerData: {},
			headerTsn: "",
			headerCsn: "",
			currentRecordPerPage: 50,
			uuid: "",
			slug:''
		};
		this.getHtApi = getHtApi.bind(this);
		this.openAddForm = this.openAddForm.bind(this);
		this.closeAddForm = this.closeAddForm.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
		this.handleDeleteRow = deleteHtAssetDetailRow.bind(this);
		this.handleEditRow = fillHtEditFormApi.bind(this);
		this.UpdateHtTaskFormApi = UpdateHtTaskFormApi.bind(this);
		this.uploadHtFileApi = uploadHtFileApi.bind(this);
		this.importHtFileApi = importHtFileApi.bind(this);
		this.downloadAllApi = DownloadAllApi.bind(this);
		this.exportHtAssetDetailListApi = exportHtAssetDetailListApi.bind(this);
	}

	componentDidMount() {
		this.getData();
		this.pullData();
		trackActivity('CAMO', {
			event_type: 'Page Visited',
			page_title: 'Hard Time(HT)',
		  })
	}
	pullData = (slug,tsn, csn,) => {
		this.setState({ slug: slug });
		this.setState({ headerTsn: tsn });
		this.setState({ headerCsn: csn });
	};
	uuidData = (uuid) => {
		this.setState({ uuid: uuid });
	}

	getData(item) {
		this.getHtApi({ per_page: this.state.currentRecordPerPage }, "skeletonLoader", this.props, item ? item : null);
	}

	openAddForm() {
		this.setState({ addFormDialog: true });
	}
	closeAddForm() {
		this.setState({ addFormDialog: false });
	}

	toggleSidebar() {
		this.setState({ sidebarOpen: !this.state.sidebarOpen });
	}
	createSortHandler = (sortField) => {
		const { sort, sort_by, filter, tabIndex, assetsDetail } = this.state;
		this.setState({
			sort: sortField,
			sort_by: sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "desc",
		})
		if (sortField === sort) {
			if (sort_by === "asc") {
				this.getHtApi({ ...filter, sort: sortField, sort_by: "desc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
			} else {
				this.getHtApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
			}
		} else {
			this.getHtApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
		}
	};

	submitFilter = (filterOptions) => {
		const { tabIndex, applyingFilter } = this.state;
		this.getHtApi({ ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), asset_type: tabIndex, }, "pageLoader", this.props);
	};

	searchResults = (item) => {
		if (item !== null) {
			this.getData(item);
		}
	};

	updateFormData = async (updateId, updatedData, props) => {
		await this.UpdateHtTaskFormApi(updateId, updatedData, props, this.state.currentRecordPerPage);
	};

	fileUploadData = async (uploadId, formdata, props, haveAttachments) => {
		await this.uploadHtFileApi(uploadId, formdata, props, this.state.currentRecordPerPage, haveAttachments);
	};

	importFile = (formData, props, isXLSX) => {
		this.importHtFileApi(formData, props, isXLSX, this.state.currentRecordPerPage);
	};

	exportAssetDetailListFunc = (props) => {
		this.exportHtAssetDetailListApi(props);
	};

	downloadAllFilesApi = (props) => {
		this.downloadAllApi(props);
	};

	refreshComp = () => {
		this.getData();
	};

	render() {
		const { tabIndex, applyingFilter, sort, sort_by, assetsDetail, pageLoader, uuid ,slug} = this.state;
		const { aircraftTypes, engineTypes, lessees } = this.props;

		let filterOptions = assetFilterOps;
		if (tabIndex === 0 || tabIndex === 1) {
			filterOptions = {
				...filterOptions,
				aircraft_type: {
					...filterOptions.aircraft_type,
					options: aircraftTypes,
				},
			};
			if (tabIndex === 1) {
				delete filterOptions.engine_type;
			}
		}
		if (tabIndex === 0 || tabIndex === 2) {
			filterOptions = {
				...filterOptions,
				engine_type: {
					...filterOptions.engine_type,
					options: engineTypes,
				},
			};
			if (tabIndex === 2) {
				delete filterOptions.aircraft_type;
			}
		}

		filterOptions = {
			...filterOptions,
			lessee: {
				...filterOptions.lessee,
				options: lessees,
			},
		};

		return (
			<section className="amp-listing-section">
				{pageLoader ? <PageLoader /> : null}
				<Grid className="mt-0" spacing={3} container>
					<Grid className="pt-0 pr-0" xs={12} item>
						<Box display="flex" alignItems="center">
							<Box flexGrow={1}>
								<ul className='list-inline'>
									<li className='list-inline-item'> <h3 className="module-heading">Hard Time Components</h3></li>
									<li className='list-inline-item'> <a href={`${config.domain.subDomian}technical-specification/${assetType[this.props?.match?.params?.assetType]}/${slug}`} target="_blank"> <h3 className="module-heading"><FlightIcon fontSize='small' color='primary' style={{ verticalAlign: "bottom", marginLeft: "30px" }} />Technical Specification</h3></a></li>
								</ul>
							</Box>
							<Box className="mr-18">
								<Link to="/dashboard?ht_Module">
									<Tooltip title="Analytics">
										<IconButton className="p-5px" color="primary">
											<PieChartOutlinedIcon />
										</IconButton>
									</Tooltip>
								</Link>
								<Tooltip title="Refresh">
									<IconButton
										className="p-5px"
										color="primary"
										onClick={this.refreshComp}
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
						<BasicDetailHeader props={this.props} func={this.pullData} uuid={this.uuidData} module='ht' />
						{permissionCheckFnforCamo({ primaryKey: "ht_component", keyIndex: "R", }) ? (
							<div className="custom-border">
								<HtSearchBar
									currentRecordPerPage={this.state.currentRecordPerPage}
									applyingFilter={applyingFilter}
									filterOptions={filterOptions}
									tabIndex={tabIndex}
									pageLoader={"pageLoader"}
									props={this.props}
									uuid={uuid}
									getResponseBack={this.refreshComp}
									headerTsn={this.state.headerTsn}
									headerCsn={this.state.headerCsn}
									importFile={this.importFile}
									fileUploadData={this.fileUploadData}
									searchData={(e) => this.searchResults(e.target.value)}
									exportReportFn={(file) =>
										this.exportAssetDetailListFunc(
											{
												download: file.extension,
												...{
													...convertFilterObject(
														removeEmptyKey(applyingFilter),
														filterOptions
													),
													asset_type: tabIndex,
												},
											},
											"pageLoader"
										)
									}
								/>
								<HtTable
									currentRecordPerPage={this.state.currentRecordPerPage}
									heads={HtTableHead}
									sort={sort}
									sort_by={sort_by}
									data={assetsDetail.list.map((item, index) => (
										<HtList
											currentRecordPerPage={this.state.currentRecordPerPage}
											key={index}
											item={item}
											index={index}
											uuid={uuid}
											getResponseBack={this.refreshComp}
											last_used_folder_uuid={assetsDetail.new_task_last_used_folder_uuid}
											last_used_folder_uuid_link_document={assetsDetail.last_used_folder_uuid_link_document}
											checkbox_last_used_folder_uuid={assetsDetail.checkbox_last_used_folder_uuid}
											checkbox_last_used_folder_name={assetsDetail.checkbox_last_used_folder_name}
											headerTsn={this.state.headerTsn}
											headerCsn={this.state.headerCsn}
											handleDeleteRow={(data) =>
												this.handleDeleteRow(
													item,
													this.props,
													this.state.currentRecordPerPage
												)
											}
											updateFormData={this.updateFormData}
											handleEditRow={(data) =>
												this.handleEditRow(item, this.props)
											}
											props={this.props}
											fileUploadData={this.fileUploadData}
											downloadAllApi={(file) =>
												this.downloadAllFilesApi(
													{
														download: file.extension,
														...{
															...convertFilterObject(
																removeEmptyKey(applyingFilter),
																filterOptions
															),
															asset_type: tabIndex,
															item,
														},
													},

													"pageLoader"
												)
											}
										/>
									))}
									createSortHandler={this.createSortHandler}
									pagination={assetsDetail.pagination}
									onChangePage={(event, newPage) =>
										this.getHtApi(
											{
												...convertFilterObject(
													removeEmptyKey({
														...applyingFilter,
														asset_type: this.state.tabIndex,
														per_page: assetsDetail.pagination.per_page,
														page: newPage + 1,
													}),
													filterOptions
												),
											},
											"pageLoader",
											this.props
										)
									}
									onChangeRowsPerPage={(event) =>
										this.setState(
											{ currentRecordPerPage: event.target.value },
											() => {
												this.getHtApi(
													{
														...convertFilterObject(
															removeEmptyKey({
																...applyingFilter,
																asset_type: this.state.tabIndex,
																per_page: this.state.currentRecordPerPage,
															}),
															filterOptions
														),
													},
													"pageLoader",
													this.props
												);
											}
										)
									}
									noRecord={
										assetsDetail.list.length ? null : !this.state.pageLoader ? (
											<EmptyCollection title="No Records Found" />
										) : null
									}
								/>
							</div>
						) : (
							<AccessDenied />
						)}
					</Grid>
				</Grid>
			</section>
		);
	}
}
export default withSnackbar(HardTime);
