import React, { useEffect } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Grid, Tooltip, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close"
import SaveAltIcon from "@material-ui/icons/SaveAlt";


const ShowAttachments = ({ handleClose, handleShowAttachments, openAttachment, downloadAllApi, item, files, removeAttachment }) => {
	const downloadAll = (event, files, triggerExport) => {
		if (triggerExport) {
			downloadAllApi(files);
		}
	};

	return (
		<div class="getAttachmentDialogue">
			<DialogTitle>Attachments</DialogTitle>
			<Box position="absolute" top={0} right={0}>
				<IconButton> <Close onClick={handleClose} style={{ fill: "white" }} /> </IconButton>
			</Box>
			<DialogContent className="showAttachmentList">
				{handleShowAttachments?.length > 0 ?
					Object.keys(handleShowAttachments).map((key1, index) => {
						const file = handleShowAttachments[key1].path || handleShowAttachments[key1].file;
						return (
							<Grid container spacing={3}>
								<Grid item xs={10}>
									<p>{handleShowAttachments[key1].name}</p>
								</Grid>
								<Grid item xs={1}>
									<Tooltip title="Download">
										<button className="downloadIcon" onClick={() => { openAttachment(file); }} > <SaveAltIcon key={key1} color="primary" /> </button>
									</Tooltip>
								</Grid>
								<Grid item xs={1}>
									<Tooltip title="Remove">
										<button className="downloadIcon" onClick={() => { removeAttachment(item, handleShowAttachments, handleShowAttachments[key1].id, handleShowAttachments[key1].uuid) }} > <CloseIcon key={key1} color="secondary" /> </button>
									</Tooltip>
								</Grid>
							</Grid>
						);
					})
					:
					<p style={{ textAlign: "center" }}>No Files(s) found</p>
				}
			</DialogContent>
			<DialogActions>
				{handleShowAttachments?.length > 0 ? <Button color="primary" variant="contained" onClick={(event) => downloadAll(event, files, "triggerExport")} files={{ title: "AMP", extension: "zip", key: "" }} > Download All </Button> : null}
				<Button color="primary" variant="contained" onClick={handleClose}> Close </Button>
			</DialogActions>
		</div>
	);
};

export default ShowAttachments;
