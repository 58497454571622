import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, FormLabel, FormControlLabel, Radio, Dialog, TextareaAutosize } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/ShopvisitTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addSvFormApi, deleteAdAttachment } from "../apiServices"
import { regexConstants } from "../../../../constants/regEx"
import { checkApiStatus } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"

class SvTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            regex: "^[0-9]+$",
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false
        }
        this.addSvFormApi = addSvFormApi.bind(this)
    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }
    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        this.setState({ file: "" })

        validationInputs = {
            ...validationInputs,
            removal_date: taskErrorCode["removal_date"][fieldValidation({ ...taskErrorCode["removal_date_obj"], fieldval: data.removal_date, })],
            removal_reason: taskErrorCode["removal_reason"][fieldValidation({ ...taskErrorCode["removal_reason_obj"], fieldval: data.removal_reason, })],
            tsn_at_removal: taskErrorCode["tsn_at_removal"][fieldValidation({ ...taskErrorCode["tsn_at_removal_obj"], fieldval: data.tsn_at_removal, })],
            csn_at_removal: taskErrorCode["csn_at_removal"][fieldValidation({ ...taskErrorCode["csn_at_removal_obj"], fieldval: data.csn_at_removal, })],
            sv_completion_date: taskErrorCode["sv_completion_date"][fieldValidation({ ...taskErrorCode["sv_completion_date_obj"], fieldval: data.sv_completion_date, })],
            sv_type: taskErrorCode["sv_type"][fieldValidation({ ...taskErrorCode["sv_type_obj"], fieldval: data.sv_type, })],
        }
        if (
            Object.keys(validationInputs).every((k) => {
                return validationInputs[k] === ""
            })
        ) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                },
            }))

            const props = this.props.props
            const SvData = data
            SvData.asset_id = props.match.params.asset
            SvData.asset_type_id = props.match.params.assetType
            SvData.sv_type = SvData.sv_type ? SvData.sv_type : null
            SvData.tsn_at_removal = SvData?.tsn_at_removal ? parseFloat(SvData.tsn_at_removal) : null
            SvData.csn_at_removal = SvData?.csn_at_removal ? parseFloat(SvData.csn_at_removal) : null
            addSvFormApi(SvData, props).then(async (response) => {
                const uploadId = response.data.data.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                }
            })
            if (this.state.btnStatus !== "addAnother") {
                this.props.closeAddForm()
            } else {
                return false
            }
        } else {
            this.setState({ error: validationInputs })
        }
    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
    updateData = async (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        this.setState({ file: "" })

        validationInputs = {
            ...validationInputs,
            removal_date: taskErrorCode["removal_date"][fieldValidation({ ...taskErrorCode["removal_date_obj"], fieldval: data.removal_date, })],
            removal_reason: taskErrorCode["removal_reason"][fieldValidation({ ...taskErrorCode["removal_reason_obj"], fieldval: data.removal_reason, })],
            tsn_at_removal: taskErrorCode["tsn_at_removal"][fieldValidation({ ...taskErrorCode["tsn_at_removal_obj"], fieldval: data.tsn_at_removal, })],
            csn_at_removal: taskErrorCode["csn_at_removal"][fieldValidation({ ...taskErrorCode["csn_at_removal_obj"], fieldval: data.csn_at_removal, })],
            sv_completion_date: taskErrorCode["sv_completion_date"][fieldValidation({ ...taskErrorCode["sv_completion_date_obj"], fieldval: data.sv_completion_date, })],
            sv_type: taskErrorCode["sv_type"][fieldValidation({ ...taskErrorCode["sv_type_obj"], fieldval: data.sv_type, })],

        }
        if (
            Object.keys(validationInputs).every((k) => {
                return validationInputs[k] === ""
            })
        ) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                },
            }))

            const updateId = this.props.editFormId
            const updatedData = this.state.data
            updatedData.sv_type = updatedData.sv_type ? updatedData.sv_type : null
            updatedData.tsn_at_removal = updatedData?.tsn_at_removal ? parseFloat(updatedData.tsn_at_removal) : null
            updatedData.csn_at_removal = updatedData?.csn_at_removal ? parseFloat(updatedData.csn_at_removal) : null
            const props = this.props.props
            this.setState({
                data: this.props.editFormData,
            })
            if (updateId) {
                await this.uploadFileTest(updateId, props, true)
            }
            await this.props.updateFormData(updateId, updatedData, props)
            this.props.closeAddForm()
        } else {
            this.setState({ error: validationInputs })
        }
    }

    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 9
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 9,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteAdAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {
            delete: true,
            file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
        };
        globalPutService(`camo/shop/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type == "link" || uuid) {
            const { data } = this.state;
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {
                file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
            };
            globalPutService(`camo/shop/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/shop/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }
    render() {
        const { data, error, attachments } = this.state
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <div id="HtTaskForm">
                        <DialogTitle>
                            {this.props.formTitle}
                            <CloseIcon
                                onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                                className="close-icon"
                            />
                        </DialogTitle>
                        <form
                            id="asset-form"
                            onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                        >
                            <DialogContent>
                                <Grid spacing={1} container>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="removal_date"
                                                label={<React.Fragment>Removal Date <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                                format={fieldDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.removal_date}
                                                error={error.removal_date ? true : false}
                                                helperText={error.removal_date ? error.removal_date : ""}
                                                onFocus={() => this.onRestErrorKey("removal_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("removal_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("removal_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment> Removal Reason <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                            name="removal_reason"
                                            id="removal_reason"
                                            value={data.removal_reason}
                                            error={error.removal_reason ? true : false}
                                            helperText={error.removal_reason ? error.removal_reason : ""}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (input.length <= 20) {
                                                    this.onFieldChange("removal_reason", input)
                                                    this.onRestErrorKey("removal_reason")
                                                } else {
                                                    const truncatedInput = input.slice(0, 20)
                                                    this.onFieldChange("removal_reason", truncatedInput)
                                                    this.onRestErrorKey("removal_reason")
                                                }
                                            }}
                                            onFocus={() => this.onRestErrorKey("removal_reason")}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment>TSN at Removal <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                            id="tsn_at_removal"
                                            value={data.tsn_at_removal ? data.tsn_at_removal : ''}
                                            error={error.tsn_at_removal ? true : false}
                                            helperText={error.tsn_at_removal ? error.tsn_at_removal : ""}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                                if (isValid || value === "") {
                                                    this.onFieldChange("tsn_at_removal", numericValue);
                                                }

                                                this.onRestErrorKey("tsn_at_removal");
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment> CSN at Removal <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                            id="csn_at_removal"
                                            value={data.csn_at_removal ? data.csn_at_removal : ''}
                                            error={error.csn_at_removal ? true : false}
                                            helperText={error.csn_at_removal ? error.csn_at_removal : ""}
                                            onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('csn_at_removal', e.target.value); this.onRestErrorKey("csn_at_removal") }}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3} className="input-calender">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="sv_completion_date"
                                                label={<React.Fragment>Shop Visit Completion Date <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                                format={fieldDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.sv_completion_date}
                                                error={error.sv_completion_date ? true : false}
                                                helperText={error.sv_completion_date ? error.sv_completion_date : ""}
                                                onFocus={() => this.onRestErrorKey("sv_completion_date")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("sv_completion_date", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("sv_completion_date")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment>Shop Visit Location /MRO  </React.Fragment>}
                                            name="sv_location_mro"
                                            id="sv_location_mro"
                                            value={data.sv_location_mro}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (input.length <= 20) {
                                                    this.onFieldChange("sv_location_mro", input)
                                                    this.onRestErrorKey("sv_location_mro")
                                                } else {
                                                    const truncatedInput = input.slice(0, 20)
                                                    this.onFieldChange("sv_location_mro", truncatedInput)
                                                    this.onRestErrorKey("sv_location_mro")
                                                }
                                            }}
                                            onFocus={() => this.onRestErrorKey("sv_location_mro")}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment>Shop Visit Workscope </React.Fragment>}
                                            name="sv_workscope"
                                            id="sv_workscope"
                                            value={data.sv_workscope}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (input.length <= 20) {
                                                    this.onFieldChange("sv_workscope", input)
                                                    this.onRestErrorKey("sv_workscope")
                                                } else {
                                                    const truncatedInput = input.slice(0, 20)
                                                    this.onFieldChange("sv_workscope", truncatedInput)
                                                    this.onRestErrorKey("sv_workscope")
                                                }
                                            }}
                                            onFocus={() => this.onRestErrorKey("sv_workscope")}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <label>Shop Visit Type <span style={{ color: "red" }}> *</span> </label>
                                        <FormControl id="d-block">
                                            <FormLabel component="legend"
                                             error={error.sv_type ? true : false}
                                            ></FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="sv_type"
                                                name="sv_type"
                                                defaultValue="top"
                                                value={data.sv_type}
                                                onChange={(e, value) => this.onFieldChange("sv_type", e.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="PRSV"
                                                    control={<Radio size="small" color="primary" />}
                                                    label="PRSV"
                                                />
                                                <FormControlLabel
                                                    value="Repair"
                                                    control={<Radio size="small" color="primary" />}
                                                    label="Repair"
                                                />
                                            </RadioGroup>
                                            {error.sv_type && (
												<FormLabel component="faa_easa"> <span style={{ color: "red" }}>{error.sv_type}</span> </FormLabel>
											)}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div className="border-grey"></div>
                                <Grid spacing={1} container>
                                    <Grid item xs={6}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            placeholder="Remark"
                                            name="remarks"
                                            id="remarks"
                                            style={{ height: "70px", width: "560px", padding: '3px' }}
                                            value={data.remarks ? data.remarks : ""}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (input.length <= 255) {
                                                    this.onFieldChange("remarks", input)
                                                    this.onRestErrorKey("remarks")
                                                } else {
                                                    const truncatedInput = input.slice(0, 255)
                                                    this.onFieldChange("remarks", truncatedInput)
                                                    this.onRestErrorKey("remarks")
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                            <ul className="list-inline spacing-list">
                                                <li className="list-inline-item" onClick={this.handleOpenUploadFile}>
                                                    <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                                </li>
                                                <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                    <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="actions-btn">
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                                >
                                    {this.props.actionType === "add" ? "Add" : "Update"}
                                </Button>

                                {this.props.actionType === "add" ? (
                                    <Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                                ) : (
                                    ""
                                )}
                                <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}  > Close </Button>
                            </DialogActions>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseAttachment}
                                id="htDialog"
                            >
                            </Dialog>
                            <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                                <LinkFiles
                                    handleClose={() => this.handleCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                    modType="shop"
                                    last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                    uploadFileInfo={data}
                                    attachments={attachments}
                                    type="link"
                                />
                            </Dialog>
                            <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                                <UploadFile
                                    handleClose={() => this.handleUploadFileCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                    modType="shop"
                                    uploadFileInfo={data}
                                    last_used_folder_uuid={this.props.last_used_folder_uuid}
                                    onUpload={(file) => this.onUpload(file)}
                                    type="upload"
                                    checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                    checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                                />
                            </Dialog>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SvTaskForm