export const OpMainHead = [
    { id: "", label: "", sortOption: false },
    { id: "fitment", label: "Fitment", sortOption: false, colspan: 10 },
    { id: "removal", label: "Removal", sortOption: false, colspan: 11 },
    { id: "Maintenance", label: "Maintenance", sortOption: false, colspan: 2 },
    { id: "", label: "", sortOption: false },
]
export const OpTableHead = [
    { id: "actions", label: "Actions", sortOption: false },
    { id: "fitment_date", label: "Date", sortOption: false },
    { id: "fitment_operator", label: "Operator", sortOption: false },
    { id: "fitment_aircraft", label: "A/C", sortOption: false },
    { id: "fitment_tail", label: "Tail", sortOption: false },
    { id: "fitment_psn", label: "PSN", sortOption: false },
    { id: "fitment_thrust", label: "Thrust", sortOption: false },
    { id: "fitment_aircraft_flying_hours", label: "A/F Hours" },
    { id: "fitment_aircraft_flying_cycle", label: "A/F Cycle" },
    { id: "fitment_engine_tsn", label: "Engine TSN" },
    { id: "fitment_engine_csn", label: "Engine CSN" },

    { id: "removal_date", label: "Date", sortOption: false },
    { id: "removal_aircraft_flying_hours", label: "A/F Hours" },
    { id: "removal_aircraft_flying_cycle", label: "A/F Cycle" },
    { id: "removal_engine_tsn", label: "Engine TSN" },
    { id: "removal_engine_csn", label: "Engine CSN" },
    { id: "removal_tslsv", label: "TSLV", sortOption: false },
    { id: "removal_cslsv", label: "CSLV", sortOption: false },
    { id: "removal_hours_used", label: "Hours Used", sortOption: false },
    { id: "removal_cycle_used", label: "Cylce Used", sortOption: false },
    { id: "removal_egtm", label: "EGTM", sortOption: false },
    { id: "removal_event", label: "Event", sortOption: false },

    { id: "maint_effective_work_carried_out", label: "Effective Work Carried Out", sortOption: false },
    { id: "maint_shop", label: "Shop", sortOption: false },
    { id: "attachments", label: "Attachments" },
];
