import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
    asset_id: '',
    asset_type_id: '',
    part_number: '',
    serial_number: '',
    description: '',
    life_limit: '',
    tsn: '',
    csn: '',
    installation_date: '',
    tsn_installation: '',
    csn_installation: '',
    engine_tsn_installation: '',
    engine_csn_installation: '',
    remarks: '',
    checklist: [],
    folder_uuid: ''
};

export const taskErrorCode = {
    part_number: {
        0: "",
        1: "Required",
    },
    part_number_obj: {
        required: true,
    },
    serial_number: {
        0: "",
        1: "Required",
    },
    serial_number_obj: {
        required: true,
    },
    life_limit: {
        0: "",
        1: "Required",
    },
    life_limit_obj: {
        required: true,
    },
    csn: {
        0: "",
        1: "Required",
    },
    csn_obj: {
        required: true,
    },
    installation_date: {
        0: "",
        1: "Required",
        4: "Please enter valid date",
    },
    installation_date_obj: {
        required: true,
        datePattern: true,
    },
    tsn_installation: {
        0: "",
        1: "Required",
    },
    tsn_installation_obj: {
        required: true,
        regexPattern: regexConstants.onlyNumeric,
    },
    csn_installation: {
        0: "",
        1: "Required",
    },
    csn_installation_obj: {
        required: true,
    },
    engine_tsn_installation: {
        0: "",
        1: "Required",
    },
    engine_tsn_installation_obj: {
        required: true,
    },
    engine_csn_installation: {
        0: "",
        1: "Required",
    },
    engine_csn_installation_obj: {
        required: true,
    },
};
