import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Dialog, TableRow, TableCell, Tooltip, IconButton, } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import ConfirmDialog from "../components/ConfirmDialog"
import { fillHtEditFormApi } from "../apiService"
import HtTaskForm from "./HtTaskForm"
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments"
import moment from "moment"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory"
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices"

const HtList = ({ item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, headerTsn, headerCsn, uuid, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name }) => {
	const [open, setOpen] = useState(false)
	const [openEditForm, setEditForm] = useState(false)
	const [editFormId, setEditFormId] = useState(null)
	const [editFormData, setEditFormData] = useState(null)
	const [formTitle, setFormTitle] = useState("")
	const [attachments, setAttachments] = useState(null)
	const [showFullDescription, setShowFullDescription] = useState({})
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] = useState(null)
	const [moduleId, setmoduleId] = useState(null)

	const handleClick = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	const closeAddForm = () => {
		setEditForm(false)
	}

	const handleEditRow = () => {
		fillHtEditFormApi(item, props).then((response) => {
			setEditFormData(response.data.data)
			setEditFormId(response.data.data.id)
			setAttachments(response.data.data.attachments)
			setFormTitle("Update HT Components")
			setEditForm(true)
		})
	}

	const downAllAttach = () => {
		fillHtEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id)
		})
		if(item.attachments?.length > 0 && item.checklist.files?.length > 0){
			getAttachments([...item.attachments,...item.checklist.files]);
		}else if (item.attachments?.length >0 ){
			getAttachments([...item.attachments]);
		}else{
			getAttachments([...item.checklist.files]);
		}
	}
	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	}

	const formatDate = (date) => {
		return moment(date).format("DD-MM-YYYY")
	}

	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		})
	}

	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments)
		setAttachmentsDialogue(true)
	}

	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false)
	}
	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const hturl = `audit/camo/htcomponent/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`

	const removeAttachment = (item, checklist, fileId, uuid) => {
		const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
		const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
		if (uuid) {
			let payload = {
				delete: true,
				file_ids: attchementRemoveID.filter((id) => id !== fileId).toString()
			};
			globalPutService(`camo/ht/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillHtEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if(item.attachments?.length > 0 && item.checklist.files?.length > 0){
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							}else if (item.attachments?.length >0 ){
								getAttachments([...response.data.data.attachments]);
							}else{
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillHtEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if(item.attachments?.length > 0 && item.checklist.files?.length > 0){
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							}else if (item.attachments?.length >0 ){
								getAttachments([...response.data.data.attachments]);
							}else{
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				}
			});
		}
	};

	return (
		<>
			<TableRow
				hover
				tabIndex={-1}
				style={{ cursor: "pointer" }}
				className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"}
			>
				<TableCell>
					{permissionCheckFnforCamo({ primaryKey: "ht_component", keyIndex: "U", }) ? (
						<Tooltip title="Edit">
							<IconButton className="edit-icon" onClick={(e) => { handleEditRow(e) }} >
								<CreateOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					{permissionCheckFnforCamo({ primaryKey: "ht_component", keyIndex: "D", }) ||
						permissionCheckFnforCamo({ primaryKey: "ht_component", keyIndex: "OD", userId: item.created_by, }) ? (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					<RevisionHistory buttonType={true} url={hturl} />
				</TableCell>
				<TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
				<TableCell style={{ width: "200px", textAlign: "left" }}>
					{item.part_description && item.part_description.length > 150 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span>
									{item.part_description}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id) }} > ..less
									</a>
								</span>
							) : (
								<span>
									{item.part_description.substring(0, 150)}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id) }} > ..more </a>
								</span>
							)}
						</div>
					) : item.part_description && item.part_description.length <= 150 ? (
						<span>{item.part_description}</span>
					) : ("--")}
				</TableCell>
				<TableCell>{item.part_number ? item.part_number : "--"}</TableCell>
				<TableCell> {item.part_serial_number ? item.part_serial_number : "--"} </TableCell>
				<TableCell> {item.part_installation_date ? formatDate(item.part_installation_date) : "--"} </TableCell>
				<TableCell> {item.aircraft_tsn_installation ? item.aircraft_tsn_installation : "--"} </TableCell>
				<TableCell> {item.aircraft_csn_installation ? item.aircraft_csn_installation : "--"} </TableCell>
				<TableCell> {item.part_tsn_installation ? item.part_tsn_installation : "--"} </TableCell>
				<TableCell> {item.part_csn_installation ? item.part_csn_installation : "--"} </TableCell>
				<TableCell> {item.ht_requirement ? item.ht_requirement : "--"} </TableCell>
				<TableCell> {item.dimension_type === "D" || item.dimension_type === "FH,D" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH,D" ? "D" : "--"} </TableCell>
				<TableCell> {item.dimension_type === "FH" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH,D" || item.dimension_type === "FC,FH" ? "FH" : "--"} </TableCell>
				<TableCell> {item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH,D" || item.dimension_type === "FC,FH" ? "FC" : "--"} </TableCell>
				<TableCell> {item.dimension_type === "D" || item.dimension_type === "FC,D" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH,D" ? item.remaining.due_at_type === "d" ? item.remaining.due_at : "--" : "--"} </TableCell>
				<TableCell>
					{item.dimension_type === "FH" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH" || item.dimension_type === "FC,FH,D"
						? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fh'
							? item.remaining.due_at
							: item.remaining.due_at
						: "--"}
				</TableCell>
				<TableCell>
					{item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH" || item.dimension_type === "FC,FH,D"
						? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fc'
							? item.remaining.due_at
							: item.remaining.due_at_type === "s" && item.remaining.s_type === null && item.remaining.due_at
						: "--"}
				</TableCell>

				<TableCell>
					{item.dimension_type === "D" || item.dimension_type === "FC,D" || item.dimension_type === "FH,D"
						? item.remaining.due_at_type === "d"
							? item.remaining.remaining
							: "--"
						: "--"}
				</TableCell>
				<TableCell>
					{item.dimension_type === "FH" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH" || item.dimension_type === "FC,FH,D"
						? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fh'
							? item.remaining.remaining
							: item.remaining.due_at_type === "s" && item.remaining.s_type === null && item.remaining.remaining
						: "--"}
				</TableCell>
				<TableCell>
					{item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH" || item.dimension_type === "FC,FH,D"
						? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fc'
							? item.remaining.remaining
							: item.remaining.due_at_type === "s" && item.remaining.s_type === null && item.remaining.remaining
						: "--"}
				</TableCell>

				<TableCell style={{ width: "200px", textAlign: "left" }}>
					{item.remark && item.remark.length > 150 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span>
									{item.remark}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id) }} > ..less </a>
								</span>
							) : (
								<span>
									{item.remark.substring(0, 150)}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id) }} > ..more </a>
								</span>
							)}
						</div>
					) : item.remark && item.remark.length <= 150 ? (
						<span>{item.remark}</span>
					) : (
						"--"
					)}
				</TableCell>
				<TableCell>
					{item.attachments?.length > 0 || item.checklist?.files?.length > 0 ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => { downAllAttach(); }} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{(item.attachments?.length > 0 && item.checklist?.files?.length > 0) ? (item.attachments?.length + item.checklist?.files?.length) : item.attachments?.length > 0 ? item.attachments?.length : item.checklist?.files?.length > 0 && item.checklist?.files?.length}</a>

								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
				onClose={closeAddForm}
			>
				<HtTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					uuid={uuid}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>

			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					removeAttachment={removeAttachment}
					item={item}
				/>
			</Dialog>
		</>
	)
}
export default withRouter(HtList)
