import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, Dialog, TextareaAutosize } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/SoftTimeTaskformData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addStFormApi, deleteStAttachment } from "../apiServices"
import { regexConstants } from "../../../../constants/regEx"
import { checkApiStatus } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"

class StTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false
        }
        this.addStFormApi = addStFormApi.bind(this)
    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        validationInputs = {
            ...validationInputs,
            part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
            serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
            life_limit: taskErrorCode["life_limit"][fieldValidation({ ...taskErrorCode["life_limit_obj"], fieldval: data.life_limit, })],
            csn: taskErrorCode["csn"][fieldValidation({ ...taskErrorCode["csn_obj"], fieldval: data.csn, })],
            tsn_installation:moment(data.installation_date).isValid()  ? taskErrorCode["tsn_installation"][fieldValidation({ ...taskErrorCode["tsn_installation_obj"], fieldval: data.tsn_installation, })] : '',
            csn_installation:moment(data.installation_date).isValid()  ? taskErrorCode["csn_installation"][fieldValidation({ ...taskErrorCode["csn_installation_obj"], fieldval: data.csn_installation, })] :'', 
            engine_tsn_installation: moment(data.installation_date).isValid()  ?taskErrorCode["engine_tsn_installation"][fieldValidation({ ...taskErrorCode["engine_tsn_installation_obj"], fieldval: data.engine_tsn_installation, })] :'',
            engine_csn_installation:moment(data.installation_date).isValid() ? taskErrorCode["engine_csn_installation"][fieldValidation({ ...taskErrorCode["engine_csn_installation_obj"], fieldval: data.engine_csn_installation, })] :'',
        }
        if (Object.keys(validationInputs).every((k) => {
            return validationInputs[k] === ""
        })
        ) {
            const props = this.props.props
            let payload = Object.assign({}, data)
            payload = {
                ...payload,
                asset_id: props.match.params.asset,
                asset_type_id: props.match.params.assetType,
                installation_date: moment(data.installation_date).isValid() ? moment(data.installation_date).format(backendDateFormat) : null,
                tsn: parseInt(data.tsn) || null,
                csn: parseInt(data.csn) || null,
                tsn_installation: parseInt(data.tsn_installation) || null,
                csn_installation: parseInt(data.csn_installation) || null,
                engine_tsn_installation: parseInt(data.engine_tsn_installation) || null,
                engine_csn_installation: parseInt(data.engine_csn_installation) || null,

            }
            addStFormApi(payload, props).then(async (response) => {
                const uploadId = response.data.data.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                }
            })
            if (this.state.btnStatus !== "addAnother") {
                this.props.closeAddForm()
            } else {
                return false
            }

        } else {
            this.setState({ error: validationInputs })
        }
    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
     isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
      }
    updateData = async (e) => {
       
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        validationInputs = {
            ...validationInputs,
            part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
            serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
            life_limit: taskErrorCode["life_limit"][fieldValidation({ ...taskErrorCode["life_limit_obj"], fieldval: data.life_limit, })],
            csn: taskErrorCode["csn"][fieldValidation({ ...taskErrorCode["csn_obj"], fieldval: data.csn, })],
            tsn_installation:moment(data.installation_date).isValid()  ? taskErrorCode["tsn_installation"][fieldValidation({ ...taskErrorCode["tsn_installation_obj"], fieldval: data.tsn_installation, })] : '',
            csn_installation:moment(data.installation_date).isValid()  ? taskErrorCode["csn_installation"][fieldValidation({ ...taskErrorCode["csn_installation_obj"], fieldval: data.csn_installation, })] :'', 
            engine_tsn_installation: moment(data.installation_date).isValid()  ?taskErrorCode["engine_tsn_installation"][fieldValidation({ ...taskErrorCode["engine_tsn_installation_obj"], fieldval: data.engine_tsn_installation, })] :'',
            engine_csn_installation:moment(data.installation_date).isValid() ? taskErrorCode["engine_csn_installation"][fieldValidation({ ...taskErrorCode["engine_csn_installation_obj"], fieldval: data.engine_csn_installation, })] :'',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
            let payload = Object.assign({}, data)
            const props = this.props.props
            payload = {
                ...payload,
                asset_type_id: props.match.params.assetType,
                installation_date: moment(data.installation_date).isValid() ? moment(data.installation_date).format(backendDateFormat) : null,
                csn: parseInt(data.csn) || null,
                tsn_installation: parseInt(data.tsn_installation) || null,
                csn_installation: parseInt(data.csn_installation) || null,
                engine_tsn_installation: parseInt(data.engine_tsn_installation) || null,
                engine_csn_installation: parseInt(data.engine_csn_installation) || null,
            }
            const updateId = this.props.editFormId
            
            if (updateId) {
                this.uploadFileTest(updateId, props, true)
            }
            this.props.updateFormData(updateId, payload, props)
            this.props.closeAddForm()
            this.setState({ error: validationInputs })
        } else {
            this.setState({ error: validationInputs })
        }
    }
    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 12
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 12,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteStAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {
            delete: true,
            file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
        };
        globalPutService(`camo/soft_time/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type == "link" || uuid) {
            const { data } = this.state;
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {
                file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
            };
            globalPutService(`camo/soft_time/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/soft_time/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }
    render() {
        const { data, error, attachments } = this.state
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <DialogTitle>
                        {this.props.formTitle}
                        <CloseIcon
                            onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                            className="close-icon"
                        />
                    </DialogTitle>
                    <form
                        id="asset-form"
                        onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                    >
                        <DialogContent>
                            <Grid spacing={2} container>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Part Number"
                                        name="part_number"
                                        id="part_number"
                                        value={data.part_number ? data.part_number : ''}
                                        error={error.part_number ? true : false}
                                        helperText={error.part_number ? error.part_number : ""}
                                        onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number") }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Serial Number"
                                        id="serial_number"
                                        value={data.serial_number}
                                        error={error.serial_number ? true : false}
                                        helperText={error.serial_number ? error.serial_number : ""}
                                        onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number") }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Life Limit"
                                        name="life_limit"
                                        id="life_limit"
                                        value={data.life_limit}
                                        error={error.life_limit ? true : false}
                                        helperText={error.life_limit ? error.life_limit : ""}
                                        onChange={(e) => { e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('life_limit', e.target.value); this.onRestErrorKey("life_limit") }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="border-grey"></div>
                            <Grid spacing={1} container>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label="TSN"
                                        name="tsn"
                                        id="tsn"
                                        value={data.tsn}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (isValid || value === "") {
                                                this.onFieldChange("tsn", numericValue);
                                            }

                                            this.onRestErrorKey("tsn");
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="CSN"
                                        name="csn"
                                        id="csn"
                                        value={data.csn}
                                        error={error.csn ? true : false}
                                        helperText={error.csn ? error.csn : ""}
                                        onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('csn', e.target.value); this.onRestErrorKey("csn") }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required={data.installation_date && true}
                                        variant="outlined"
                                        label="TSN Installation"
                                        name="tsn_installation"
                                        id="tsn_installation"
                                        value={data.tsn_installation}
                                        error={error.tsn_installation ? true : false}
                                        helperText={error.tsn_installation ? error.tsn_installation : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("tsn_installation", input)
                                                this.onRestErrorKey("tsn_installation")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange("tsn_installation", truncatedInput)
                                                this.onRestErrorKey("tsn_installation")
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="border-grey"></div>
                            <Grid spacing={1} container id="ht-dropdown">
                                <Grid item xs={4}>
                                    <TextField
                                       required={data.installation_date && true}
                                        variant="outlined"
                                        label="CSN Installation"
                                        name="csn_installation"
                                        id="csn_installation"
                                        value={data.csn_installation}
                                        error={error.csn_installation ? true : false}
                                        helperText={error.csn_installation ? error.csn_installation : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("csn_installation", input)
                                                this.onRestErrorKey("csn_installation")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange("csn_installation", truncatedInput)
                                                this.onRestErrorKey("csn_installation")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required={data.installation_date && true}
                                        variant="outlined"
                                        label="Engine TSN Installation"
                                        id="engine_tsn_installation"
                                        value={data.engine_tsn_installation}
                                        error={error.engine_tsn_installation ? true : false}
                                        helperText={error.engine_tsn_installation ? error.engine_tsn_installation : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("engine_tsn_installation", input)
                                                this.onRestErrorKey("engine_tsn_installation")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange("engine_tsn_installation", truncatedInput)
                                                this.onRestErrorKey("engine_tsn_installation")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                       required={data.installation_date && true}
                                        variant="outlined"
                                        label="Engine CSN Installation"
                                        name="engine_csn_installation"
                                        id="engine_csn_installation"
                                        value={data.engine_csn_installation}
                                        error={error.engine_csn_installation ? true : false}
                                        helperText={error.engine_csn_installation ? error.engine_csn_installation : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("engine_csn_installation", input)
                                                this.onRestErrorKey("engine_csn_installation")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange("engine_csn_installation", truncatedInput)
                                                this.onRestErrorKey("engine_csn_installation")
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={4} className="input-calender">
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="Installation Date"
                                            id="installation_date"
                                            format={fieldDateFormat}
                                            inputVariant="outlined"
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            error={error.installation_date ? true : false}
                                            helperText={error.installation_date ? error.installation_date : ""}
                                            value={data.installation_date}
                                            onChange={(data, value) => {
                                                this.onFieldChange(
                                                    "installation_date",
                                                    moment(data).format(backendDateFormat)
                                                )
                                                this.onRestErrorKey("installation_date")
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        multiline
                                        minRows={5}
                                        variant="outlined"
                                        label="Description"
                                        name="description"
                                        id="description"
                                        value={data.description}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 255) {
                                                this.onFieldChange("description", input)
                                                this.onRestErrorKey("description")
                                            } else {
                                                const truncatedInput = input.slice(0, 255)
                                                this.onFieldChange("description", truncatedInput)
                                                this.onRestErrorKey("description")
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={6}>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        placeholder="Remark"
                                        name="remark"
                                        id="remark"
                                        style={{ height: "70px", width: "560px", padding: '3px' }}
                                        value={data.remarks ? data.remarks : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 255) {
                                                this.onFieldChange("remarks", input)
                                                this.onRestErrorKey("remarks")
                                            } else {
                                                const truncatedInput = input.slice(0, 255)
                                                this.onFieldChange("remarks", truncatedInput)
                                                this.onRestErrorKey("remarks")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                        <ul className="list-inline spacing-list">
                                            <li className="list-inline-item" onClick={this.handleOpenUploadFile} >
                                                <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                            </li>
                                            <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="actions-btn">
                            <Button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                            >
                                {this.props.actionType === "add" ? "Add" : "Update"}
                            </Button>

                            {this.props.actionType === "add" ? (<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                            ) : (
                                ""
                            )}
                            <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
                        </DialogActions>
                        <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                            <LinkFiles
                                handleClose={() => this.handleCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                modType="soft_time"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                attachments={attachments}
                                type="link"
                            />
                        </Dialog>
                        <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                            <UploadFile
                                handleClose={() => this.handleUploadFileCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                modType="soft_time"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid}
                                onUpload={(file) => this.onUpload(file)}
                                type="upload"
                                checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                            />
                        </Dialog>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default StTaskForm