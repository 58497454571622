import { IconButton, TableCell, TableRow, Tooltip, Dialog, } from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AdTaskForm from "./AdTaskForm";
import { fillAdEditFormApi } from "../apiService";
import ConfirmDialog from "./ConfirmDialog";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import { getLocalStorageInfo } from "../../../../utils";
import config from "../../../../config"
const AdList = ({ item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, headerTsn, headerCsn, uuid, getResponseBack, last_used_folder_uuid, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name }) => {
	const [open, setOpen] = useState(false);
	const [openEditForm, setEditForm] = useState(false);
	const [editFormId, setEditFormId] = useState(null);
	const [editFormData, setEditFormData] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [attachments, setAttachments] = useState(null);
	const [showFullDescription, setShowFullDescription] = useState({});
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
		useState(null);
	const [moduleId, setmoduleId] = useState(null);
	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const closeAddForm = () => {
		setEditForm(false);
	};

	const handleEditRow = () => {
		fillAdEditFormApi(item, props).then((response) => {
			setEditFormData(response.data.data);
			setEditFormId(response.data.data.id);
			setAttachments(response.data.data.attachments);
			setFormTitle("Update Airworthiness Directives (ADs)");
			setEditForm(true);
		});
	};

	const downAllAttach = () => {
		fillAdEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id);
		});
		if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
			getAttachments([...item.attachments, ...item.checklist.files]);
		} else if (item.attachments?.length > 0) {
			getAttachments([...item.attachments]);
		} else {
			getAttachments([...item.checklist.files]);
		}


	};

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	};

	const formatDate = (date) => {
		return moment(date).format("DD-MM-YYYY");
	};
	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		});
	};

	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments);
		setAttachmentsDialogue(true);
	};
	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false);
	};

	const removeAttachment = (item, checklist, fileId, uuid) => {
		const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
		const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
		if (uuid) {
			let payload = {
				delete: true,
				file_ids: attchementRemoveID.filter((id) => id !== fileId).toString()
			};
			globalPutService(`camo/aird/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillAdEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							} else if (item.attachments?.length > 0) {
								getAttachments([...response.data.data.attachments]);
							} else {
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillAdEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							} else if (item.attachments?.length > 0) {
								getAttachments([...response.data.data.attachments]);
							} else {
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				}
			});
		}
	};
	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const adurl = `audit/camo/adstatus/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)

	return (
		<>
			<TableRow
				hover
				tabIndex={-1}
				style={{ cursor: "pointer" }}
				className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"}
			>
				<TableCell> {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U", }) ? (
					<Tooltip title="Edit">
						<IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
				) : null}
					{permissionCheckFnforCamo({
						primaryKey: "ad_status",
						keyIndex: "D",
					}) ||
						permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "OD", userId: item.created_by, }) ? (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					<RevisionHistory buttonType={true} url={adurl} />
				</TableCell>
				<TableCell>{smbcInstance ? item.faa_ad : item?.ata || "--"}</TableCell>
				<TableCell>{smbcInstance ? item.faa_superseded : item.ad_no || "--"} </TableCell>
				{smbcInstance ? <TableCell>{item.easa_ad}</TableCell> : null}
				<TableCell>{smbcInstance ? item.easa_superseded : item.issuing_authority || "--"} </TableCell>
				<TableCell style={{ width: "200px", textAlign: "left" }}>
					{item.description && item.description.length > 150 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span> {item.description}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >..less </a>
								</span>
							) : (
								<span> {item.description.substring(0, 150)}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
								</span>
							)}
						</div>
					) : item.description && item.description.length <= 150 ? (
						<span>{item.description}</span>
					) : (
						"--"
					)}
				</TableCell>
				{smbcInstance ? <TableCell>{item?.related_document || "--"}</TableCell> : null}
				<TableCell>{item.applicability ? item.applicability : "--"}</TableCell>
				<TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>
				{smbcInstance ? <TableCell>{item?.faa_effective_date || "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{item?.easa_effective_date || "--"}</TableCell> : null}
				{smbcInstance ? null : <TableCell>{item.ad_type ? item.ad_type : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell>{item.effective_date ? formatDate(item.effective_date) : "--"}  </TableCell>}
				{smbcInstance ? null : <TableCell>{item.dimension_type ? item.dimension_type : "--"}  </TableCell>}
				<TableCell>{item.ad_compliance_status ? item.ad_compliance_status : "--"} </TableCell>
				{smbcInstance ? null : <TableCell>{item.ad_compliance_tsn ? item.ad_compliance_tsn : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_csn ? item.ad_compliance_csn : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_date ? formatDate(item.ad_compliance_date) : "--"}</TableCell>}
				<TableCell>{item.remaining.due_at ? item.dimension_type === "D" ? formatDate(item.remaining.due_at) : item.remaining.due_at : item.due_date}</TableCell>
				{smbcInstance ? null : <TableCell>{item.sb_number ? item.sb_number : "--"}</TableCell>}
				{smbcInstance ? <TableCell>{item?.reference || "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{item.remark ? item.remark : "--"}</TableCell> : null}
				<TableCell>
					{item.attachments?.length > 0 || item.checklist?.files?.length > 0 ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => { downAllAttach(); }} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{(item.attachments?.length > 0 && item.checklist?.files?.length > 0) ? (item.attachments?.length + item.checklist?.files?.length) : item.attachments?.length > 0 ? item.attachments?.length : item.checklist?.files?.length > 0 && item.checklist?.files?.length}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
				onClose={closeAddForm}
			>
				<AdTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					uuid={uuid}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>
			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					item={item}
					removeAttachment={removeAttachment}
				/>
			</Dialog>
		</>
	);
};
export default withRouter(AdList);
