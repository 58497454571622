import { globalGetService, globalPostService, globalExportService, globalPutService, } from "../../utils/globalApiServices"
import { checkApiStatus, getLocalStorageInfo, downloadFileType, permissionCheckFnforCamo, } from "../../utils"
import { trackActivity } from "../../utils/mixpanel"
import config from '../../config'

export function getAssetListApi(queryParams = {}, loaderType) {
	this.setState({ [loaderType]: true })
	globalGetService(`console/list/`, { ...queryParams, per_page: queryParams.per_page ? queryParams.per_page : 50, application: config.env.key === 'api' ? 18 : 19, })
		.then((response) => {
			this.setState({ [loaderType]: false })
			if (checkApiStatus(response)) {
				delete queryParams.page
				delete queryParams.per_page
				delete queryParams.sort
				delete queryParams.sort_by
				this.setState((prevState) => ({
					...prevState,
					filter: queryParams,
					assets: {
						...prevState.assets,
						asset_count: response.data.data.asset_count,
						list: response.data.data.asset,
						pagination: {
							...prevState.assets.pagination,
							total: response.data.data.pagination.total,
							per_page: response.data.data.pagination.per_page,
							page: parseInt(response.data.data.pagination.current_page),
						},
					},
				}))
			}
		})
}
export function addNewAssetApi(props, url, data) {
	return new Promise(function (resolve, reject) {
		globalPostService(url, data).then((response) => {
			resolve(response)
		})
	})
}

export function UpdateAssetApi(props, url, data) {
	return new Promise(function (resolve, reject) {
		globalPutService(url, data).then((response) => {
			resolve(response)
		})
	})
}
export function UpdateEngine(props, url, data) {
	return new Promise(function (resolve, reject) {
		globalPutService(url, data).then((response) => {
			resolve(response)
		})
	})
}

export function engineType() {
	globalGetService(`console/engine-types/`,)
		.then(response => {
			if (checkApiStatus(response)) {
				this.setState({ engineList: response?.data?.data?.engineTypes })
			}
		})
}
export function getAllEngine(props, slug) {
	globalGetService(`console/aircraft/${slug.slug}/engine/`)
		.then(response => {
			if (checkApiStatus(response)) {
				this.setState({ allEngine: response?.data?.data })
			}
		})
}

export function exportAssetListApi(queryParams = {}, loaderType) {
	this.setState({ pageLoader: true })
	globalExportService(`/console/list/`, { ...queryParams, asset_type: queryParams.asset_type, application: config.env.key === 'api' ? 18 : 19 })
		.then((response) => {
			this.setState({ pageLoader: false })
			downloadFileType(response.data, `${getLocalStorageInfo().defaultLessor.name}_Assets`, queryParams.download)
			this.props.enqueueSnackbar("Download of Asset List successful", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
			trackActivity("Item Exported", { page_title: "Asset Listing", })
		})
}

export function getUserProfileDetails() {
	const currentTimeStamp = Date.now()
	return new Promise(function (resolve, reject) {
		globalGetService(`console/user/?timestamp=${currentTimeStamp}`).then(
			(response) => {
				resolve(response)
			}
		)
	})
}

export function getAllOppThrust(props, engine_type) {
	globalGetService(`camo/current_thrust/${props.assetType}/?engine_family_engine_type=${engine_type?.engine_family_type_id}`,)
		.then(response => {
			if (response?.status === 200) {
				this.setState({ operatingThrust: response?.data })
			}
		})
}
export function allDropDownList() {
	this.setState({ pageLoader: true })
	globalGetService(`camo/all_dropdown_list/`)
		.then(response => {
			this.setState({ pageLoader: false })
			if (response?.status === 200) {
				this.setState({ positionDropDownList: response.data.position_list })
			}
		})
}

