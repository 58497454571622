import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import SideNav from "../SideNav";
import { getLocalStorageInfo } from "../../../utils";
import config from '../../../config';

function MntSideNav(props) {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key)? 465 : 532)
	const asset = props.match.params?.asset
	const assetType = props.match.params?.assetType
	return (
		<Fragment>
			<ul className="list-unstyled">
				{smbcInstance && assetType == 2 ? <SideNav to={`/${asset}/${assetType}/op_history/list`} title="Op. History" /> : null}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/amp/list`} title="AMP/MRB Compliance" /> : null}
				<SideNav to={`/${asset}/${assetType}/ad/list`} title={smbcInstance ? "AD Status" : "Airworthiness Directives (ADs)"} />
				{smbcInstance ? null : <SideNav to={`/${asset}/${assetType}/ht/list`} title="Hard Time Components" />}
				<SideNav to={`/${asset}/${assetType}/sb/list`} title={smbcInstance ? "SB Status" : "Service Bulletins (SB)"} />
				{smbcInstance ? null : <SideNav to={`/${asset}/${assetType}/occm/list`} title="OC-CM Components" />}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/sr/list`} title="Structure Repair" /> : null}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/llp/list`} title={smbcInstance ? "LLP Status" : "Life Limited Parts (LLPs)"} />}
				{assetType == 1 ? <SideNav to={`/${asset}/${assetType}/mods/list`} title="Modifications" /> : null}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/inventory/list`} title="Inventory List" />}
				{assetType == 1 ? null : <SideNav to={`/${asset}/${assetType}/shopVisit/list`} title="Shop Visits" />}
				{assetType == 2 && <SideNav to={`/${asset}/${assetType}/utilization/list`} title="Utilization" />}
				{assetType == 2 && <SideNav to={`/${asset}/${assetType}/softTime/list`} title="Soft Time" />}
				{smbcInstance ? null : <SideNav to={`/dashboard`} title="Analytics" />}
			</ul>
		</Fragment>
	);
}

export default withRouter(MntSideNav);
