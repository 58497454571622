import React, { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import { Button, Dialog, TextField, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { DownloadOccmSampleFile} from "../apiService";
import { isMobile } from "react-device-detect";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import OccmTaskForm from "./OccmTaskForm";
import DownloadSample from "../../../../shared_elements/DownloadSample";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";

const OccmSearchBar = ({ applyingFilter, filterOptions, tabIndex, pageLoader, props, searchData, fileUploadData, importFile, exportReportFn, currentRecordPerPage, uuid ,getResponseBack}) => {
	const [open, setOpen] = useState(false);
	const [menuAnchorEl, menuSetAnchorEl] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [actionType, setActionType] = useState("");
	const [importAnchorEl, importSetAnchorEl] = useState(null);
	const [exportAnchorEl, exportSetAnchorEl] = useState(null);
	const [downloadAnchorEl, downloadSetAnchorEl] = useState(null);

	const openMenu = Boolean(menuAnchorEl);
	const openImportMenu = Boolean(importAnchorEl);
	const openExportMenu = Boolean(exportAnchorEl);
	const openDownloadMenu = Boolean(downloadAnchorEl);

	const handlefilterButtonClick = (event) => {
		menuSetAnchorEl(event.currentTarget);
	};

	const onHandleClose = () => {
		menuSetAnchorEl(null);
	};

	const addNewTask = () => {
		setOpen(true);
		setFormTitle("OC-CM Components");
		setActionType("add");
	};

	const closeAddForm = () => {
		setOpen(false);
	};

	const importFileFunc = (e) => {
		const files = e.target.files;
		const formData = new FormData();
		const fileType = files[0].type;
		if (
			!fileType.endsWith(
				"vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			)
		) {
			formData.append("file", files[0]);
			importFile(formData, props, false);
			e.target.value = null;
		} else {
			formData.append("file", files[0]);
			importFile(formData, props, true);
			e.target.value = null;
		}
	};

	const onExportHandleClose = () => {
		exportSetAnchorEl(null);
	};

	const onExportHandleClick = (event) => {
		exportSetAnchorEl(event.currentTarget);
	};

	const ondownloadHandleClose = () => {
		downloadSetAnchorEl(null);
	};
	
	return (
		<Fragment>
			<div id="AmpSearchBar">
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div>
							<Button
								id="basic-button"
								className="menu-btn"
								aria-controls={openMenu ? "basic-menu" : ""}
								aria-haspopup="true"
								aria-expanded={openMenu ? "true" : ""}
								onClick={handlefilterButtonClick}
							>
								<FilterListIcon />
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={menuAnchorEl}
								open={openMenu}
								onClose={onHandleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={onHandleClose}>Over due tasks</MenuItem>
								<MenuItem onClick={onHandleClose}>Tasks</MenuItem>
								<MenuItem onClick={onHandleClose}>All tasks</MenuItem>
							</Menu>
						</div>
						<div className="search-input">
							<TextField
								id="search"
								placeholder="Search ATA Chapter, Part Description, Part Number, Part Serial Number"
								onChange={searchData}
							/>
							<Tooltip
								title="Search ATA Chapter, Part Description, Part Number, Part Serial Number"
								variant="contained"
							>
								<SearchIcon className="search-icon" />
							</Tooltip>
						</div>
					</div>
					<div className="btn-wrapper">
						{permissionCheckFnforCamo({
							primaryKey: "occm_component",
							keyIndex: "C",
						}) ? (
							<Tooltip title="Add Task">
								<Button
									onClick={addNewTask}
									variant="contained"
									color="primary"
								>
									<AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
									NEW TASK
								</Button>
							</Tooltip>
						) : null}
						<Button variant="outlined" color="primary" component="label">
							<span>IMPORT</span>
							<input
								className="upload-input"
								onChange={(e) => importFileFunc(e)}
								id="fileUplaod"
								name="file"
								type="file"
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							/>
						</Button>
						<Button
							variant="outlined"
							color="primary"
							id="basic-button"
							aria-controls={openExportMenu ? "basic-menu" : ""}
							aria-haspopup="true"
							aria-expanded={openExportMenu ? "true" : ""}
							onClick={onExportHandleClick}
						>
							EXPORT
							<KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
						</Button>
						<Menu
							className="import-menu-item export"
							id="basic-menu"
							anchorEl={exportAnchorEl}
							open={openExportMenu}
							onClose={onExportHandleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem className="export-list">
								<DownloadXLSAssetDetail
									files={{ title: "EXCEL", extension: "xls", key: "" }}
									exportReportFn={exportReportFn}
								/>
							</MenuItem>
							<MenuItem className="export-list">
								<DownloadPDFAssetDetail
									files={{ title: "PDF", extension: "pdf", key: "" }}
									exportReportFn={exportReportFn}
								/>
							</MenuItem>
						</Menu>
						<DownloadSample
							variant="outlined"
							color="primary"
							files={{ title: "DOWNLOAD SAMPLE", extension: "xlsx", key: "" }}
							exportSample={(file) =>
								DownloadOccmSampleFile(
									{
										download: file.extension,
										...{
											...convertFilterObject(
												removeEmptyKey(applyingFilter),
												filterOptions
											),
											asset_type: tabIndex,
										},
									},
									pageLoader,
									props
								)
							}
						/>
						<Menu
							className="import-menu-item export"
							id="basic-menu"
							anchorEl={downloadAnchorEl}
							open={openDownloadMenu}
							onClose={ondownloadHandleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem></MenuItem>
						</Menu>
						<Dialog open={open} onClose={closeAddForm} style={{ height: isMobile && "650px" }}>
							<OccmTaskForm
								formTitle={formTitle}
								closeAddForm={() => closeAddForm()}
								props={props}
								actionType={actionType}
								fileUploadData={fileUploadData}
								currentRecordPerPage={currentRecordPerPage}
								uuid={uuid}
								getResponseBack={() => getResponseBack()}
							/>
						</Dialog>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default OccmSearchBar;
