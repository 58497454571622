import React, { Fragment } from "react";
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { permissionCheckFnforCamo,getLocalStorageInfo } from "../../utils";
import config from '../../config';

const AssetAddDropdown = ({ toggleAssetType }) => {

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, assetType) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if (assetType) {
      toggleAssetType(assetType);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Fragment>
      {permissionCheckFnforCamo({ primaryKey: "asset_management", keyIndex: "C", }) ? (
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
          variant="contained"
          size="small"
        >
          Add Asset <ArrowDropDownIcon />
        </Button>
      ) : null}
      <Popper
        placement="bottom-end"
        style={{ zIndex: "9" }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532 ) ? null : <MenuItem>
                    <a href={`${config.domain.subDomian}assets-listing?apps=camo&asset_type=1`} style={{color:"#000000"}}>Aircraft (MSN)</a>
                  </MenuItem>}
                  <MenuItem style={{width:"100px"}}>
                     <a href={`${config.domain.subDomian}assets-listing?apps=camo&asset_type=2`}  style={{color:"#000000"}}> Engine</a>
                     </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default AssetAddDropdown;
