import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, Dialog, TextareaAutosize } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from "@material-ui/lab/Autocomplete"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/UtilizationTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addUtilFormApi, deleteAdAttachment, utilizatioPeriodDateApi } from "../apiServices"
import { regexConstants } from "../../../../constants/regEx"
import { checkApiStatus } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { getAllOppThrust, allDropDownList } from "../../../home/apiServices";
import { PageLoader } from "../../../../shared_elements";

class UtilizationFormData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false,
            operatingThrust: [],
            previousLocation: this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0],
            locationRegA_c: this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0],
            positionDropDownList: [],
            pageLoader: false,
            utilDateList: []

        }
        this.addUtilFormApi = addUtilFormApi.bind(this)
        this.getAllOppThrust = getAllOppThrust.bind(this)
        this.allDropDownList = allDropDownList.bind(this)
        this.utilizatioPeriodDateApi = utilizatioPeriodDateApi.bind(this)

    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
        this.getAllOppThrust(this.props.props.match.params, this.props.engineType?.engine_type)
        this.allDropDownList()
        this.utilizatioPeriodDateApi(this.props.props.match.params)
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
        if (keyParam === "location_country") {
            this.setState({ previousLocation: value });
        }
        if (keyParam === "location_reg_aircraft") {
            this.setState({ locationRegA_c: value });
        }
        if (keyParam === "utilization_period") {
            let latestUtilMonth = moment(this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.utilization_period)[0]?.value)
            if (moment(value.value).diff(latestUtilMonth, 'days') > 31) {
                this.props.props.enqueueSnackbar("Previous Month Utilisation Not Entered", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        }

    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }
    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        this.setState({ file: "" })

        validationInputs = {
            ...validationInputs,
            utilization_period: taskErrorCode["utilization_period"][fieldValidation({ ...taskErrorCode["utilization_period_obj"], fieldval: data.utilization_period, })],
            monthly_FH: taskErrorCode["monthly_FH"][fieldValidation({ ...taskErrorCode["monthly_FH_obj"], fieldval: data.monthly_FH, })],
            monthly_FC: taskErrorCode["monthly_FC"][fieldValidation({ ...taskErrorCode["monthly_FC_obj"], fieldval: data.monthly_FC, })],
        }
        if (
            Object.keys(validationInputs).every((k) => {
                return validationInputs[k] === ""
            })
        ) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                },
            }))

            const props = this.props.props
            const utilData = data
            utilData.utilization_period = utilData.utilization_period ? utilData.utilization_period : ''
            utilData.location_country = utilData.location_country ? utilData.location_country : this.props.basicDetails?.country
            const prevsThrustValue = this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.current_operating_thrust)[0]
            const filterPosition = this.state.positionDropDownList.filter(item => item.id === this.props.basicDetails.position)[0]
            const prevsPositionValue = ({ label: filterPosition?.label, value: filterPosition?.id })
            utilData.location_position = utilData.location_position ? ({ value: data.location_position.id, label: data.location_position.label }) : prevsPositionValue
            utilData.asset_id = props.match.params.asset
            utilData.asset_type_id = props.match.params.assetType
            utilData.monthly_FC = utilData.monthly_FC ? +utilData.monthly_FC : 0
            utilData.monthly_FH = utilData.monthly_FH ? +utilData.monthly_FH : 0
            utilData.monthly_derate = utilData.monthly_derate ? +utilData.monthly_derate : 0
            utilData.tsn = this.props.assetsDetail?.length > 1 ? this.props.assetsDetail.sort((a, b) => b - a).filter(filterId => filterId?.id)[0]?.tsn + parseInt(utilData.monthly_FH) : +utilData.tsn
            utilData.csn = this.props.assetsDetail?.length > 1 ? this.props.assetsDetail.sort((a, b) => b - a).filter(filterId => filterId?.id)[0]?.csn + parseInt(utilData.monthly_FC) : +utilData.csn


            utilData.current_operating_thrust = utilData.current_operating_thrust ? utilData.current_operating_thrust : prevsThrustValue ? prevsThrustValue : null
            let latestUtilMonth = moment(this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.utilization_period)[0]?.value)
            if (moment(utilData.utilization_period.value).diff(latestUtilMonth, 'days') > 31) {
                this.props.props.enqueueSnackbar("Previous Month Utilisation Not Entered", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            } else {
                addUtilFormApi(utilData, props).then(async (response) => {
                    const uploadId = response.data.data.id
                    if (uploadId) {
                        await this.uploadFileTest(uploadId, props, false)
                    }

                })
                if (this.state.btnStatus !== "addAnother") {
                    this.props.closeAddForm()
                } else {
                    return false
                }
            }
        } else {
            this.setState({ error: validationInputs })
        }
    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
    updateData = async (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        this.setState({ file: "" })
        validationInputs = {
            ...validationInputs,
            utilization_period: taskErrorCode["utilization_period"][fieldValidation({ ...taskErrorCode["utilization_period_obj"], fieldval: data.utilization_period, })],
            monthly_FH: data.monthly_FH === '' ? taskErrorCode["monthly_FH"][fieldValidation({ ...taskErrorCode["monthly_FH_obj"], fieldval: data.monthly_FH, })] : '',
            monthly_FC: data.monthly_FC === '' ? taskErrorCode["monthly_FC"][fieldValidation({ ...taskErrorCode["monthly_FC_obj"], fieldval: data.monthly_FC, })] : '',
        }
        if (
            Object.keys(validationInputs).every((k) => {
                return validationInputs[k] === ""
            })
        ) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                },
            }))

            const updateId = this.props.editFormId
            const updatedData = this.state.data
            const props = this.props.props
            const prevsThrustValue = this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.current_operating_thrust)[0]
            updatedData.location_country = updatedData.location_country ? updatedData.location_country : this.props.basicDetails?.country
            updatedData.monthly_FC = updatedData.monthly_FC ? +updatedData.monthly_FC : 0
            updatedData.monthly_FH = updatedData.monthly_FH ? +updatedData.monthly_FH : 0
            updatedData.monthly_derate = updatedData.monthly_derate ? +updatedData.monthly_derate : 0
            const indexing = this.props.assetsDetail.map((item, index) => item.id).sort((b, a) => b - a)[0] === this.props.item?.id
            updatedData.tsn = indexing ? +data.tsn : this.props.assetsDetail?.length > 1 ? this.props.assetsDetail.sort((a, b) => b - a).filter(filterId => filterId?.id === this.props.item?.id - !indexing && 1)[0]?.tsn + +data.monthly_FH : +data.tsn
            updatedData.csn = indexing ? +data.csn : this.props.assetsDetail?.length > 1 ? this.props.assetsDetail.sort((a, b) => b - a).filter(filterId => filterId?.id === this.props.item?.id - !indexing && 1)[0]?.csn + +data.monthly_FC : +data.csn
            updatedData.current_operating_thrust = updatedData.current_operating_thrust ? updatedData?.current_operating_thrust : prevsThrustValue ? prevsThrustValue : null
            const filterPosition = this.state.positionDropDownList.filter(item => item.id === this.props.item?.location_position?.value)[0]
            const prevsPositionValue = ({ label: filterPosition?.label, value: filterPosition?.id })
            updatedData.location_position = updatedData.location_position ? ({ value: data.location_position.id ? data.location_position.id : data.location_position?.value, label: data.location_position.label }) : prevsPositionValue
            this.setState({
                data: this.props.editFormData,
            })
            if (updateId) {
                await this.uploadFileTest(updateId, props, true)
            }
            await this.props.updateFormData(updateId, updatedData, props)
            this.props.closeAddForm()
        } else {
            this.setState({ error: validationInputs })
        }
    }

    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 11
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 11,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id) => {
        if (id !== undefined && id !== 0) {
            const props = this.props.props
            deleteAdAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name === obj.file_name && file.type === obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {
            delete: true,
            file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
        };
        globalPutService(`camo/util/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type === "link" || uuid) {
            const { data } = this.state;
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {
                file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
            };
            globalPutService(`camo/util/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/util/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }

    tsnCalc() {
        const { data } = this.state;
        const filterdata = this.props.assetsDetail.filter(filterId => filterId);
        const currentIndex = filterdata.findIndex(item => item.id === this.props.item?.id);
        const indexLength = this.props.assetsDetail.filter(filterId => filterId)?.length - 1
        if (this.props.actionType === "add") {
            data.tsn = this.props.assetsDetail.sort((a, b) => b - a).filter(item => item)[0]?.tsn + parseInt(data?.monthly_FH) || ""
        } else {
            if (currentIndex >= 0 && currentIndex !== indexLength) {
                const previousEntry = filterdata[currentIndex + 1];
                data.tsn = previousEntry?.tsn + parseInt(data?.monthly_FH) || '';
            } else {
                data.tsn = this.props.assetsDetail.find(item => item.id === this.props.item?.id)?.tsn || '';
            }
        }
         return typeof data?.tsn === 'string' && data?.tsn > 0 ? parseFloat(data?.tsn).toFixed(2) : ''
    }

    csnCalc() {
        const { data } = this.state;
        const filterdata = this.props.assetsDetail.filter(filterId => filterId);
        const currentIndex = filterdata.findIndex(item => item.id === this.props.item?.id);
        const indexLength = this.props.assetsDetail.filter(filterId => filterId)?.length - 1
        if (this.props.actionType === "add") {
            data.csn = this.props.assetsDetail.sort((a, b) => b - a).filter(item => item)[0]?.csn + parseInt(data?.monthly_FC) || ""
        } else {
            if (currentIndex >= 0 && currentIndex !== indexLength) {
                const previousEntry = filterdata[currentIndex + 1];
                data.csn = previousEntry?.csn + parseInt(data?.monthly_FC) || '';
            } else {
                data.csn = this.props.assetsDetail.find(item => item.id === this.props.item?.id)?.csn || '';
            }
        }
        return data?.csn;
    }

    render() {
        const { data, error, attachments, operatingThrust, positionDropDownList, locationRegA_c, pageLoader, utilDateList } = this.state
        const operatingThrustName = operatingThrust.map((item => item.thrust))
        const prevsThrustValue = this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.operating_thrust_current)[0]
        const prevsPositionValue = positionDropDownList?.filter(item => item.id === this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.location_position.value)[0])[0]
        const prevslocationPosition = ({ label: prevsPositionValue?.label, value: prevsPositionValue?.id })
        const filteredOptions = utilDateList.filter((option) => option?.value > this.props.assetsDetail.sort((a, b) => b - a).map(filterId => filterId?.utilization_period)[0]?.value);
        const indexing = this.props.assetsDetail.map((item, index) => item.id).sort((b, a) => b - a)[0] === this.props.item?.id
        const prevsPosition = positionDropDownList.filter(item => item.id === this.props.basicDetails.position)[0]
        const prevslocationPos = ({ label: prevsPosition?.label, value: prevsPosition?.id })

        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <div id="HtTaskForm">
                        <DialogTitle>
                            {this.props.formTitle}
                            <CloseIcon
                                onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                                className="close-icon"
                            />
                        </DialogTitle>
                        <form
                            id="asset-form"
                            onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                        >
                            <DialogContent>
                                <Grid spacing={1} container>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={filteredOptions.length > 0 ? filteredOptions : utilDateList}
                                            getOptionLabel={(option) => `${option?.data}`}
                                            id="utilization_period"
                                            value={data?.utilization_period}
                                            disabled={this.props.actionType === "edit"}
                                            getOptionDisabled={(option) => !!data?.utilization_period && option.value === data?.utilization_period.value}
                                            onChange={(e, value) => this.onFieldChange("utilization_period", value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Utilization Period"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    placeholder="Select Utilization Period"
                                                    variant="outlined"
                                                    error={error.utilization_period ? true : false}
                                                    helperText={error.utilization_period ? error.utilization_period : ""}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment> Monthly FH <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                            name="monthly_FH"
                                            id="monthly_FH"
                                            value={data?.monthly_FH}
                                            error={error.monthly_FH ? true : false}
                                            helperText={error.monthly_FH ? error.monthly_FH : ""}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 4 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 3 && regexConstants.numberWithDot.test(numericValue);
                                                if (isValid || value === "") {
                                                    this.onFieldChange("monthly_FH", numericValue);
                                                }
                                                this.onRestErrorKey("monthly_FH");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label={<React.Fragment> Monthly FC <span style={{ color: "red" }}> *</span> </React.Fragment>}
                                            name="monthly_FC"
                                            id="monthly_FC"
                                            value={data?.monthly_FC}
                                            error={error.monthly_FC ? true : false}
                                            helperText={error.monthly_FC ? error.monthly_FC : ""}
                                            onChange={(e) => { e.target.value.length <= 3 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('monthly_FC', e.target.value); this.onRestErrorKey("monthly_FC") }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="TSN"
                                            id="tsn"
                                            disabled={this.props.actionType === "edit" ? indexing ? false : this.props.assetsDetail?.length > 1 : this.props.assetsDetail?.length > 0}
                                            value={
                                                this.props.assetsDetail?.length === 1 && this.props.actionType === "edit" ?
                                                    data.tsn : indexing ? data.tsn : this.props.assetsDetail?.length > 1 && this.props.actionType === "edit" ? this.tsnCalc() :
                                                        this.props.assetsDetail?.length > 0 && this.props.actionType === "add" ?
                                                            this.tsnCalc() : data.tsn}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                                if (isValid || value === "") {
                                                    this.onFieldChange("tsn", numericValue);
                                                }
                                                this.onRestErrorKey("tsn");
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="CSN"
                                            id="csn"
                                            disabled={this.props.actionType === "edit" ? indexing ? false : this.props.assetsDetail?.length > 1 : this.props.assetsDetail?.length > 0}
                                            value={
                                                this.props.assetsDetail?.length === 1 && this.props.actionType === "edit" ?
                                                    data.csn : indexing ? data.csn : this.props.assetsDetail?.length > 1 && this.props.actionType === "edit" ? this.csnCalc() :
                                                        this.props.assetsDetail?.length > 0 && this.props.actionType === "add" ?
                                                            this.csnCalc() : data.csn}
                                            onChange={(e) => e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('csn', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="Monthly Derate"
                                            id="monthly_derate"
                                            value={data.monthly_derate ? data.monthly_derate : ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const numericValue = value.replace(/[^\d.]/g, '');
                                                const dotCount = (numericValue.match(/\./g) || []).length;
                                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 2 && regexConstants.numberWithDot.test(numericValue);
                                                if (isValid || value === "") {
                                                    this.onFieldChange("monthly_derate", numericValue);
                                                }
                                                this.onRestErrorKey("monthly_derate");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={operatingThrust ? operatingThrust : []}
                                            getOptionLabel={(option) => option?.name}
                                            id="current_operating_thrust"
                                            value={data?.current_operating_thrust ? data?.current_operating_thrust : prevsThrustValue}
                                            onChange={(e, value) => this.onFieldChange("current_operating_thrust", value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Op.Thrust (Current)"
                                                    margin="normal"
                                                    fullWidth
                                                    placeholder="Select Op.Thrust (Current)"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="Location Reg Aircraft"
                                            id="location_reg_aircraft"
                                            value={data?.location_reg_aircraft ? data?.location_reg_aircraft : locationRegA_c}
                                            onChange={(e) => { this.onFieldChange('location_reg_aircraft', e.target.value); this.onRestErrorKey("location_reg_aircraft") }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={positionDropDownList ? positionDropDownList : []}
                                            getOptionLabel={(option) => option?.label}
                                            id="location_position"
                                            value={data?.location_position ? data?.location_position : prevslocationPosition?.value ? prevslocationPosition : prevslocationPos ? prevslocationPos : null}
                                            onChange={(e, value) => this.onFieldChange("location_position", value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Location Position"
                                                    margin="normal"
                                                    fullWidth
                                                    placeholder="Select Op.Thrust (Current)"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            label="Location Country"
                                            id="location_country"
                                            value={data.location_country ? data?.location_country : this.state.previousLocation ? this.state.previousLocation : this.props.basicDetails.country || ''}
                                            onChange={(e) => { this.onFieldChange('location_country', e.target.value); this.onRestErrorKey("location_country") }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="date_of_installation"
                                                label="Date Of Installation"
                                                format={fieldDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.date_of_installation}
                                                error={error.date_of_installation ? true : false}
                                                helperText={error.date_of_installation ? error.date_of_installation : ""}
                                                onFocus={() => this.onRestErrorKey("date_of_installation")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("date_of_installation", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("date_of_installation")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                id="date_of_removal"
                                                label="Date of Removal"
                                                format={fieldDateFormat}
                                                inputVariant="outlined"
                                                fullWidth
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, "years")}
                                                value={data.date_of_removal}
                                                error={error.date_of_removal ? true : false}
                                                helperText={error.date_of_removal ? error.date_of_removal : ""}
                                                onFocus={() => this.onRestErrorKey("date_of_removal")}
                                                onChange={(data, value) => {
                                                    this.onFieldChange("date_of_removal", moment(data).format(backendDateFormat))
                                                    this.onRestErrorKey("date_of_removal")
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} container>
                                    <Grid item md={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Removal Reason"
                                            id="reason_of_removal"
                                            value={data.reason_of_removal ? data.reason_of_removal : ''}
                                            onChange={(e) => { this.onFieldChange('reason_of_removal', e.target.value); this.onRestErrorKey("reason_of_removal") }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className="border-grey"></div>
                                <Grid spacing={1} container>
                                    <Grid item xs={6}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            placeholder="Remark"
                                            name="remarks"
                                            id="remarks"
                                            style={{ height: "70px", width: "560px", padding: '3px' }}
                                            value={data.remarks ? data.remarks : ""}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (input.length <= 255) {
                                                    this.onFieldChange("remarks", input)
                                                    this.onRestErrorKey("remarks")
                                                } else {
                                                    const truncatedInput = input.slice(0, 255)
                                                    this.onFieldChange("remarks", truncatedInput)
                                                    this.onRestErrorKey("remarks")
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                            <ul className="list-inline spacing-list">
                                                <li className="list-inline-item" onClick={this.handleOpenUploadFile}>
                                                    <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                                </li>
                                                <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                    <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="actions-btn">
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                                >
                                    {this.props.actionType === "add" ? "Add" : "Update"}
                                </Button>

                                {this.props.actionType === "add" ? (
                                    <Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                                ) : (
                                    ""
                                )}
                                <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}  > Close </Button>
                            </DialogActions>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseAttachment}
                                id="htDialog"
                            >
                            </Dialog>
                            <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                                <LinkFiles
                                    handleClose={() => this.handleCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                    modType="util"
                                    last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                    uploadFileInfo={data}
                                    attachments={attachments}
                                    type="link"
                                />
                            </Dialog>
                            <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                                <UploadFile
                                    handleClose={() => this.handleUploadFileCloseFile()}
                                    uuid={this.props?.uuid?.uuid}
                                    editFormId={this.props.editFormId}
                                    getResponseBack={(file) => this.getResponseBack(file)}
                                    listResponseBack={(file) => this.listResponseBack(file)}
                                    actionType={this.props.actionType}
                                    onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                    modType="util"
                                    uploadFileInfo={data}
                                    last_used_folder_uuid={this.props.last_used_folder_uuid}
                                    onUpload={(file) => this.onUpload(file)}
                                    type="upload"
                                    checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                    checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                                />
                            </Dialog>
                        </form>
                    </div>
                    {pageLoader ? <PageLoader /> : null}
                </div>
            </Fragment>
        )
    }
}
export default UtilizationFormData