import React, { Component, Fragment } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TextareaAutosize, } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/InventoryTaskformData"
import axios from 'axios';
import { addTaskFormApi, deleteAttachment, inventorySectionList } from "../apiServices"
import { globalPutService } from "../../../../utils/globalApiServices"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { checkApiStatus } from "../../../../utils"
import SectionCreates from "../../../../shared_elements/components/SectionCreates"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { regexConstants } from "../../../../constants/regEx"
class InventoryTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            attachments: [],
            btnStatus: "",
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false,
            sectionList: [],
            pageLoader: false
        }
        this.addTaskFormApi = addTaskFormApi.bind(this)
        this.inventorySectionList = inventorySectionList.bind(this)
        this.dropDownChange1 = this.dropDownChange1.bind(this)
        this.dropDownChange2 = this.dropDownChange2.bind(this)

    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
        this.inventorySectionList(this.props)
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };
    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value) => {
        const { data } = this.state

        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
    }
    dropDownChange1(e, keyParam, value) {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                installed: e.target.value,
            },
        }))
    }
    dropDownChange2 = (e, keyParam, value) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [keyParam]: value,
            },
        }))
    }
    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        validationInputs = {
            description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description })]
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })
        ) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                },
            }))
            const props = this.props.props
            const inventoryData = data
            inventoryData.asset_id = props.match.params.asset
            inventoryData.asset_type_id = props.match.params.assetType
            inventoryData.quantity = inventoryData?.quantity ? parseInt(inventoryData.quantity) : 0
            inventoryData.tsn = inventoryData?.tsn ? parseFloat(inventoryData.tsn) : null
            inventoryData.csn = inventoryData?.csn ? parseFloat(inventoryData.csn) : null
            inventoryData.tso = inventoryData?.tso ? parseFloat(inventoryData.tso) : null
            inventoryData.cso = inventoryData?.cso ? parseFloat(inventoryData.cso) : null
            addTaskFormApi(inventoryData, props).then(async (response) => {
                const uploadId = response.data.data.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                    this.props.getResponseBack()
                }
            })
            if (this.state.btnStatus !== "addAnother") {
                this.props.closeAddForm()
            } else {
                return false
            }
        } else {
            this.setState({ error: validationInputs })
        }

    }

    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }

    }

    updateData = async (e) => {
        e.preventDefault()
        let validationInputs = {}
        const { data } = this.state
        this.setState({ file: "" })
        validationInputs = {
            description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description })]
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
            let payload = Object.assign({}, data)
            payload = {
                ...payload,
                quantity: data.quantity ? parseInt(data.quantity) : 0,
                tsn: data.tsn ? parseFloat(data.tsn) : null,
                csn: data.csn ? parseFloat(data.csn) : null,
                tso: data.tso ? parseFloat(data.tso) : null,
                cso: data.cso ? parseFloat(data.cso) : null,
            }
            const updateId = this.props.editFormId
            this.setState({
                data: this.props.editFormData,
            })
            const props = this.props.props
            if (updateId) {
                await this.uploadFileTest(updateId, props, true)
            }
            await this.props.updateFormData(updateId, payload, props)
            this.props.closeAddForm()
        } else {
            this.setState({ error: validationInputs })
        }
    }

    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 10
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append("[" + key + "]file", this.state.newUploadedAttachments[key])
                formdata.append("[" + key + "]file_type", this.state.newUploadedAttachments[key].type)
                formdata.append("[" + key + "]file_name", this.state.newUploadedAttachments[key].name)
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 10,
                file_name: file.name,
                file_type: file.type,
                file: "upload file",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })
        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }
    deleteAttachmentFile = async (id, attachments) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        } else {
            this.onRemoveCamoFile(attachments)
        }
    }
    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]
        var filteredArray = []
        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })
    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {
            delete: true,
            file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
        };
        globalPutService(`camo/inventory/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });

    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || "edit") {
            return null
        } else {
            this.props.getResponseBack(file)
        }

    }

    onLinkFile = (file, uuid, type) => {
        const { data } = this.state;
        if (type == "link" || uuid) {
            const folderUUID = [...data.folder_uuid, uuid];
            const updatedChecklist = [...data.checklist, file];

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData
                },
            });
        } else {
            this.onUpload(file)
        }
    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {
                file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
            };
            globalPutService(`camo/inventory/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/inventory/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }

    render() {
        const { data, attachments, error, sectionList } = this.state
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <DialogTitle className="just-center">
                        {this.props.formTitle}
                        <CloseIcon onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} className="close-icon" />
                    </DialogTitle>
                    <form id="asset-form" onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)} >
                        <DialogContent>
                            <Grid spacing={1} container>
                                <Grid item md={3}  >
                                    <SectionCreates
                                        label='Section'
                                        options={sectionList}
                                        getOptionLabel={(option) => option}
                                        paramsKey='section'
                                        optionKey='name'
                                        value={data.section}
                                        onFieldChange={this.dropDownChange2}
                                    />
                                </Grid>
                                <Grid item md={9}  >
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Description"
                                        id="description"
                                        error={error.description ? true : false}
                                        helperText={error.description ? error.description : ""}
                                        value={data.description ? data.description : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 250) {
                                                this.onFieldChange("description", input)
                                                this.onRestErrorKey("description")
                                            } else {
                                                const truncatedInput = input.slice(0, 250)
                                                this.onFieldChange(
                                                    "description",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("description")
                                            }
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="IIN"
                                        name="iin"
                                        id="iin"
                                        value={data.iin ? data.iin : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("iin", input)
                                                this.onRestErrorKey("iin")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "iin",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("iin")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Spec"
                                        id="spec"
                                        value={data.spec ? data.spec : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("spec", input)
                                                this.onRestErrorKey("spec")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "spec",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("spec")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="IPC & PPBU FIG. / REF"
                                        id="ipc_ppbu_fig_ref"
                                        value={data.ipc_ppbu_fig_ref ? data.ipc_ppbu_fig_ref : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("ipc_ppbu_fig_ref", input)
                                                this.onRestErrorKey("ipc_ppbu_fig_ref")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "ipc_ppbu_fig_ref",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("ipc_ppbu_fig_ref")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Quantity"
                                        id="quantity"
                                        value={data.quantity ? data.quantity : ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.onlyNumeric.test(numericValue);

                                            if (isValid || value === "") {
                                                this.onFieldChange("quantity", numericValue);
                                            }

                                            this.onRestErrorKey("quantity");
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Part Number"
                                        id="part_number"
                                        value={data.part_number ? data.part_number : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("part_number", input)
                                                this.onRestErrorKey("part_number")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "part_number",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("part_number")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Serial Number"
                                        id="serial_number"
                                        value={data.serial_number ? data.serial_number : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("serial_number", input)
                                                this.onRestErrorKey("serial_number")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "serial_number",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("serial_number")
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="TSN"
                                        id="tsn"
                                        value={data.tsn ? data.tsn : ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (isValid || value === "") {
                                                this.onFieldChange("tsn", numericValue);
                                            }
                                            this.onRestErrorKey("tsn");
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} id="ht-dropdown">
                                    <FormControl>
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            className="dropdown-label"
                                        >
                                            Installed
                                        </InputLabel>
                                        <Select
                                            labelId="installed"
                                            id="installed"
                                            variant="outlined"
                                            value={data.installed}
                                            onChange={this.dropDownChange1}
                                        >
                                            <MenuItem value={"Yes"}>YES</MenuItem>
                                            <MenuItem value={"No"}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="CSN"
                                        id="csn"
                                        value={data.csn ? data.csn : ""}
                                        onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('csn', e.target.value); this.onRestErrorKey("csn") }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="TSO"
                                        id="tso"
                                        value={data.tso ? data.tso : ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (isValid || value === "") {
                                                this.onFieldChange("tso", numericValue);
                                            }
                                            this.onRestErrorKey("tso");
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="CSO"
                                        id="cso"
                                        value={data.cso ? data.cso : ""}
                                        onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('cso', e.target.value); this.onRestErrorKey("cso") }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Condition"
                                        id="condition"
                                        value={data.condition ? data.condition : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 20) {
                                                this.onFieldChange("condition", input)
                                                this.onRestErrorKey("condition")
                                            } else {
                                                const truncatedInput = input.slice(0, 20)
                                                this.onFieldChange(
                                                    "condition",
                                                    truncatedInput
                                                )
                                                this.onRestErrorKey("condition")
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="border-grey"></div>
                            <Grid spacing={1} container>
                                <Grid item xs={6}  >
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        placeholder="Remarks"
                                        name="remarks"
                                        style={{ height: "70px", width: "560px", padding: '3px' }}
                                        value={data.remarks ? data.remarks : ""}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 255) {
                                                this.onFieldChange("remarks", input)
                                                this.onRestErrorKey("remarks")
                                            } else {
                                                const truncatedInput = input.slice(0, 255)
                                                this.onFieldChange("remarks", truncatedInput)
                                                this.onRestErrorKey("remarks")
                                            }
                                        }}
                                        onFocus={() => this.onRestErrorKey("remarks")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                        <ul className="list-inline spacing-list">
                                            <li className="list-inline-item" onClick={this.handleOpenUploadFile} >
                                                <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                            </li>
                                            <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid id="data-container" spacing={1} container>
                                <Grid spacing={1} container>
                                    {this.props.actionType === 'add' ?
                                        <div className='checklist-files'>
                                            <ul className='list-inline'>
                                                {data?.checklist?.map((file) =>
                                                    <li className='list-inline-item'>
                                                        <span className='file-name'>{file.name}</span>
                                                        <span className='file-remove'>
                                                            <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                            <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                        </span>
                                                    </li>
                                                )}
                                                {attachments.length > 0 && attachments?.map((attachments) =>
                                                    <li className='list-inline-item'>
                                                        <span className='file-name'>{attachments.file_name}</span>
                                                        <span className='file-remove'>
                                                            <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                            <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                        </span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        :
                                        <div className='checklist-files'>
                                            <ul className='list-inline'>
                                                {data?.checklist?.files?.map((file) =>
                                                    <li className='list-inline-item'>
                                                        <span className='file-name'>{file.name}</span>
                                                        <span className='file-remove'>
                                                            <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                            <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                        </span>
                                                    </li>
                                                )}
                                                {attachments?.map((attachments) =>
                                                    <li className='list-inline-item'>
                                                        <span className='file-name'>{attachments.file_name}</span>
                                                        <span className='file-remove'>
                                                            <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                            <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
                                                        </span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="actions-btn">
                            <Button type="button" className="btn btn-primary" onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)} >
                                {this.props.actionType === "add" ? "Add" : "Update"}
                            </Button>
                            {this.props.actionType === "add" ? (
                                <Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                            ) : (
                                ""
                            )}
                            <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
                        </DialogActions>
                        <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                            <LinkFiles
                                handleClose={() => this.handleCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                modType="inventory"
                                last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                uploadFileInfo={data}
                                attachments={attachments}
                                type="link"
                            />
                        </Dialog>
                        <Dialog open={this.state.uploadFile} onClose={this.handleUploadCloseFile} id="htDialog">
                            <UploadFile
                                handleClose={() => this.handleUploadCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                modType="inventory"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid}
                                onUpload={(file) => this.onUpload(file)}
                                type="upload"
                                checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                            />
                        </Dialog>
                    </form>
                </div>
            </Fragment>
        )
    }
}
export default InventoryTaskForm