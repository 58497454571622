import { FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tab, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { fillChartAdApi, fillChartAmpApi, fillChartHtApi, fillChartModsApi, fillChartSbApi, fillChartSrApi, fillChartLLpApi, fillChartSoftTimeApi } from "../apiServices";
import moment from "moment";
import { fieldDateFormat } from "../../../constants";
import { withRouter } from "react-router-dom";
import { getLocalStorageInfo } from "../../../utils";
import config from '../../../config';
import { trackActivity } from "../../../utils/mixpanel";

const DashboardChart = ({ location, history }) => {
	const [overdueData, setOverdueData] = useState([]);
	const [upcomingData, setUpcomingData] = useState([]);
	const [pieData, setPieData] = useState({});
	const [value, setValue] = React.useState(0);
	const [selectedData, setSelectedData] = useState(value);
	const [llpData, setLlpData] = useState([])
	const [softTime, setsoftTime] = useState()
	
	useEffect(() => {
		trackActivity('CAMO', {
			event_type: 'Page Visited',
			page_title: 'Dashboard',
		})
		let apiFunction, selectedDataValue;
		switch (location?.search) {
			case "?ad_Module":
				apiFunction = fillChartAdApi;
				selectedDataValue = "Airworthiness Directives (ADs)";
				break;
			case "?ht_Module":
				apiFunction = fillChartHtApi;
				selectedDataValue = "Hard Time Components";
				break;
			case "?sb_Module":
				apiFunction = fillChartSbApi;
				selectedDataValue = "Service Bulletins";
				break;
			case "?sr_Module":
				apiFunction = fillChartSrApi;
				selectedDataValue = "Structure Repairs";
				break;
			case "?amp_Module":
				apiFunction = fillChartAmpApi;
				selectedDataValue = "AMP/MRB Compliance";
				break;
			case "?mods_Module":
				apiFunction = fillChartModsApi;
				selectedDataValue = "Modifications";
				break;
			case "?llp_Module":
				apiFunction = fillChartLLpApi;
				selectedDataValue = "LLP";
				break;
			case "?soft_time":
				apiFunction = fillChartSoftTimeApi;
				selectedDataValue = "Soft Time";
				break;
			default:
				apiFunction = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? fillChartHtApi : fillChartAmpApi;
				selectedDataValue = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? "Hard Time Components" : "AMP/MRB Compliance";
				break;
		}
		apiFunction(value).then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
			setsoftTime(response.data?.data);
			console.log("first", response.data?.data)
		});
		setSelectedData(selectedDataValue);

	}, [location?.search]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue === 0 && selectedData === "AMP/MRB Compliance") {
			fillChartAmpApi().then((response) => {
				setOverdueData(response.data.overdue);
				setUpcomingData(response.data.upcoming);
				setPieData(response.data);
			});
			setSelectedData(selectedData);
		} else if (selectedData === "Hard Time Components") {
			fillChartHtApi(newValue).then((response) => {
				setOverdueData(response.data.overdue);
				setUpcomingData(response.data.upcoming);
				setPieData(response.data);
			});
			setSelectedData(selectedData === "AMP/MRB Compliance" ? "Hard Time Components" : selectedData)
		} else if (selectedData === "Airworthiness Directives (ADs)") {
			fillChartAdApi(newValue).then((response) => {
				setOverdueData(response.data.overdue);
				setUpcomingData(response.data.upcoming);
				setPieData(response.data);
			});
			setSelectedData(selectedData === "AMP/MRB Compliance" ? "Hard Time Components" : selectedData)
		}
		else if (selectedData === "Service Bulletins") {
			fillChartSbApi(newValue).then((response) => {
				setOverdueData(response.data.overdue);
				setUpcomingData(response.data.upcoming);
				setPieData(response.data);
			});
			setSelectedData(selectedData === "AMP/MRB Compliance" ? "Hard Time Components" : selectedData)
		} else if (selectedData === "Soft Time") {
			fillChartSoftTimeApi(newValue).then((response) => {
				setsoftTime(response.data?.data);
			});
			setSelectedData(selectedData === "AMP/MRB Compliance" ? "Hard Time Components" : selectedData)

		} else if (newValue === 1 && selectedData === "AMP/MRB Compliance" || "Structure Repairs" || "Modifications") {
			fillChartHtApi(newValue).then((response) => {
				setOverdueData(response.data.overdue);
				setUpcomingData(response.data.upcoming);
				setPieData(response.data);
			});
			setSelectedData(selectedData === "AMP/MRB Compliance" || "Structure Repairs" || "Modifications" ? "Hard Time Components" : selectedData)
		};
	}

	const onSelectAmpItem = () => {
		fillChartAmpApi().then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
	};
	const onSelectHtItem = () => {
		fillChartHtApi(value).then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectAdItem = () => {
		fillChartAdApi(value).then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectSbItem = () => {
		fillChartSbApi(value).then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectSrItem = () => {
		fillChartSrApi().then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectModsItem = () => {
		fillChartModsApi().then((response) => {
			setOverdueData(response.data.overdue);
			setUpcomingData(response.data.upcoming);
			setPieData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectLLpItem = () => {
		fillChartLLpApi().then((response) => {
			setLlpData(response.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};
	const onSelectSoftTimeItem = () => {
		fillChartSoftTimeApi().then((response) => {
			setsoftTime(response.data?.data);
		});
		let urlPath = location.pathname;
		history.push(urlPath,);
	};


	const options = {
		chart: {
			type: "pie",
		},
		title: {
			text: "Tasks Overview",
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				depth: 35,
				showInLegend: true,
				dataLabels: {
					enabled: false,
					format: "{point.name}",
				},
			},
		},
		series: [
			{
				name: "Tasks",
				colorByPoint: true,
				data: [
					{
						name: `Total Overdue`,
						color: "#a2a05b",
						y: pieData.total_overdue,
					},
					{
						name: `Total Due in 30`,
						color: "#81a27b",
						y: pieData.total_due_in_30,
					},
					{
						name: `Total Due in 60`,
						color: "#5c95db",
						y: pieData.total_due_in_60,
					},
					{
						name: `Total Due in 90`,
						color: "#ff6565",
						y: pieData.total_due_in_90,
					},
				],
			},
		],
		credits: {
			enabled: false,
		},
	};

	const dropDownChange = (e) => {
		setSelectedData(e.target.value);
	};

	return (
		<div id="analytics">
			<Paper square style={{ height: "60px" }}>
				<Grid container>
					<Grid item xs={8}>
						<Tabs
							value={value}
							indicatorColor="primary"
							textColor="primary"
							onChange={handleChange}
							aria-label="disabled tabs example"
						>
							{getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? null : <Tab label="Aircraft" />}
							<Tab label="Engine" />
						</Tabs>
					</Grid>
					<Grid item xs={4}>
						<FormControl style={{ marginTop: '13px' }}>
							<InputLabel id="demo-simple-select-label">Select Module</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="analytics-dropdown"
								variant="outlined"
								value={selectedData}
								onChange={dropDownChange}
							>
								{getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? null : value == 0 && <MenuItem value={"AMP/MRB Compliance"} onClick={onSelectAmpItem}> AMP/MRB Compliance </MenuItem>}
								<MenuItem value={"Hard Time Components"} onClick={onSelectHtItem}> Hard Time Components </MenuItem>
								<MenuItem value={"Airworthiness Directives (ADs)"} onClick={onSelectAdItem} > Airworthiness Directives (ADs) </MenuItem>
								<MenuItem value={"Service Bulletins"} onClick={onSelectSbItem}> Service Bulletins </MenuItem>
								{getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? null : value == 0 && <MenuItem value={"Structure Repairs"} onClick={onSelectSrItem}> Structure Repairs </MenuItem>}
								{getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) ? null : value == 0 && <MenuItem value={"Modifications"} onClick={onSelectModsItem}> Modifications </MenuItem>}
								{getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) && value == 0 ? <MenuItem value={"LLP"} onClick={onSelectLLpItem}> Life Limited Part (LLPs) </MenuItem> :
									getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532) && value == 1 && <MenuItem value={"LLP"} onClick={onSelectLLpItem}> Life Limited Part (LLPs) </MenuItem>
								}
								<MenuItem value={"Soft Time"} onClick={onSelectSoftTimeItem}> Soft Time </MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Paper>
			<div id="analytics-datatable">
				{selectedData === 'LLP' || selectedData === 'Soft Time' ? null : <Grid container className="mt-20">
					<Grid xs={6} item className="table-border">
						<span class="header">Overdue</span>
						<Table className="table-wrapper">
							<TableHead>
								<TableRow>
									<TableCell align="center"> {pieData.table_row_title} </TableCell>
									<TableCell align="center">Due Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{overdueData?.length === 0 ? (
									<TableRow>
										<TableCell className="no-records" colSpan={2}>
											<span className="center font-400"> No Records Found </span>
										</TableCell>
									</TableRow>
								) : (
									<>
										{overdueData?.map((item, i) => {
											return [
												<TableRow key={i}>
													<TableCell> {item.title ? item.title : "--"} </TableCell>
													<TableCell> {item.due_at ? moment(item.due_at).format(fieldDateFormat) : "--"} </TableCell>
												</TableRow>,
											];
										})}
									</>
								)}
							</TableBody>
						</Table>
					</Grid>
					<Grid xs={6} item className="table-border">
						<span class="header">Upcoming</span>
						<Table className="table-wrapper">
							<TableHead>
								<TableRow>
									<TableCell align="center"> {pieData.table_row_title} </TableCell>
									<TableCell align="center">Due Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{upcomingData?.length === 0 ? (
									<TableRow>
										<TableCell className="no-records" colSpan={2}>
											<span className="center font-400"> No Records Found </span>
										</TableCell>
									</TableRow>
								) : (
									<>
										{upcomingData?.map((item, i) => {
											return [
												<TableRow key={i}>
													<TableCell> {item.title ? item.title : "--"} </TableCell>
													<TableCell> {item.due_at ? moment(item.due_at).format(fieldDateFormat) : "--"} </TableCell>
												</TableRow>,
											];
										})}
									</>
								)}
							</TableBody>
						</Table>
					</Grid>
				</Grid>}
				{selectedData === 'LLP' || selectedData === "Soft Time" ? null : <div className="claims-side-card" style={{ background: "#fff" }}>
					<div className="status-data"> <PieChart highcharts={Highcharts} options={options} /> </div>
				</div>}
				{selectedData === 'LLP' ? <div id="analytics-datatable">
					<Grid xs={12} item className="table-border">
						<Table className="table-wrapper">
							<TableHead >
								<TableRow style={{ backgroundColor: '#f5f7fa' }}>
									<TableCell align="center"> Engine Serial Number</TableCell>
									<TableCell align="center"> Part Number </TableCell>
									<TableCell align="center">Description </TableCell>
									<TableCell align="center"> Remaining Limiter </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{llpData?.length === 0 ? (
									<TableRow>
										<TableCell className="no-records" colSpan={2}>
											<span className="center font-400"> No Records Found </span>
										</TableCell>
									</TableRow>
								) : (
									<>
										{Object.values(llpData).map((entry, index) => (
											<TableRow key={index}>
												<TableCell> {entry.engine_serial_number}</TableCell>
												<TableCell> {entry.llp_part_number} </TableCell>
												<TableCell> {entry.llp_description} </TableCell>
												<TableCell> {entry.life_remaining_limiter} </TableCell>
											</TableRow>
										))
										}
									</>
								)}
							</TableBody>
						</Table>
					</Grid>
				</div> : null}
				{selectedData === 'Soft Time' ? <div id="analytics-datatable">
					<Grid xs={12} item className="table-border">
						<Table className="table-wrapper">
							<TableHead >
								<TableRow style={{ backgroundColor: '#f5f7fa' }}>
									<TableCell align="center"> Serial Number</TableCell>
									<TableCell align="center"> Part Number </TableCell>
									<TableCell align="center"> Description </TableCell>
									<TableCell align="center"> Remaining Limiter </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{softTime?.length === 0 ? (
									<TableRow>
										<TableCell className="no-records" colSpan={2}>
											<span className="center font-400"> No Records Found </span>
										</TableCell>
									</TableRow>
								) : (
									<>
										{softTime &&
											Object.values(softTime).map((entry, index) => (
												<TableRow key={index}>
													<TableCell> {entry.engine_serial_number}</TableCell>
													<TableCell> {entry.part_number} </TableCell>
													<TableCell> {entry.description} </TableCell>
													<TableCell> {entry.life_remaining_limiter} </TableCell>
												</TableRow>
											))}
									</>
								)}
							</TableBody>
						</Table>
					</Grid>
				</div> : null}
			</div>
		</div>
	);
};

export default withRouter(DashboardChart);
