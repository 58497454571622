import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { DashboardChart, SmbcDashboardList } from "../components";
import { getLocalStorageInfo } from "../../../utils";
import config from '../../../config'

function DashboardWrapper(HocComponent, extraProps = {}) {
	class EnhancedComponent extends Component {

		render() {
			let smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)
			return (
					<div className="camo-dashboard">
						{smbcInstance ? <SmbcDashboardList /> :
							<>
								<DashboardChart />
								<HocComponent {...this.props} {...extraProps} />
							</>
						}
					</div>
			);
		}
	}

	return withRouter(EnhancedComponent);
}

export default DashboardWrapper;
