import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, Dialog, TextareaAutosize } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/AmpTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import MomentUtils from "@date-io/moment"
import { regexConstants } from "../../../../constants/regEx"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addTaskFormApi, deleteAttachment, } from "../apiServices"
import { globalPutService } from "../../../../utils/globalApiServices"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import AmpConfirmDeleteAttachment from "./AmpConfirmDeleteAttachment";
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { trackActivity } from '../../../../utils/mixpanel';
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import UploadFile from "../../../../shared_elements/components/UploadFile";

class AmpTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			regex: "^[0-9]+$",
			numberError: false,
			selectLastDDate: null,
			selectNextDueDate: null,
			attachments: [],
			btnStatus: "",
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
			showSuccessUpload: false,
			globalValidationInputs: {},
			validatedFields: [],
			linkFile: false,
			uploadFile: false
		}

		this.addTaskFormApi = addTaskFormApi.bind(this)
	}

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData();
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'AMP Add Task Form',
		})
	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	onFieldChange = (keyParam, value) => {
		const { data } = this.state
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				[keyParam]: value,
			},
		}))

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				nextDue_FH: data.nextDue_FH,
			},
		}))
	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}
	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })

		validationInputs = {
			MPD_item_no: taskErrorCode["MPD_item_no"][fieldValidation({ ...taskErrorCode["MPD_item_no_obj"], fieldval: data.MPD_item_no, })],
			task_card_no: taskErrorCode["task_card_no"][fieldValidation({ ...taskErrorCode["task_card_no_obj"], fieldval: data.task_card_no, })],
			Task_Description: taskErrorCode["Task_Description"][fieldValidation({ ...taskErrorCode["Task_Description_obj"], fieldval: data.Task_Description, })],
			threshold_D: data.threshold_FH || data.threshold_FC ? '' : taskErrorCode["threshold_D"][fieldValidation({ ...taskErrorCode["threshold_D_obj"], fieldval: data.threshold_D !== null ? String(data.threshold_D) : "", })],
			interval_FH: data.interval_FH ? "" : data.threshold_FH ?  taskErrorCode["interval_FH"][fieldValidation({ ...taskErrorCode["interval_FH_obj"], fieldval: data.interval_FH, })] : '',
			interval_FC: data.interval_FC ? "" : data.threshold_FC ?  taskErrorCode["interval_FC"][fieldValidation({ ...taskErrorCode["interval_FC_obj"], fieldval: data.interval_FC, })]: '',
			interval_D: data.threshold_D === null || data.threshold_D === '' ? '' : taskErrorCode["interval_D"][fieldValidation({ ...taskErrorCode["interval_D_obj"], fieldval: data.interval_D, })],
			lastDone_D:data.lastDone_D ? "" : data.threshold_D ? taskErrorCode["lastDone_D"][fieldValidation({ ...taskErrorCode["lastDone_D_obj"], fieldval: data.lastDone_D, })] : '',
			lastDone_FH:data.lastDone_FH ? "" : data.interval_FH ? taskErrorCode["lastDone_FH"][fieldValidation({ ...taskErrorCode["lastDone_FH_obj"], fieldval: data.lastDone_FH, })] : '',
			lastDone_FC: data.lastDone_FC ? "" : data.interval_FC ? taskErrorCode["lastDone_FC"][fieldValidation({ ...taskErrorCode["lastDone_FC_obj"], fieldval: data.lastDone_FC, })] : '',
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })
		) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			const props = this.props.props
			const ampFormData = data
			ampFormData.asset_id = props.match.params.asset
			ampFormData.asset_type_id = props.match.params.assetType
			ampFormData.nextDue_FC=ampFormData.nextDue_FC  ? ampFormData.nextDue_FC  : null
			ampFormData.nextDue_FH=ampFormData.nextDue_FH  ? ampFormData.nextDue_FH  : null
			addTaskFormApi(ampFormData, props).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'AMP Compliance',
						item_id: uploadId ? uploadId : '',
						request_body: ampFormData ? ampFormData : '',
						response_msg: response.data.message ? response.data.message : ''
					  })
				}
			})
			if (this.state.btnStatus !== "addAnother") {
				this.props.closeAddForm()
			} else {
				return false
			}
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'AMP Compliance',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}

	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}

	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })
		validationInputs = {
			MPD_item_no: taskErrorCode["MPD_item_no"][fieldValidation({ ...taskErrorCode["MPD_item_no_obj"], fieldval: data.MPD_item_no, })],
			task_card_no: taskErrorCode["task_card_no"][fieldValidation({ ...taskErrorCode["task_card_no_obj"], fieldval: data.task_card_no, })],
			Task_Description: taskErrorCode["Task_Description"][fieldValidation({ ...taskErrorCode["Task_Description_obj"], fieldval: data.Task_Description, })],
			threshold_D: data.threshold_FH || data.threshold_FC ? '' : taskErrorCode["threshold_D"][fieldValidation({ ...taskErrorCode["threshold_D_obj"], fieldval: data.threshold_D !== null ? String(data.threshold_D) : "", })],
			interval_FH: data.interval_FH ? "" : data.threshold_FH ?  taskErrorCode["interval_FH"][fieldValidation({ ...taskErrorCode["interval_FH_obj"], fieldval: data.interval_FH, })] : '',
			interval_FC: data.interval_FC ? "" : data.threshold_FC ?  taskErrorCode["interval_FC"][fieldValidation({ ...taskErrorCode["interval_FC_obj"], fieldval: data.interval_FC, })]: '',
			interval_D: data.threshold_D === null || data.threshold_D === '' ? '' : taskErrorCode["interval_D"][fieldValidation({ ...taskErrorCode["interval_D_obj"], fieldval: data.interval_D, })],
			lastDone_D:data.lastDone_D ? "" : data.threshold_D ? taskErrorCode["lastDone_D"][fieldValidation({ ...taskErrorCode["lastDone_D_obj"], fieldval: data.lastDone_D, })] : '',
			lastDone_FH:data.lastDone_FH ? "" : data.interval_FH ? taskErrorCode["lastDone_FH"][fieldValidation({ ...taskErrorCode["lastDone_FH_obj"], fieldval: data.lastDone_FH, })] : '',
			lastDone_FC: data.lastDone_FC ? "" : data.interval_FC ? taskErrorCode["lastDone_FC"][fieldValidation({ ...taskErrorCode["lastDone_FC_obj"], fieldval: data.lastDone_FC, })] : '',
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				threshold_D: data.threshold_D ? data.threshold_D : null,
				threshold_FH: data.threshold_FH ? data.threshold_FH : null,
				threshold_FC: data.threshold_FC ? data.threshold_FC : null,
				interval_FH: data.interval_FH ? data.interval_FH : null,
				interval_FC: data.interval_FC ? data.interval_FC : null,
				interval_D: data.interval_D ? data.interval_D : null,
				lastDone_D: data.lastDone_D === "Invalid date" ? null : data.lastDone_D,
				lastDone_FC: data.lastDone_FC ? data.lastDone_FC : null,
				lastDone_FH: data.lastDone_FH ? data.lastDone_FH : null,
				nextDue_D: data.nextDue_D ? data.nextDue_D : null,
				nextDue_FH: data.nextDue_FH ? data.nextDue_FH : null,
				nextDue_FC: data.nextDue_FC ? data.nextDue_FC : null,
				remainingValues_D: data.remainingValues_D ? data.remainingValues_D : null,
			}
			const updateId = this.props.editFormId
			this.setState({
				data: this.props.editFormData,
			})
			const props = this.props.props
			if (updateId) {
				await this.uploadFileTest(updateId, props, true)
			}
			await this.props.updateFormData(updateId, payload, props)
			this.props.closeAddForm();
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'AMP Compliance',
				item_id: updateId ? updateId : '',
				request_body: payload ? payload : ''
			  })
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'AMP Compliance',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 1
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append( "[" + key + "]file", this.state.newUploadedAttachments[key] )
				formdata.append( "[" + key + "]file_type", this.state.newUploadedAttachments[key].type )
				formdata.append( "[" + key + "]file_name", this.state.newUploadedAttachments[key].name )
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}

	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 1,
				file_name: file.name,
				file_type: file.type,
				file: "upload file",
				name: file.name,
				asset_id: this.props.props.match.params.asset,
				asset_type_id: this.props.props.match.params.assetType
			}
			existingAttachments.push(objc)
		})
		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}
	deleteAttachmentFile = async (id,attachments) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		}else{
			this.onRemoveCamoFile(attachments)
		}
	}
	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]
		var filteredArray = []
		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}

	scrollToBottom = (event) => {
		document
			.querySelector(".MuiDialog-paper")
			.scrollTo(0, document.documentElement.scrollHeight)
	}
	nextDueValue = () => {
		const { data } = this.state
		var date = new Date(data.lastDone_D)
		date.setDate(date.getDate() + parseInt(data.interval_D))
		var dom = new Date(this.props.date_of_manufacture)
		dom.setDate(dom.getDate() + parseInt(data.threshold_D))
		if (data.lastDone_D && data.interval_D) {
			if (data.lastDone_D?.length > 0) {
				data.nextDue_D = data.lastDone_D === "Invalid date" ? data.threshold_D != '' && moment(dom).format(backendDateFormat) : moment(date).format(backendDateFormat)
			} else {
				data.nextDue_D = null || ''
			}
		} else {
			if (data.threshold_D != '') {
				data.nextDue_D = moment(dom).format(backendDateFormat)
			} else {
				data.nextDue_D = null
			}
		}
		return data.nextDue_D

	}
	nextDueFHValue = () => {
		const { data } = this.state
		if (data.lastDone_FH > 0 && data.interval_FH > 0) {
			if (data.lastDone_FH === '') {
				data.nextDue_FH = null
			} else {
				data.nextDue_FH = (+data.lastDone_FH + +data.interval_FH).toFixed(0)
			}
		} else {
			if (data.threshold_FH?.length > 0) {
				data.nextDue_FH = (+data.threshold_FH + +this.props.headerTsn).toFixed(0)
			} else {
				data.nextDue_FH = ''
			}
		}
		return data.nextDue_FH
	}
	nextDueFCValue = () => {
		const { data } = this.state
		if (data.lastDone_FC > 0 && data.interval_FC > 0) {
			if (data.lastDone_FC === '' || null) {
				data.nextDue_FC = null
			} else {
				data.nextDue_FC = (+data.lastDone_FC + +data.interval_FC).toFixed(0)
			}
		} else {
			if (data.threshold_FC?.length > 0) {
				data.nextDue_FC = (+data.threshold_FC + +this.props.headerCsn).toFixed(0)
			} else {
				data.nextDue_FC = ''
			}
		}
		return data.nextDue_FC
	}
	remainingDaysValues = () => {
		const { data } = this.state
		const calDate = new Date()
		const todayD = moment(calDate).format(backendDateFormat)
		const lDate = moment(data.nextDue_D)
		if (data.nextDue_D) {
			data.remainingValues_D = lDate.diff(todayD, "days") ? lDate.diff(todayD, "days") : null
		}
		return data.remainingValues_D
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {
			delete: true,
			file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
		};
		globalPutService(`camo/amp/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'AMP Compliance',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				  })
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}
	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});

	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}

	}

	onLinkFile = (file, uuid, type) => {
		const { data } = this.state;
		if (type == "link" || uuid) {
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {
				file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
			};
			globalPutService(`camo/amp/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
						// this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/amp/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
						// this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}

	render() {
		const { data, error, attachments} = this.state
		var date = new Date(data.lastDone_D)
		date.setDate(date.getDate() + parseInt(data.interval_D))
		var calDate = new Date()
		const lDate = moment(data.lastDone_D)
		const todayD = moment(calDate)
		let diffDay = lDate.diff(todayD, "days")
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<DialogTitle className="just-center">
						{this.props.formTitle}
						<CloseIcon onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} className="close-icon" />
					</DialogTitle>
					<form id="asset-form" onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)} >
						<DialogContent>
							<Grid spacing={1} container>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<React.Fragment> MPD Item Number <span style={{ color: "red" }}> *</span> </React.Fragment>}
										name="MPD_item_no"
										id="MPD_item_no"
										value={data.MPD_item_no}
										error={error.MPD_item_no ? true : false}
										helperText={error.MPD_item_no ? error.MPD_item_no : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 20) {
												this.onFieldChange("MPD_item_no", input)
												this.onRestErrorKey("MPD_item_no")
											} else {
												const truncatedInput = input.slice(0, 20)
												this.onFieldChange("MPD_item_no", truncatedInput)
												this.onRestErrorKey("MPD_item_no")
											}
										}}
										onFocus={() => this.onRestErrorKey("MPD_item_no")}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<React.Fragment> Task Card Number<span style={{ color: "red" }}> *</span> </React.Fragment>}
										name="task_card_no"
										id="task_card_no"
										value={data.task_card_no}
										error={error.task_card_no ? true : false}
										helperText={error.task_card_no ? error.task_card_no : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 20) {
												this.onFieldChange("task_card_no", input)
												this.onRestErrorKey("task_card_no")
											} else {
												const truncatedInput = input.slice(0, 20)
												this.onFieldChange("task_card_no", truncatedInput)
												this.onRestErrorKey("task_card_no")
											}
										}}
										onFocus={() => this.onRestErrorKey("task_card_no")}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="Previous LDND Task Number"
										name="previous_LDND_Taskcard"
										id="previous_LDND_Taskcard"
										value={data.previous_LDND_Taskcard ? data.previous_LDND_Taskcard : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 20) {
												this.onFieldChange("previous_LDND_Taskcard", input)
												this.onRestErrorKey("previous_LDND_Taskcard")
											} else {
												const truncatedInput = input.slice(0, 20)
												this.onFieldChange(
													"previous_LDND_Taskcard",
													truncatedInput
												)
												this.onRestErrorKey("previous_LDND_Taskcard")
											}
										}}
									/>
								</Grid>
							</Grid>
							<Grid spacing={1} container>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										label={<React.Fragment> Task Description<span style={{ color: "red" }}> *</span> </React.Fragment>}
										name="Task_Description"
										id="Task_Description"
										multiline
										minRows={5}
										error={error.Task_Description ? true : false}
										helperText={error.Task_Description ? error.Task_Description : ""}
										value={data.Task_Description ? data.Task_Description : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 255) {
												this.onFieldChange("Task_Description", input)
												this.onRestErrorKey("Task_Description")
											} else {
												const truncatedInput = input.slice(0, 255)
												this.onFieldChange("Task_Description", truncatedInput)
												this.onRestErrorKey("Task_Description")
											}
										}}
										onFocus={() => this.onRestErrorKey("Task_Description")}
									/>
								</Grid>
							</Grid>
							<div className="border-grey"></div>
							<Grid id="data-container" spacing={1} container>
								<Grid item xs={4}>
									<label className="ml-10px font-14">THRESHOLD</label>
									<Grid spacing={1} container>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> Days {data.threshold_FH == null && data.threshold_FC == null ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="threshold_D"
												id="threshold_D"
												error={error.threshold_D ? true : false}
												helperText={error.threshold_D ? error.threshold_D : ""}
												value={data.threshold_D || data.threshold_D === 0 ? data.threshold_D : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 5 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("threshold_D", input)
														this.onRestErrorKey("threshold_D")
													} else if (
														input.length > 5 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 5)
														this.onFieldChange("threshold_D", truncatedInput)
														this.onRestErrorKey("threshold_D")
													} else {
														e.preventDefault()
													}
												}}
												onFocus={() => this.onRestErrorKey("threshold_D")}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FH"
												name="threshold_FH"
												id="threshold_FH"
												error={error.threshold_FH ? true : false}
												helperText={error.threshold_FH ? error.threshold_FH : ""}
												value={data.threshold_FH || data.threshold_FH === 0 ? data.threshold_FH : ""}
												onFocus={() => this.onRestErrorKey("threshold_FH")}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("threshold_FH", input)
														this.onRestErrorKey("threshold_FH")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("threshold_FH", truncatedInput)
														this.onRestErrorKey("threshold_FH")
													} else {
														e.preventDefault()
													}
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FC" 
												name="threshold_FC"
												id="threshold_FC"
												error={error.threshold_FC ? true : false}
												helperText={error.threshold_FC ? error.threshold_FC : ""}
												value={data.threshold_FC || data.threshold_FC === 0 ? data.threshold_FC : ""}
												onFocus={() => this.onRestErrorKey("threshold_FC")}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("threshold_FC", input)
														this.onRestErrorKey("threshold_FC")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("threshold_FC", truncatedInput)
														this.onRestErrorKey("threshold_FC")
													} else {
														e.preventDefault()
													}
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={4}>
									<label className="ml-10px font-14">INTERVAL</label>
									<Grid spacing={1} container>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> Days {data.threshold_D !== undefined && data.threshold_D !== null && data.threshold_D !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="interval_D"
												id="interval_D"
												error={error.interval_D ? true : false}
												helperText={error.interval_D ? error.interval_D : ""}
												value={data.interval_D || data.interval_D === 0 ? data.interval_D : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 5 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("interval_D", input)
														this.onRestErrorKey("interval_D")
													} else if (
														input.length > 5 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 5)
														this.onFieldChange("interval_D", truncatedInput)
														this.onRestErrorKey("interval_D")
													} else {
														e.preventDefault()
													}
												}}
												onFocus={() => this.onRestErrorKey("interval_D")}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> FH {data.threshold_FH !== undefined && data.threshold_FH !== null && data.threshold_FH !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="interval_FH"
												id="interval_FH"
												error={error.interval_FH ? true : false}
												helperText={error.interval_FH ? error.interval_FH : ""}
												value={data.interval_FH || data.interval_FH === 0 ? data.interval_FH : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("interval_FH", input)
														this.onRestErrorKey("interval_FH")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("interval_FH", truncatedInput)
														this.onRestErrorKey("interval_FH")
													} else {
														e.preventDefault()
													}
												}}
												onFocus={() => this.onRestErrorKey("interval_FH")}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> FC {data.threshold_FC !== undefined && data.threshold_FC !== null && data.threshold_FC !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="interval_FC"
												id="interval_FC"
												error={error.interval_FC ? true : false}
												helperText={error.interval_FC ? error.interval_FC : ""}
												value={data.interval_FC || data.interval_FC === 0 ? data.interval_FC : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("interval_FC", input)
														this.onRestErrorKey("interval_FC")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("interval_FC", truncatedInput)
														this.onRestErrorKey("interval_FC")
													} else {
														e.preventDefault()
													}
												}}
												onFocus={() => this.onRestErrorKey("interval_FC")}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={4}>
									<label className="ml-10px font-14">LAST DONE</label>
									<Grid spacing={1} container>
										<Grid item xs={4} className="input-calender">
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													id="lastDone_D"
													label={<React.Fragment> Date {data.threshold_D !== undefined && data.threshold_D !== null && data.threshold_D !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
													format={fieldDateFormat}
													inputVariant="outlined"
													fullWidth
													disableFuture
													InputLabelProps={{ shrink: true }}
													minDate={moment().subtract(25, "years")}
													value={data.lastDone_D || ''}
													error={error.lastDone_D ? true : false}
													helperText={error.lastDone_D ? error.lastDone_D : ""}
													onChange={(data, value) => {
														this.onFieldChange(
															"lastDone_D",
															moment(data).format(backendDateFormat)
														)
														this.onRestErrorKey("lastDone_D")
													}}

												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> FH {data.threshold_FH !== undefined && data.threshold_FH !== null && data.threshold_FH !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="lastDone_FH"
												id="lastDone_FH"
												InputLabelProps={{ shrink: true }}
												error={error.lastDone_FH ? true : false}
											    helperText={error.lastDone_FH ? error.lastDone_FH : ""}
												value={data.lastDone_FH ? data.lastDone_FH : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("lastDone_FH", input)
														this.onRestErrorKey("lastDone_FH")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("lastDone_FH", truncatedInput)
														this.onRestErrorKey("lastDone_FH")
													} else {
														e.preventDefault()
													}
												}}
												onFocus={() => this.onRestErrorKey("lastDone_FH")}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> FC {data.threshold_FC !== undefined && data.threshold_FC !== null && data.threshold_FC !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												name="lastDone_FC"
												id="lastDone_FC"
												InputLabelProps={{ shrink: true }}
												error={error.lastDone_FC ? true : false}
											    helperText={error.lastDone_FC ? error.lastDone_FC : ""}
												value={data.lastDone_FC ? data.lastDone_FC : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (
														input.length <= 10 &&
														regexConstants.onlyNumeric.test(e.target.value)
													) {
														this.onFieldChange("lastDone_FC", input)
														this.onRestErrorKey("lastDone_FC")
													} else if (
														input.length > 10 &&
														regexConstants.onlyNumeric.test(input)
													) {
														const truncatedInput = input.slice(0, 10)
														this.onFieldChange("lastDone_FC", truncatedInput)
														this.onRestErrorKey("lastDone_FC")
													} else {
														e.preventDefault()
													}
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid id="data-container" spacing={1} container>
								<Grid item xs={6}>
									<label className="ml-10px font-14">NEXT DUE</label>
									<Grid spacing={1} container>
										<Grid item xs={4} className="input-calender" id="disabled">
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													id="date_of_manufacture"
													format={fieldDateFormat}
													inputVariant="outlined"
													label="Date"
													fullWidth
													disableFuture
													disabled
													InputLabelProps={{ shrink: true }}
													minDate={moment().subtract(25, "years")}
													value={this.nextDueValue()}
													error={error.nextDue_D ? true : false}
													helperText={error.nextDue_D ? error.nextDue_D : ""}
													onFocus={() => this.onRestErrorKey("nextDue_D")}
													onChange={(data, value) => this.onFieldChange("nextDue_D", moment(data).format(backendDateFormat))}
													className="disabled-input"
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FH"
												name="nextDue_FH"
												id="nextDue_FH"
												value={this.nextDueFHValue()}
												inputProps={{ readOnly: true }}
												InputLabelProps={{ shrink: true }}
												disabled
												className="disabled-input"
												onChange={(e, value) => this.onFieldChange("nextDue_FH", e.target.value)}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FC"
												name="nextDue_FC"
												id="nextDue_FC"
												value={this.nextDueFCValue()}
												inputProps={{ readOnly: true }}
												InputLabelProps={{ shrink: true }}
												disabled
												className="disabled-input"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<label className="ml-10px font-14">REMAINING</label>
									<Grid spacing={1} container>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="Days"
												name="remainingValues_D"
												id="remainingValues_D"
												// value={data.remainingValues_D}
												value={this.remainingDaysValues()}
												InputLabelProps={{ shrink: true }}
												inputProps={{ readOnly: true }}
												disabled
												className="disabled-input"
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FH"
												name="remainingValues_FH"
												id="remainingValues_FH"
												InputLabelProps={{ shrink: true }}
												value={
													this.props.headerTsn &&
														data.interval_FH &&
														data.interval_FH > 0 &&
														data.lastDone_FH &&
														parseInt(data.lastDone_FH) +
														parseInt(data.interval_FH) >
														0
														? (data.remainingValues_FH =
															data.nextDue_FH !== 0
																? Number.isInteger(
																	data.nextDue_FH - this.props.headerTsn
																)
																	? data.nextDue_FH - this.props.headerTsn
																	: (
																		data.nextDue_FH - this.props.headerTsn
																	).toFixed(2)
																: "")
														: ""
												}
												inputProps={{ readOnly: true }}
												disabled
												className="disabled-input"
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label="FC"
												name="remainingValues_FC"
												id="remainingValues_FC"
												InputLabelProps={{ shrink: true }}
												value={
													this.props.headerCsn &&
														data.interval_FC &&
														data.interval_FC > 0 &&
														data.lastDone_FC &&
														parseInt(data.lastDone_FC) +
														parseInt(data.interval_FC) >
														0
														? (data.remainingValues_FC = (
															data.nextDue_FC - this.props.headerCsn
														).toFixed(
															Number.isInteger(
																data.nextDue_FC - this.props.headerCsn
															)
																? 0
																: 2
														))
														: ""
												}
												inputProps={{ readOnly: true }}
												disabled
												className="disabled-input"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<div className="border-grey"></div>
							<Grid spacing={1} container>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										label={<React.Fragment>Remarks</React.Fragment>}
										name="remarks"
										id="remarks"
										multiline
										minRows={5}
										error={error.remarks ? true : false}
										helperText={error.remarks ? error.remarks : ""}
										value={data.remarks ? data.remarks : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 255) {
												this.onFieldChange("remarks", input)
												this.onRestErrorKey("remarks")
											} else {
												const truncatedInput = input.slice(0, 255)
												this.onFieldChange("remarks", truncatedInput)
												this.onRestErrorKey("remarks")
											}
										}}
										onFocus={() => this.onRestErrorKey("remarks")}
									/>
								</Grid>
							</Grid>

							<Grid spacing={1} container>
								<Grid item xs={6}  >
									<TextareaAutosize
										aria-label="minimum height"
										placeholder="Workorder Reference"
										name="workOrder"
										id="workOrder"
										style={{ height: "70px", width: "560px", padding: '3px' }}
										error={error.workOrder ? true : false}
										helperText={error.workOrder ? error.workOrder : ""}
										value={data.workOrder ? data.workOrder : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (input.length <= 255) {
												this.onFieldChange("workOrder", input)
												this.onRestErrorKey("workOrder")
											} else {
												const truncatedInput = input.slice(0, 255)
												this.onFieldChange("workOrder", truncatedInput)
												this.onRestErrorKey("workOrder")
											}
										}}
										onFocus={() => this.onRestErrorKey("workOrder")}
									/>
								</Grid>
								<Grid item xs={6}>
									<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
										<ul className="list-inline spacing-list">
											<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
												<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
											</li>
											<li className="list-inline-item" onClick={this.handleOpenFile}>
												<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
											</li>
										</ul>
									</div>
								</Grid>
							</Grid>
							<Grid id="data-container" spacing={1} container>
								<Grid spacing={1} container>
									{this.props.actionType === 'add' ?
										<div className='checklist-files'>
											<ul className='list-inline'>
												{data?.checklist?.map((file) =>
													<li className='list-inline-item'>
														<span className='file-name'>{file.name}</span>
														<span className='file-remove'>
															<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
															<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
														</span>
													</li>
												)}
												{attachments.length > 0 && attachments?.map((attachments) =>
													<li className='list-inline-item'>
														<span className='file-name'>{attachments.file_name}</span>
														<span className='file-remove'>
															<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
															<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
														</span>
													</li>
												)}
											</ul>
										</div>
										:
										<div className='checklist-files'>
											<ul className='list-inline'>
												{data?.checklist?.files?.map((file) =>
													<li className='list-inline-item'>
														<span className='file-name'>{file.name}</span>
														<span className='file-remove'>
															<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
															<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
														</span>
													</li>
												)}
												{attachments?.map((attachments) =>
													<li className='list-inline-item'>
														<span className='file-name'>{attachments.file_name}</span>
														<span className='file-remove'>
															<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
															<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id,attachments)} color='secondary' fontSize='small' />
														</span>
													</li>
												)}
											</ul>
										</div>}
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions className="actions-btn">
							<Button type="button" className="btn btn-primary" onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)} >
								{this.props.actionType === "add" ? "Add" : "Update"}
							</Button>
							{this.props.actionType === "add" ? (
								<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
							) : (
								""
							)}
							<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
						</DialogActions>
						<Dialog open={this.state.open} onClose={this.handleCloseAttachment} id="htDialog" >
							<AmpConfirmDeleteAttachment
								handleCloseAttachment={() => this.handleCloseAttachment()}
								deleteAttachmentFile={() => this.deleteAttachmentFile(this.state.deleteAttachmentId)}
							/>
						</Dialog>
						<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
							<LinkFiles
								handleClose={() => this.handleCloseFile()}
								uuid={this.props?.uuid?.uuid}
								editFormId={this.props.editFormId}
								getResponseBack={(file) => this.getResponseBack(file)}
								listResponseBack={(file) => this.listResponseBack(file)}
								actionType={this.props.actionType}
								onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
								modType="amp"
								last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
								uploadFileInfo={data}
								attachments={attachments}
								type="link"
							/>
						</Dialog>
						<Dialog open={this.state.uploadFile} onClose={this.handleUploadCloseFile} id="htDialog">
							<UploadFile
								handleClose={() => this.handleUploadCloseFile()}
								uuid={this.props?.uuid?.uuid}
								editFormId={this.props.editFormId}
								getResponseBack={(file) => this.getResponseBack(file)}
								listResponseBack={(file) => this.listResponseBack(file)}
								actionType={this.props.actionType}
								onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
								modType="amp"
								uploadFileInfo={data}
								last_used_folder_uuid={this.props.last_used_folder_uuid}
								onUpload={(file) => this.onUpload(file)}
								type="upload"
								checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
								checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
							/>
						</Dialog>
					</form>
				</div>
			</Fragment>
		)
	}
}
export default AmpTaskForm
