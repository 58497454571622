
export const inventoryTableHead = [
  { id: "actions", label: "Action",},
  { id: "section_id", label: "Section",},
  { id: "description", label: "Description", width: 200, },
  { id: "iin", label: "IIN", sortOption: false },
  { id: "spec", label: "SPEC", sortOption: false },
  { id: "ipc_ppbu_fig", label: "IPC & PPBU FIG. / REF", sortOption: false, },
  { id: "quantity", label: "Quantity"},
  { id: "part_number", label: "Part Number",},
  { id: "serial_number", label: "Serial Number",},
  { id: "installed", label: "Installed", sortOption: false },
  { id: "tsn", label: "TSN", sortOption: false },
  { id: "csn", label: "CSN", sortOption: false },
  { id: "tso", label: "TSO", sortOption: false },
  { id: "cso", label: "CSO", sortOption: false },
  { id: "condition", label: "Condition", sortOption: false },
  { id: "attachments", label: "Attachments" },
]

