import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import config from "../../../config"
import { fieldDateFormat } from "../../../constants";
import { getLocalStorageInfo } from "../../../utils"
import RevisionHistory from '../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";

const FleetAdListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {

	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)
	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);

	const fleet_AdUrl = `audit/camo/fleetadstatus/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
	return (
		<>
			<TableRow >
				<TableCell >
					<Tooltip title="Edit" placement='top'>
						<IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} >
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete" placement='top' >
						<IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}>
							<DeleteOutlinedIcon />
						</IconButton>
					</Tooltip>
					<RevisionHistory buttonType={true} url={fleet_AdUrl} />
				</TableCell>
				<TableCell>{smbcInstance ? item.faa_ad : item?.ata || "--"}</TableCell>
				<TableCell>{smbcInstance ? item.faa_superseded : item.ad_no || "--"} </TableCell>
				{smbcInstance ? <TableCell>{item.easa_ad}</TableCell> : null}
				<TableCell>{smbcInstance ? item.easa_superseded : item.issuing_authority || "--"} </TableCell>
				<TableCell style={{ width: "200px", textAlign: "left" }}> {item.description} </TableCell>
				{smbcInstance ? <TableCell>{item?.related_document || "--"}</TableCell> : null}
				<TableCell>{item?.applicability?.map((item) => item?.name ? item?.name + ',' : '') || '--'}</TableCell>
				<TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>
				{smbcInstance ? <TableCell>{item?.faa_effective_date || "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{item?.easa_effective_date || "--"}</TableCell> : null}
				{smbcInstance ? null : <TableCell>{item.ad_type ? item.ad_type : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell>{item.effective_date ? moment(item.effective_date).format(fieldDateFormat) : "--"}  </TableCell>}
				{smbcInstance ? null : <TableCell>{item.dimension_type ? item.dimension_type : "--"}  </TableCell>}
				<TableCell>{item.ad_compliance_status ? item.ad_compliance_status : "--"} </TableCell>
				{smbcInstance ? null : <TableCell>{item.ad_compliance_tsn ? item.ad_compliance_tsn : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_csn ? item.ad_compliance_csn : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_date ? moment(item.ad_compliance_date).format(fieldDateFormat) : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell>{item.sb_number ? item.sb_number : "--"}</TableCell>}
				{smbcInstance ? <TableCell>{item?.reference || "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{item.remark ? item.remark : "--"}</TableCell> : null}
				<TableCell>
					{item.attachments?.length > 0 ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" >
									<AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
									<a href="#" style={{ color: "#0e7fe1" }}>{item.attachments?.length > 0 && item.attachments?.length}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
		</>
	)
}

export default withRouter(FleetAdListing)