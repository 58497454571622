import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableContainer, TableHead, TableCell, TableBody, Table, TableRow, TextField, TextareaAutosize } from "@material-ui/core"
import React, { Component, Fragment } from "react"
import CloseIcon from "@material-ui/icons/Close"
import Autocomplete from "@material-ui/lab/Autocomplete"
import axios from 'axios';
import { formValues, taskErrorCode } from "../containers/LLpTaskFormData"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { addLLpTaskFormApi, deleteAttachment, } from "../apiService";
import { fieldValidation } from "../../../../utils/formValidation";
import LLpConfirmDeleteAttachment from "./LLpConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import { globalPutService } from "../../../../utils/globalApiServices";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import { trackActivity } from '../../../../utils/mixpanel';
import { checkApiStatus } from "../../../../utils";

class LLpTaskForms extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			numberError: false,
			attachments: [],
			newUploadedAttachments: [],
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			showSuccessUpload: false,
		}

		this.addLLpTaskFormApi = addLLpTaskFormApi.bind(this)
	}

	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'LLP Add Task Form',
		})
	}
	onFieldChange = (keyParam, value, index) => {
		this.setState((prevState) => {
			const newData = { ...prevState.data };
			if (keyParam === "cootr_thrust") {
				const thrustKey = index >= 0 ? `COOTR_thrust_${String.fromCharCode(97 + index)}` : keyParam;
				newData[thrustKey] = value;
			} else if (keyParam === "life_limit") {
				const thrustKey = index >= 0 ? `life_limit_thrust_${String.fromCharCode(97 + index)}` : keyParam;
				newData[thrustKey] = value
			} else {
				newData[keyParam] = value;
			}

			return { data: newData };
		});
	};

	onRestErrorKey = (keyParam, index) => {
		this.setState((prevState) => {
			const { error } = prevState;
			if (keyParam === "life_limit") {
				const updatedError = {};
				for (let i = 0; i < 5; i++) {
					const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + i)}`;
					updatedError[thrustKey] = "";
				}
				return { error: { ...error, ...updatedError } };
			}

			return { error: { ...error, [keyParam]: "" } };
		});
	};

	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		validationInputs = {
			...validationInputs,
			module_type: taskErrorCode["module_type"][fieldValidation({ ...taskErrorCode["module_type_obj"], fieldval: data.module_type, })],
			description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
			part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
			serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
			tsn: taskErrorCode["tsn"][fieldValidation({ ...taskErrorCode["tsn_obj"], fieldval: data.tsn, })],
			life_limit_thrust_a: data?.life_limit_thrust_a ? '' : taskErrorCode["life_limit_thrust_a"][fieldValidation({ ...taskErrorCode["life_limit_thrust_a_obj"], fieldval: data.life_limit_thrust_a, })],
			life_limit_thrust_b: data?.life_limit_thrust_b ? '' : taskErrorCode["life_limit_thrust_b"][fieldValidation({ ...taskErrorCode["life_limit_thrust_b_obj"], fieldval: data.life_limit_thrust_b, })],
			life_limit_thrust_c: data?.life_limit_thrust_c ? '' : taskErrorCode["life_limit_thrust_c"][fieldValidation({ ...taskErrorCode["life_limit_thrust_c_obj"], fieldval: data.life_limit_thrust_c, })],
			life_limit_thrust_d: data?.life_limit_thrust_d ? '' : taskErrorCode["life_limit_thrust_d"][fieldValidation({ ...taskErrorCode["life_limit_thrust_d_obj"], fieldval: data.life_limit_thrust_d, })],
			life_limit_thrust_e: data?.life_limit_thrust_e ? '' : taskErrorCode["life_limit_thrust_e"][fieldValidation({ ...taskErrorCode["life_limit_thrust_e_obj"], fieldval: data.life_limit_thrust_e, })],
		}
		if (Object.keys(validationInputs).every((k) => {
			return validationInputs[k] === ""
		})
		) {
			const props = this.props.props
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				asset_id: props.match.params.asset,
				asset_type_id: props.match.params.assetType,
				part_number: payload.part_number || null,
				serial_number: payload.serial_number || null,
				tsn: parseInt(payload.tsn) || null,
				csn: parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || null,
				COOTR_thrust_a: parseInt(data.COOTR_thrust_a) || null,
				COOTR_thrust_b: parseInt(data.COOTR_thrust_b) || null,
				COOTR_thrust_c: parseInt(data.COOTR_thrust_c) || null,
				COOTR_thrust_d: parseInt(data.COOTR_thrust_d) || null,
				COOTR_thrust_e: parseInt(data.COOTR_thrust_e) || null,

				life_limit_thrust_a: parseInt(data.life_limit_thrust_a) || null,
				life_limit_thrust_b: parseInt(data.life_limit_thrust_b) || null,
				life_limit_thrust_c: parseInt(data.life_limit_thrust_c) || null,
				life_limit_thrust_d: parseInt(data.life_limit_thrust_d) || null,
				life_limit_thrust_e: parseInt(data.life_limit_thrust_e) || null,

			}
			addLLpTaskFormApi(payload, props).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'LLP',
						item_id: uploadId ? uploadId : '',
						request_body: payload ? payload : '',
						response_msg: response.data.message ? response.data.message : ''
					})
				}
				this.props.getResponseBack()
			})
			if (this.state.btnStatus !== "addAnother") {
				this.props.closeAddForm()
			} else {
				return false
			}

		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'LLP',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		validationInputs = {
			...validationInputs,
			module_type: taskErrorCode["module_type"][fieldValidation({ ...taskErrorCode["module_type_obj"], fieldval: data.module_type, })],
			description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
			part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
			serial_number: taskErrorCode["serial_number"][fieldValidation({ ...taskErrorCode["serial_number_obj"], fieldval: data.serial_number, })],
			tsn: taskErrorCode["tsn"][fieldValidation({ ...taskErrorCode["tsn_obj"], fieldval: data.tsn, })],
			life_limit_thrust_a: data?.life_limit_thrust_a ? '' : taskErrorCode["life_limit_thrust_a"][fieldValidation({ ...taskErrorCode["life_limit_thrust_a_obj"], fieldval: data.life_limit_thrust_a, })],
			life_limit_thrust_b: data?.life_limit_thrust_b ? '' : taskErrorCode["life_limit_thrust_b"][fieldValidation({ ...taskErrorCode["life_limit_thrust_b_obj"], fieldval: data.life_limit_thrust_b, })],
			life_limit_thrust_c: data?.life_limit_thrust_c ? '' : taskErrorCode["life_limit_thrust_c"][fieldValidation({ ...taskErrorCode["life_limit_thrust_c_obj"], fieldval: data.life_limit_thrust_c, })],
			life_limit_thrust_d: data?.life_limit_thrust_d ? '' : taskErrorCode["life_limit_thrust_d"][fieldValidation({ ...taskErrorCode["life_limit_thrust_d_obj"], fieldval: data.life_limit_thrust_d, })],
			life_limit_thrust_e: data?.life_limit_thrust_e ? '' : taskErrorCode["life_limit_thrust_e"][fieldValidation({ ...taskErrorCode["life_limit_thrust_e_obj"], fieldval: data.life_limit_thrust_e, })],
			
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				part_number: payload.part_number || null,
				serial_number: payload.serial_number || null,
				tsn: parseInt(payload.tsn) || null,
				csn: parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || null,
				COOTR_thrust_a: parseInt(data.COOTR_thrust_a) || null,
				COOTR_thrust_b: parseInt(data.COOTR_thrust_b) || null,
				COOTR_thrust_c: parseInt(data.COOTR_thrust_c) || null,
				COOTR_thrust_d: parseInt(data.COOTR_thrust_d) || null,
				COOTR_thrust_e: parseInt(data.COOTR_thrust_e) || null,
				life_limit_thrust_a: parseInt(data.life_limit_thrust_a) || null,
				life_limit_thrust_b: parseInt(data.life_limit_thrust_b) || null,
				life_limit_thrust_c: parseInt(data.life_limit_thrust_c) || null,
				life_limit_thrust_d: parseInt(data.life_limit_thrust_d) || null,
				life_limit_thrust_e: parseInt(data.life_limit_thrust_e) || null,
			}
			const updateId = this.props.editFormId
			const props = this.props.props
			if (updateId) {
				this.uploadFileTest(updateId, props, true)
			}
			this.props.updateFormData(updateId, payload, props)
			this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'LLP',
				item_id: updateId ? updateId : '',
				request_body: payload ? payload : ''
			})
			this.setState({ error: validationInputs })
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'LLP',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 8
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append(
					"[" + key + "]file",
					this.state.newUploadedAttachments[key]
				)
				formdata.append(
					"[" + key + "]file_type",
					this.state.newUploadedAttachments[key].type
				)
				formdata.append(
					"[" + key + "]file_name",
					this.state.newUploadedAttachments[key].name
				)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				// document.querySelector(".MuiDialog-paper").scrollTop = 800;
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}


	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 8,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
			}
			existingAttachments.push(objc)
		})
		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		}
	}


	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {
			delete: true,
			file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
		};
		globalPutService(`camo/llp/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'LLP',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				  })
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		this.props.getResponseBack(file)
	}

	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {
				file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
			};
			globalPutService(`camo/llp/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/llp/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}
	lifeLimithelperText = (index) => {
		const { error } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return error[thrustKey] || "";
	}

	lifeLimitError = (index) => {
		const { error } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return !!error[thrustKey];
	}

	operatedThrustValue = (index) => {
		const { data } = this.state;
		const thrustKey = `COOTR_thrust_${String.fromCharCode(97 + index)}`;
		return data[thrustKey] || '';
	}

	lifeLimitValue = (index) => {
		const { data } = this.state;
		const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
		return data[thrustKey] || '';
	}

	remainingCycle = (index) => {
		const { data } = this.state
		if (index === 0) {
			return data.remaining_cycle_thrust?.remaining_cycle_thrust_a ? data.remaining_cycle_thrust?.remaining_cycle_thrust_a : data.remaining_cycle_thrust_a = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_a)) || "" : parseInt(data.life_limit_thrust_a) || '';

		} else if (index === 1) {
			return data.remaining_cycle_thrust?.remaining_cycle_thrust_b ? data.remaining_cycle_thrust?.remaining_cycle_thrust_b : data.remaining_cycle_thrust_b = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_b)) || "" : parseInt(data.life_limit_thrust_b) || '';

		} else if (index === 2) {
			return data.remaining_cycle_thrust?.remaining_cycle_thrust_c ? data.remaining_cycle_thrust?.remaining_cycle_thrust_c : data.remaining_cycle_thrust_c = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_c)) || "" : parseInt(data.life_limit_thrust_c) || '';
		} else if (index === 3) {
			return data.remaining_cycle_thrust?.remaining_cycle_thrust_d ? data.remaining_cycle_thrust?.remaining_cycle_thrust_d : data.remaining_cycle_thrust_d = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_d)) || "" : parseInt(data.life_limit_thrust_d) || '';
		} else if (index === 4) {
			return data.remaining_cycle_thrust?.remaining_cycle_thrust_e ? data.remaining_cycle_thrust?.remaining_cycle_thrust_e : data.remaining_cycle_thrust_e = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
				Math.ceil((1 - (
					(parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
					(parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
					(parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
					(parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
					(parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
				)) * parseInt(data.life_limit_thrust_e)) || "" : parseInt(data.life_limit_thrust_e) || '';
		}

	}

	render() {
		const { data, error, attachments, } = this.state
		const thrust = ["Thrust A", "Thrust B", "Thrust C", "Thrust D", "Thrust E"]
		const modulType = ["FAN","HPC","HPT","LPT","Static","Blades"]
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="adTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label="Part Number"
											name="part_number"
											id="part_number"
											InputLabelProps={{ shrink: true }}
											value={data.part_number ? data.part_number : ''}
											error={error.part_number ? true : false}
											helperText={error.part_number ? error.part_number : ""}
											onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('part_number', e.target.value); this.onRestErrorKey("part_number") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label="Serial Number"
											name="ad_no"
											id="serial_number"
											InputLabelProps={{ shrink: true }}
											value={data.serial_number}
											error={error.serial_number ? true : false}
											helperText={error.serial_number ? error.serial_number : ""}
											onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('serial_number', e.target.value); this.onRestErrorKey("serial_number") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required
											variant="outlined"
											label="TSN"
											name="tsn"
											id="tsn"
											error={error.tsn ? true : false}
											helperText={error.tsn ? error.tsn : ""}
											InputLabelProps={{ shrink: true }}
											value={data.tsn}
											onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('tsn', e.target.value); this.onRestErrorKey("tsn") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="CSN"
											disabled
											InputLabelProps={{ shrink: true }}
											value={parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || data.csn || ''}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<Autocomplete
											options={modulType || []}
											getOptionLabel={(option) => option}
											id="module_type"
											value={data?.module_type ? data?.module_type : null}	
											onChange={(e, value) => {this.onFieldChange("module_type", value);this.onRestErrorKey("module_type")}}
											renderInput={(params) => (
												<TextField
													{...params}
													required
													label="Module"
													margin="normal"
													fullWidth
													error={error.module_type ? true : false}
											        helperText={error.module_type ? error.module_type : ""}
													InputLabelProps={{ shrink: true }}
													placeholder="Select Module"
													variant="outlined"
													
												/>
											)}
										/>
									</Grid>
									<Grid item xs={9}>
										<TextField
										    required
											multiline
											minRows={5}
											variant="outlined"
											label="Description"
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
											InputLabelProps={{ shrink: true }}
											value={data.description}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 255) {
													this.onFieldChange("description", input)
													this.onRestErrorKey("description")
												} else {
													const truncatedInput = input.slice(0, 255)
													this.onFieldChange("description", truncatedInput)
													this.onRestErrorKey("description")
												}
											}}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<TableContainer>
										<Table aria-label="simple table">
											<TableHead style={{ background: '#f0f2f3' }}>
												<TableRow>
													<TableCell>Thrust</TableCell>
													<TableCell align="left">Cycles Operated</TableCell>
													<TableCell align="left">Life Limit <sup style={{ color: " #ff0000", fontSize: "14px", position: "relative", top: 0 }}> * </sup></TableCell>
													<TableCell align="left">Remaining Cycles</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{thrust.map((row, index) => (
													<TableRow >
														<TableCell> {row} </TableCell>
														<TableCell>
															<TextField
																variant="outlined"
																name="cootr_thrust"
																keyParams="cootr_thrust"
																id="cootr_thrust"
																// onChange={(e) => { e.target.value.length <= 10 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('cootr_thrust', e.target.value, index); this.onRestErrorKey("cootr_thrust") }}
																onChange={(e) => this.onFieldChange('cootr_thrust', e.target.value, index)}
																value={this.operatedThrustValue(index)}
															/>
														</TableCell>
														<TableCell>
															<TextField
																variant="outlined"
																onChange={(e) => { this.onFieldChange('life_limit', e.target.value, index); this.onRestErrorKey("life_limit") }}
																error={this.lifeLimitError(index)}
																helperText={this.lifeLimithelperText(index)}
																value={this.lifeLimitValue(index)}
															/>
														</TableCell>
														<TableCell> <TextField variant="outlined"
															disabled
															value={this.remainingCycle(index)}
														/>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextareaAutosize
											aria-label="minimum height"
											placeholder="Remark"
											name="remark"
											id="remark"
											style={{ height: "70px", width: "560px", padding: '3px' }}
											value={data.remarks ? data.remarks : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 255) {
													this.onFieldChange("remarks", input)
													this.onRestErrorKey("remarks")
												} else {
													const truncatedInput = input.slice(0, 255)
													this.onFieldChange("remarks", truncatedInput)
													this.onRestErrorKey("remarks")
												}
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
									<Grid id="data-container" spacing={1} container>
										<Grid spacing={1} container>
											{this.props.actionType === 'add' ?
												<div className='checklist-files'>
													<ul className='list-inline'>
														{data?.checklist?.map((file) =>
															<li className='list-inline-item'>
																<span className='file-name'>{file.name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
														{attachments?.map((attachments) =>
															<li className='list-inline-item'>
																<span className='file-name'>{attachments.file_name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
													</ul>
												</div>
												:
												<div className='checklist-files'>
													<ul className='list-inline'>
														{data?.checklist?.files?.map((file) =>
															<li className='list-inline-item'>
																<span className='file-name'>{file.name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
														{attachments?.map((attachments) =>
															<li className='list-inline-item'>
																<span className='file-name'>{attachments.file_name}</span>
																<span className='file-remove'>
																	<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																	<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
													</ul>
												</div>}
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button
									type="button"
									className="btn btn-primary"
									onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
								>
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>

								{this.props.actionType === "add" ? (<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<LLpConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() =>
										this.deleteAttachmentFile(this.state.deleteAttachmentId)
									}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="llp"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="llp"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default LLpTaskForms
