import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton, } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "./ConfirmDialog";
import { fillOpEditFormApi } from "../apiServices";
import OpTaskForm from "./OpTaskForm";
import moment from "moment";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";

const OpHistoryList = ({ item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, headerTsn, headerCsn, uuid, downloadAllApi, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name, engineType, basicDetails, assetsDetail }) => {
    const [open, setOpen] = useState(false);
    const [openEditForm, setEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState(null);
    const [editFormData, setEditFormData] = useState(null);
    const [formTitle, setFormTitle] = useState("");
    const [attachments, setAttachments] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
    const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] = useState(null)
    const [moduleId, setmoduleId] = useState(null)

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const closeAddForm = () => {
        setEditForm(false);
    };

    const handleEditRow = () => {
        fillOpEditFormApi(item, props).then((response) => {
            setEditFormData(response.data.data);
            setEditFormId(response.data.data.id);
            setAttachments(response.data.data.attachments);
            setFormTitle("Update Op History");
            setEditForm(true);
        });
    };
    const downAllAttach = () => {
        fillOpEditFormApi(item, props).then((response) => {
            setmoduleId(response.data.data.id)
        })
        if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
            getAttachments([...item.attachments, ...item.checklist.files]);
        } else if (item.attachments?.length > 0) {
            getAttachments([...item.attachments]);
        } else {
            getAttachments([...item.checklist.files]);
        }
    }

    const openAttachment = (file) => {
        var decodedURL = decodeURIComponent(file);
        window.open(decodedURL, "_blank");
    }
    const getAttachments = (attachments) => {
        setAllAttachmentsforDialogue(attachments)
        setAttachmentsDialogue(true)
    }
    const handleCloseAttachmentDialogue = () => {
        setAttachmentsDialogue(false)
    }

    const removeAttachment = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
            let payload = {
                delete: true,
                file_ids: attchementRemoveID.filter((id) => id !== fileId).toString()
            };
            globalPutService(`camo/ope_hist/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        fillOpEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
                                getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
                            } else if (item.attachments?.length > 0) {
                                getAttachments([...response.data.data.attachments]);
                            } else {
                                getAttachments([...response.data.data.checklist.files]);
                            }
                            getResponseBack()
                        });
                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                } else {
                    if (response) {
                        fillOpEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
                                getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
                            } else if (item.attachments?.length > 0) {
                                getAttachments([...response.data.data.attachments]);
                            } else {
                                getAttachments([...response.data.data.checklist.files]);
                            }
                            getResponseBack()
                        });
                    }
                }
            });
        }
    };
    const formatDate = (date) => {
        return moment(date).format("DD-MM-YYYY");
    };
    const toggleDescription = (id) => {
        setShowFullDescription({
            ...showFullDescription,
            [id]: !showFullDescription[id],
        });
    };

    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);
    const opUrl = `audit/camo/operationhistory/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
    console.log("item---", item)

    return (
        <>
            <TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"} >
                <TableCell> {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U", }) ? (
                    <Tooltip title="Edit">
                        <IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
                            <CreateOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
                    {permissionCheckFnforCamo({
                        primaryKey: "ad_status",
                        keyIndex: "D",
                    }) ||
                        permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "OD", userId: item.created_by, }) ? (
                        <Tooltip title="Delete" arrow>
                            <IconButton className="delete-icon" onClick={handleClick}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    <RevisionHistory buttonType={true} url={opUrl} />
                </TableCell>
                <TableCell>{item.fitment_date || "--"}</TableCell>
                <TableCell>{item.fitment_operator ? item.fitment_operator?.name : "--"}</TableCell>
                <TableCell>{item.fitment_aircraft ? item.fitment_aircraft : "--"} </TableCell>
                <TableCell>{item.fitment_tail ? item.fitment_tail : "--"}</TableCell>
                <TableCell>{item.fitment_psn ? item.fitment_psn?.label : "--"}</TableCell>
                <TableCell>{item?.fitment_thrust?.name || "--"}</TableCell>
                <TableCell>{typeof item.fitment_aircraft_flying_hours === 'string' && item.fitment_aircraft_flying_hours.length > 0 ? parseFloat(item.fitment_aircraft_flying_hours).toFixed(2) : '--'}</TableCell>
                <TableCell>{item.fitment_aircraft_flying_cycle}</TableCell>
                <TableCell>{typeof item.fitment_engine_tsn === 'string' && item.fitment_engine_tsn.length > 0 ? parseFloat(item.fitment_engine_tsn).toFixed(2) : '--'}</TableCell>
                <TableCell>{item.fitment_engine_csn}</TableCell>
                <TableCell>{item.removal_date ? item.removal_date : "--"}</TableCell>
                <TableCell>{item.removal_aircraft_flying_hours?.length > 0 ? item.removal_aircraft_flying_hours.toFixed(2) : '--'}</TableCell>
                <TableCell>{item.removal_aircraft_flying_cycle}</TableCell>
                <TableCell>{typeof item.removal_engine_tsn === 'string' && item.removal_engine_tsn.length > 0 ? parseFloat(item.removal_engine_tsn).toFixed(2) : '--'}</TableCell>
                <TableCell>{item.removal_engine_csn}</TableCell>
                <TableCell>{typeof item.removal_tslsv === 'string' && item.removal_tslsv.length > 0 ? parseFloat(item.removal_tslsv).toFixed(2) : '--'}</TableCell>
                <TableCell>{item.removal_cslsv}</TableCell>
                <TableCell>{typeof item.removal_hours_used === 'string' && item.removal_hours_used.length > 0 ? parseFloat(item.removal_hours_used).toFixed(2) : '--'}</TableCell>
                <TableCell>{item.removal_cycle_used}</TableCell>
                <TableCell>{item.removal_egtm ? item.removal_egtm : "--"}</TableCell>
                <TableCell>{item.removal_event ? item.removal_event : "--"}</TableCell>
                <TableCell>{item.maint_effective_work_carried_out ? item.maint_effective_work_carried_out : "--"}</TableCell>
                <TableCell>{item.maint_shop ? item.maint_shop : "--"}</TableCell>


                <TableCell>
                    {item.attachments?.length > 0 || item.checklist?.files?.length > 0 ? (
                        <>
                            <Tooltip title="View Attachments">
                                <span className="center" onClick={() => { downAllAttach(); }} >
                                    <AttachFileIcon className="attach-icon" />
                                    <a href="#" style={{ color: "#0e7fe1" }}>{(item.attachments?.length > 0 && item.checklist?.files?.length > 0) ? (item.attachments?.length + item.checklist?.files?.length) : item.attachments?.length > 0 ? item.attachments?.length : item.checklist?.files?.length > 0 && item.checklist?.files?.length}</a>
                                </span>
                            </Tooltip>
                        </>
                    )
                        : (
                            "--"
                        )}
                </TableCell>
            </TableRow>
            <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={handleDeleteRow}
                />
            </Dialog>
            <Dialog
                position="relative"
                maxWidth="lg"
                open={openEditForm}
                onClose={closeAddForm}
            >
                <OpTaskForm
                    formTitle={formTitle}
                    closeAddForm={() => closeAddForm()}
                    props={props}
                    editFormId={editFormId}
                    editFormData={editFormData}
                    attachments={attachments}
                    item={item}
                    headerTsn={headerTsn}
                    headerCsn={headerCsn}
                    updateFormData={updateFormData}
                    fileUploadData={fileUploadData}
                    currentRecordPerPage={currentRecordPerPage}
                    uuid={uuid}
                    last_used_folder_uuid={last_used_folder_uuid}
                    last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
                    checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
                    checkbox_last_used_folder_name={checkbox_last_used_folder_name}
                    getResponseBack={() => getResponseBack()}
                    engineType={engineType}
                    basicDetails={basicDetails}
                    assetsDetail={assetsDetail}
                />
            </Dialog>
            <Dialog
                open={openGetAttachmentsDialogue}
                onClose={handleCloseAttachmentDialogue}
                id="htDialog"
            >
                <ShowAttachments
                    handleClose={() => handleCloseAttachmentDialogue()}
                    handleShowAttachments={AllAttachmentsforDialogue}
                    openAttachment={openAttachment}
                    files={{ title: "OP History", extension: "zip", key: "" }}
                    downloadAllApi={downloadAllApi}
                    moduleId={moduleId}
                    removeAttachment={removeAttachment}
                    item={item}
                />
            </Dialog>
        </>
    );
};
export default withRouter(OpHistoryList);
