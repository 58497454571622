import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton, } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillUtilEditFormApi } from "../apiServices";
import UtilizationFormData from "./UtilizationFormData";
import moment from "moment";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";

const UtilizationList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, uuid, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name,engineType,assetsDetail,basicDetails}) => {
    const [open, setOpen] = useState(false);
    const [openEditForm, setEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState(null);
    const [editFormData, setEditFormData] = useState(null);
    const [formTitle, setFormTitle] = useState("");
    const [attachments, setAttachments] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
    const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
        useState(null);
    const [moduleId, setmoduleId] = useState(null);
    const [actionType, setActionType] = useState("");

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const closeAddForm = () => {
        setEditForm(false);
    };

    const handleEditRow = () => {
        fillUtilEditFormApi(item, props).then((response) => {
            setEditFormData(response.data.data);
            setEditFormId(response.data.data.id);
            setAttachments(response.data.data.attachments);
            setFormTitle("Update Utilization");
            setEditForm(true);
            setActionType('edit')
        });
    };

    const downAllAttach = () => {
        fillUtilEditFormApi(item, props).then((response) => {
            setmoduleId(response.data.data.id);
        });
        if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
            getAttachments([...item.attachments, ...item.checklist.files]);
        } else if (item.attachments?.length > 0) {
            getAttachments([...item.attachments]);
        } else {
            getAttachments([...item.checklist.files]);
        }
    };

    const openAttachment = (file) => {
        var decodedURL = decodeURIComponent(file);
        window.open(decodedURL, "_blank");
    };

    const formatDate = (date) => {
        return moment(date).format("DD-MM-YYYY");
    };

    const toggleDescription = (id) => {
        setShowFullDescription({
            ...showFullDescription,
            [id]: !showFullDescription[id],
        });
    };

    const getAttachments = (attachments) => {
        setAllAttachmentsforDialogue(attachments);
        setAttachmentsDialogue(true);
    };

    const handleCloseAttachmentDialogue = () => {
        setAttachmentsDialogue(false);
    };
    const removeAttachment = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
            let payload = {
                delete: true,
                file_ids: attchementRemoveID.filter((id) => id !== fileId).toString()
            };
            globalPutService(`camo/util/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        fillUtilEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
                                getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
                            } else if (item.attachments?.length > 0) {
                                getAttachments([...response.data.data.attachments]);
                            } else {
                                getAttachments([...response.data.data.checklist.files]);
                            }
                            getResponseBack()
                        });
                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                } else {
                    if (response) {
                        fillUtilEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
                                getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
                            } else if (item.attachments?.length > 0) {
                                getAttachments([...response.data.data.attachments]);
                            } else {
                                getAttachments([...response.data.data.checklist.files]);
                            }
                            getResponseBack()
                        });
                    }
                }
            });
        }
    };
    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);
    const utilurl = `audit/camo/utilization/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
                style={{ cursor: "pointer" }}
                className={
                    item.remainingValues_D < 0 || item.remainingValues_D == null
                        ? "red-row"
                        : "none"
                }
            >
                <TableCell>
                    {permissionCheckFnforCamo({
                        primaryKey: "occm_component",
                        keyIndex: "U",
                    }) ? (
                        <Tooltip title="Edit">
                            <IconButton
                                className="edit-icon"
                                onClick={(e) => {
                                    handleEditRow(e);
                                }}
                            >
                                <CreateOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    { assetsDetail.sort((a,b)=> b - a).filter(item=>item)[0]?.id === item?.id ? permissionCheckFnforCamo({
                        primaryKey: "occm_component",
                        keyIndex: "D",
                    }) ||
                        permissionCheckFnforCamo({
                            primaryKey: "occm_component",
                            keyIndex: "OD",
                            userId: item.created_by,
                        }) ? (
                        <Tooltip title="Delete" arrow>
                            <IconButton className="delete-icon" onClick={handleClick}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null : null}
                    <RevisionHistory buttonType={true} url={utilurl} />
                </TableCell>
                <TableCell> {item.utilization_period ? item.utilization_period?.data : "--"} </TableCell>
                <TableCell> {item.tsn} </TableCell>
                <TableCell> {item.csn} </TableCell>
                <TableCell> {item?.monthly_FH}</TableCell>
                <TableCell> {item?.monthly_FC}</TableCell>
                <TableCell> {item.current_operating_thrust ? item.current_operating_thrust?.name : "--"} </TableCell>
                <TableCell> {item.location_reg_aircraft ? item.location_reg_aircraft : "--"}</TableCell>
                <TableCell> {item.location_position?.label ? item.location_position?.label : "--"} </TableCell>
                <TableCell> {item.location_country ? item.location_country : "--"} </TableCell>
                <TableCell> {item.date_of_installation ? formatDate(item.date_of_installation) : "--"} </TableCell>
                <TableCell> {item.date_of_removal ? item.date_of_removal : "--"} </TableCell>
                <TableCell> {item.reason_of_removal ? item.reason_of_removal : "--"} </TableCell>
                <TableCell>
                    {item.attachments?.length > 0 || item.checklist?.files?.length > 0 ? (
                        <>
                            <Tooltip title="View Attachments">
                                <span className="center" onClick={() => { downAllAttach(); }} >
                                    <AttachFileIcon className="attach-icon" />
                                    <a href="#" style={{ color: "#0e7fe1" }}>{(item.attachments?.length > 0 && item.checklist?.files?.length > 0) ? (item.attachments?.length + item.checklist?.files?.length) : item.attachments?.length > 0 ? item.attachments?.length : item.checklist?.files?.length > 0 && item.checklist?.files?.length}</a>
                                </span>
                            </Tooltip>
                        </>
                    )
                        : (
                            "--"
                        )}
                </TableCell>
            </TableRow>
            <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={handleDeleteRow}
                />
            </Dialog>
            <Dialog
                position="relative"
                maxWidth="lg"
                open={openEditForm}
                onClose={closeAddForm}
            >
                <UtilizationFormData
                    actionType={actionType}
                    formTitle={formTitle}
                    closeAddForm={() => closeAddForm()}
                    props={props}
                    editFormId={editFormId}
                    editFormData={editFormData}
                    attachments={attachments}
                    item={item}
                    updateFormData={updateFormData}
                    fileUploadData={fileUploadData}
                    currentRecordPerPage={currentRecordPerPage}
                    uuid={uuid}
                    last_used_folder_uuid={last_used_folder_uuid}
                    last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
                    checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
                    checkbox_last_used_folder_name={checkbox_last_used_folder_name}
                    getResponseBack={() => getResponseBack()}
                    engineType={engineType}
                    assetsDetail={assetsDetail}
                    basicDetails={basicDetails}
                />
            </Dialog>
            <Dialog
                open={openGetAttachmentsDialogue}
                onClose={handleCloseAttachmentDialogue}
                id="htDialog"
            >
                <ShowAttachments
                    handleClose={() => handleCloseAttachmentDialogue()}
                    handleShowAttachments={AllAttachmentsforDialogue}
                    openAttachment={openAttachment}
                    files={{ title: "Utilization", extension: "zip", key: "" }}
                    downloadAllApi={downloadAllApi}
                    moduleId={moduleId}
                    item={item}
                    removeAttachment={removeAttachment}
                />
            </Dialog>
        </>
    );
};
export default withRouter(UtilizationList);