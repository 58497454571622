import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Button, DialogTitle, DialogContent, DialogActions, TextField, } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalPutService, globalGetService } from '../../utils/globalApiServices';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { checkApiStatus } from '../../utils';
import { fieldDateFormat, backendDateFormat } from '../../constants';
import { regexConstants } from '../../constants/regEx';
import { PageLoader } from '../../shared_elements';

function EngineDetailForm({ slug, closeUpdateForm, basicDetailData, getResponseBack, assetDetail, assets }) {
    console.log("basicDetailData",basicDetailData)
   
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [techSpecData, settechSpecData] = useState(basicDetailData);
    const [newtechSpecData, setnewtechSpecData] = useState(basicDetailData?.new_tec_spec);
    const [error, setError] = useState({});
    const [dropDownList, setDropDownList] = useState([])
    const [pageLoader, setPageLoader] = useState(false)

    const todayDate = moment(new Date()).format(backendDateFormat);
    const leaseDate = newtechSpecData?.lease_term_date || basicDetailData?.new_tec_spec?.lease_term_date;
    const monthRemaining = moment(leaseDate, backendDateFormat).diff(moment(todayDate, backendDateFormat), 'month');

    console.log("techSpecData",techSpecData)

    useEffect(() => {
        allDropDownList()
    }, [])
    const onFieldChange = (keyParam, value, extraProps) => {
        if (extraProps) {
            setnewtechSpecData({ ...newtechSpecData, [keyParam]: value })
        } else {
            settechSpecData({ ...techSpecData, [keyParam]: value })
        }

    }
    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })
    }

    const onAddNewAsset = () => {
        setPageLoader(true)
        let payload = {
            id: techSpecData.id || null,
            slug: techSpecData.slug || null,
            location: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : techSpecData.country || null,
            operator: techSpecData?.operator || null,
            lessee: techSpecData?.lessee || null,
            position: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] : techSpecData.position || null,
            lessor_name: techSpecData?.lessor_name || null,
            delivery_date_to_current_operator: techSpecData?.delivery_date_to_current_operator || null,
            average_monthly_hours: assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FH)[0] || null,
            average_monthly_cycles: assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FC)[0] || null,
            purchase_date: techSpecData?.purchase_date || null,
            next_delivery_date: techSpecData?.next_delivery_date || null,
            country: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : techSpecData.country || null,
            region: techSpecData?.region || null,
            date_of_installation: techSpecData?.date_of_installation || null,
            last_utilization_updated: techSpecData?.last_utilization_updated || null,
            tslsv: techSpecData?.tslsv || null,
            cslsv: techSpecData?.cslsv || null,
            owner: techSpecData?.owner || null,
            sub_status: ({ value: techSpecData?.sub_status?.id || techSpecData?.sub_status?.value }) || null,
            status: ({ value: techSpecData?.status?.id || techSpecData?.status?.value, label: techSpecData?.label }) || null,
            llp_limiter: techSpecData?.llp_limiter || null,
            Soft_time_limiter: techSpecData?.Soft_time_limiter || null,
            avg_flight_ratio: techSpecData?.avg_flight_ratio || null,
            new_tec_spec: {
                aircraft_model: newtechSpecData?.aircraft_model || null,
                owner_aircraft: newtechSpecData?.owner_aircraft || null,
                location_reg_aircraft: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || null,
                date_of_removal: newtechSpecData?.date_of_removal || null,
                avg_derate: +newtechSpecData.avg_derate || 0,
                engine_stand_part_number: newtechSpecData.engine_stand_part_number || null,
                engine_stand_serial_number: newtechSpecData.engine_stand_serial_number || null,
                engine_stand_location: newtechSpecData.engine_stand_location || null,
                qec_config: newtechSpecData.qec_config || null,
                qec_part_number: newtechSpecData.qec_part_number || null,
                min_llp_prior_to_term_date: newtechSpecData.min_llp_prior_to_term_date || null,
                lease_start_date: newtechSpecData.lease_start_date || null,
                lease_term_date: newtechSpecData?.lease_term_date || null,
                redelivery_date: newtechSpecData.redelivery_date || null,
                eec_software_part_number: newtechSpecData.eec_software_part_number || null,
                eec_software_version: newtechSpecData.eec_software_version || null,
                monthly_use_fee: newtechSpecData.monthly_use_fee || '',
                monthly_llp_fee: newtechSpecData.monthly_llp_fee || '',
                accumulated_mr: newtechSpecData.accumulated_mr || '',
                month_remaining: monthRemaining ? monthRemaining : 0,
                bag: newtechSpecData.bag,
                stand: newtechSpecData.stand,
            }
        };
        globalPutService(`camo/engine_tech_spec/${slug}/details/`, payload)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    closeUpdateForm(); getResponseBack()
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                }
            })
    }
    const allDropDownList = () => {
        globalGetService(`camo/all_dropdown_list/`)
            .then(response => {
                if (response?.status === 200) {
                    setDropDownList(response.data)
                }
            })
    }
    console.log("newtechSpecData", newtechSpecData)
    const stand_and_bag = ['YES', 'NO', 'N/A']
    return (
        <div id="AmpTaskForm">
            <DialogTitle >
                Update Technical Specification
                <CloseIcon className="close-icon" onClick={closeUpdateForm} />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="new_tec_spec.aircraft_model"
                            label="A/C model"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData?.aircraft_model || ""}
                            onChange={(e, value) => onFieldChange('aircraft_model', e.target.value, true)}
                            onFocus={() => onRestErrorKey('aircraft_model')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.opertor_lessee_list || []}
                            getOptionLabel={option => option.name}
                            value={techSpecData?.lessee}
                            onChange={(e, value) => onFieldChange("lessee", value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('lessee')} {...params} label="Operator (Lessee)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.operator_sub_Lessee || []}
                            getOptionLabel={option => option.name}
                            value={techSpecData?.operator}
                            onChange={(e, value) => onFieldChange("operator", value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('operator')} {...params} label="Operator ((Sub-Lessee))" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Owner A/C"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData?.owner_aircraft || ""}
                            onChange={(e, value) => onFieldChange('owner_aircraft', e.target.value, true)}
                            onFocus={() => onRestErrorKey('owner_aircraft')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.owner_list || []}
                            getOptionLabel={option => option.name}
                            id="lessor_name"
                            value={techSpecData?.lessor_name}
                            onChange={(e, value) => onFieldChange('lessor_name', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('Lessor')} {...params} label="Lessor Name" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Delivery Date"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={techSpecData?.delivery_date_to_current_operator || null}
                                error={error?.delivery_date_to_current_operator ? true : false}
                                helperText={error.delivery_date_to_current_operator ? error?.delivery_date_to_current_operator : ''}
                                onChange={(data, value) => onFieldChange('delivery_date_to_current_operator', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="Monthly EFH"
                            label="Monthly EFH"
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FH)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly EFC"
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FC)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Date of Purchase"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={techSpecData?.purchase_date ? techSpecData?.purchase_date : null}
                                error={error?.purchase_date ? true : false}
                                helperText={error.purchase_date ? error?.purchase_date : ''}
                                onChange={(data, value) => onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Next Delivery  Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={techSpecData?.next_delivery_date ? techSpecData?.next_delivery_date : null}
                                error={error?.next_delivery_date ? true : false}
                                helperText={error.next_delivery_date ? error?.next_delivery_date : ''}
                                onChange={(data, value) => onFieldChange('next_delivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Locations (A/C Reg.)"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData?.location_reg_aircraft ? newtechSpecData?.location_reg_aircraft : newtechSpecData.location_reg_aircraft || assets?.sort((a, b) => b - a).map(filterId => filterId.location_reg_aircraft)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.position_list || []}
                            getOptionLabel={option => option.label}
                            id="position"
                            disabled
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] :techSpecData.position || null}
                            // onChange={(e, value) => onFieldChange('position', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('position')} {...params} label="Locations (Position)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Locations (Country) "
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ?  assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : techSpecData.country || "--"}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.region_list || []}
                            getOptionLabel={option => option.name}
                            id="region"
                            value={techSpecData?.region}
                            onChange={(e, value) => onFieldChange('region', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('region')} {...params} label="Operating Region" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Date of installation"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={techSpecData?.date_of_installation ? techSpecData?.date_of_installation : null}
                                error={error?.date_of_installation ? true : false}
                                helperText={error.date_of_installation ? error?.date_of_installation : ''}
                                onChange={(data, value) => onFieldChange('date_of_installation', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Date of Removal"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail.date_of_manufacture)}
                                value={newtechSpecData.date_of_removal || null}
                                error={error?.new_tec_spec?.date_of_removal ? true : false}
                                helperText={error.new_tec_spec?.date_of_removal ? error?.new_tec_spec?.date_of_removal : ''}
                                onChange={(data, value) => onFieldChange('date_of_removal', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, true)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Reporting Month"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                disabled
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail.date_of_manufacture)}
                                value={techSpecData?.last_utilization_updated ? techSpecData?.last_utilization_updated : null}
                                error={error?.last_utilization_updated ? true : false}
                                helperText={error.last_utilization_updated ? error?.last_utilization_updated : ''}
                                onChange={(data, value) => onFieldChange('last_utilization_updated', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="avg_flight_ratio"
                            label="Avg. Flight Ratio"
                            fullWidth
                            margin="normal"
                            value={techSpecData?.avg_flight_ratio ? (techSpecData?.avg_flight_ratio).toFixed(2) : ""}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="avg_derate"
                            label="Avg. Derate %"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.avg_derate || ""}
                            onFocus={() => onRestErrorKey('avg_derate')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            onChange={(e) => {
                                const value = e.target.value;
                                const numericValue = value.replace(/[^\d.]/g, '');
                                const dotCount = (numericValue.match(/\./g) || []).length;
                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 2 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 3 && regexConstants.numberWithDot.test(numericValue);
                                if (isValid || value === "") {
                                    onFieldChange("avg_derate", numericValue, true);
                                }
                                onRestErrorKey("avg_derate");
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="TSLV"
                            fullWidth
                            margin="normal"
                            value={techSpecData?.tslsv || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="CSLV"
                            fullWidth
                            margin="normal"
                            value={techSpecData?.cslsv || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="LLP Limiter(s)"
                            fullWidth
                            margin="normal"
                            value={techSpecData?.llp_limiter || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Soft-time Limiter(s)"
                            fullWidth
                            margin="normal"
                            value={techSpecData?.Soft_time_limiter}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand P/N "
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.engine_stand_part_number || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_part_number', e.target.value, true)}
                            onFocus={() => onRestErrorKey('engine_stand_part_number')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'

                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand S/N"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.engine_stand_serial_number || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_serial_number', e.target.value, true)}
                            onFocus={() => onRestErrorKey('engine_stand_serial_number')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'

                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Location"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.engine_stand_location || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_location', e.target.value, true)}
                            onFocus={() => onRestErrorKey('engine_stand_location')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.owner_list || []}
                            getOptionLabel={option => option.name}
                            id="owner"
                            value={techSpecData?.owner}
                            onChange={(e, value) => onFieldChange('owner', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('owner')} {...params} label="Asset Owner" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="QEC Config."
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.qec_config || ""}
                            onChange={(e, value) => onFieldChange('qec_config', e.target.value, true)}
                            onFocus={() => onRestErrorKey('qec_config')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="QEC P/N."
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.qec_part_number || ""}
                            onChange={(e, value) => onFieldChange('qec_part_number', e.target.value, true)}
                            onFocus={() => onRestErrorKey('qec_part_number')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Min LLP Prior to Term. Date"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.min_llp_prior_to_term_date || ""}
                            onChange={(e, value) => onFieldChange('min_llp_prior_to_term_date', e.target.value, true)}
                            onFocus={() => onRestErrorKey('min_llp_prior_to_term_date')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.asset_sub_status_list || []}
                            getOptionLabel={option => option.label}
                            id="sub_status"
                            value={techSpecData?.sub_status}
                            onChange={(e, value) => onFieldChange('sub_status', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('sub_status')} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Lease Start Date"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail.date_of_manufacture)}
                                value={newtechSpecData.lease_start_date || null}
                                error={error?.new_tec_spec?.lease_start_date ? true : false}
                                helperText={error.new_tec_spec?.lease_start_date ? error?.new_tec_spec?.lease_start_date : ''}
                                onChange={(data, value) => onFieldChange('lease_start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, true)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Lease Term Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={newtechSpecData?.lease_term_date || null}
                                error={error?.new_tec_spec?.lease_term_date ? true : false}
                                helperText={error.new_tec_spec?.lease_term_date ? error?.new_tec_spec?.lease_term_date : ''}
                                onChange={(data, value) => onFieldChange('lease_term_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, true)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Redelivery Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={newtechSpecData.redelivery_date || null}
                                error={error?.new_tec_spec?.redelivery_date ? true : false}
                                helperText={error.new_tec_spec?.redelivery_date ? error?.new_tec_spec?.redelivery_date : ''}
                                onChange={(data, value) => onFieldChange('redelivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, true)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Month Remaining"
                            fullWidth
                            margin="normal"
                            value={monthRemaining >= 0 ? monthRemaining : 0}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.asset_status_list || []}
                            getOptionLabel={option => option.label}
                            id="status"
                            value={techSpecData?.status}
                            onChange={(e, value) => onFieldChange('status', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('status')} {...params} label="On/Off Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="EEC Software P/N"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.eec_software_part_number || ""}
                            onChange={(e, value) => onFieldChange('eec_software_part_number', e.target.value, true)}
                            onFocus={() => onRestErrorKey('eec_software_part_number')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="EEC Software Version"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.eec_software_version || ""}
                            onChange={(e, value) => onFieldChange('eec_software_version', e.target.value, true)}
                            onFocus={() => onRestErrorKey('eec_software_version')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly Use Fee"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.monthly_use_fee || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly LLP Fee"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.monthly_llp_fee || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Accumulated MR"
                            fullWidth
                            margin="normal"
                            value={newtechSpecData.accumulated_mr || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={stand_and_bag || []}
                            getOptionLabel={(option) => option}
                            value={newtechSpecData?.stand || null}
                            onChange={(e, value) => onFieldChange('stand', value, true)}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Stand"
                                    margin="normal"
                                    fullWidth
                                    placeholder="Select Stand"
                                    variant="outlined"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={stand_and_bag || []}
                            getOptionLabel={(option) => option}
                            value={newtechSpecData?.bag || null}
                            onChange={(e, value) => onFieldChange('bag', value, true)}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Bag"
                                    margin="normal"
                                    fullWidth
                                    placeholder="Select Bag"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onAddNewAsset} > SAVE CHANGES </Button>
                <Button color="primary" variant="contained" onClick={closeUpdateForm}>CANCEL</Button>
            </DialogActions>
            {pageLoader ? <PageLoader /> : null}
        </div>
    )
}

export default withRouter(EngineDetailForm)