import { globalDeleteService, globalGetService, globalExportService, globalPostService, globalFileUploadService, globalPutService, } from "../../../utils/globalApiServices";
import { downloadFileType, permissionCheckFnforCamo, } from "../../../utils";
import { trackActivity } from "../../../utils/mixpanel";
import { checkApiStatus } from "../../../utils";
import moment from "moment";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
let timeoutId;

export function getOpHistoryApi(queryParams = {}, loaderType, props = {}, searchString = null) {
    if (permissionCheckFnforCamo({ primaryKey: "occm_component", keyIndex: "R", })) {
        this.setState({ pageLoader: true });
        const { asset, assetType } = props?.match?.params;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            globalGetService(`camo/ope_hist/`, {
                ...queryParams,
                per_page: queryParams.per_page ? queryParams.per_page : 50,
                asset: asset,
                asset_type: assetType,
                search: searchString,
            }).then((response) => {
                if (response?.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    this.setState({
                        pageLoader: false,
                        assetsDetail: {
                            list: response?.data?.results,
                        },
                    });
                    delete queryParams.page;
                    delete queryParams.per_page;
                    delete queryParams.asset_type;
                    delete queryParams.sort;
                    delete queryParams.sort_by;
                    this.setState((prevState) => ({
                        ...prevState,
                        filter: queryParams,
                        assetsDetail: {
                            ...prevState.assetsDetail,
                            pagination: {
                                ...prevState.assetsDetail?.pagination,
                                total: response?.data?.pagination?.total,
                                per_page: response?.data?.pagination?.per_page,
                                page: parseInt(response?.data?.pagination?.current_page),
                            },
                            new_task_last_used_folder_uuid: response?.data?.new_task_last_used_folder_uuid,
                            last_used_folder_uuid_link_document: response?.data?.last_used_folder_uuid_link_document,
                            checkbox_last_used_folder_uuid: response?.data?.checkbox_last_used_folder_uuid,
                            checkbox_last_used_folder_name: response?.data?.checkbox_last_used_folder_name
                        },
                    }));
                }
            });
        }, 500);
    }
}

export function deleteOpAssetDetailRow(item, props, per_page) {
    globalDeleteService(`camo/ope_hist/${item.id}/`).then((response) => {
        if (response.status === 200) {
            props.enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        }
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        }
        this.getOpHistoryApi({ per_page: per_page }, "skeletonLoader", props);
    });
}

export function addOpFormApi(data, props) {
    if (permissionCheckFnforCamo({ primaryKey: "occm_component", keyIndex: "C", })) {
        return new Promise(function (resolve, reject) {
            globalPostService(`camo/ope_hist/`, data).then((response) => {
                if (response?.data?.statusCode === 500) {
                    props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    resolve(response);
                }
            });
        });
    }
}

export function fillOpEditFormApi(item, props) {
    return new Promise(function (resolve, reject) {
        globalGetService(`camo/ope_hist/${item.id}/`).then((response) => {
            if (response.status === 500) {
                props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            } else {
                resolve(response);
            }
        });
    });
}

export function UpdateOpFormApi(updateId, updatedData, props, per_page) {
    if (permissionCheckFnforCamo({ primaryKey: "occm_component", keyIndex: "U", })) {
        globalPutService(`camo/ope_hist/${updateId}/`, updatedData).then(
            (response) => {
                if (response?.data?.statusCode === 500) {
                    props.enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    if (response) {
                        props.enqueueSnackbar("Record Updated Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                    }
                    this.getOpHistoryApi({ per_page: per_page }, "skeletonLoader", props);
                }
            }
        );
    }
}

export function uploadOpFileApi(uploadId, formdata, props, per_page, haveAttachments) {
    this.setState({ pageLoader: true });
    if (haveAttachments) {
        globalFileUploadService(`camo/attachment/upload/`, formdata)
            .then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    if (response?.data?.statusCode === 200) {
                        this.setState({ pageLoader: false });
                        if (response.data.data.length > 0) {
                        }
                    }
                    this.getOpHistoryApi({ per_page: per_page }, "skeletonLoader", props);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    } else {
        props.enqueueSnackbar("Data Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        this.getOpHistoryApi({ per_page: per_page }, "", props);
    }
}

export function exportOpListApi(queryParams = {}, loaderType, props = {}) {
    this.setState({ pageLoader: true });
    const date = new Date();
    const todayeDate = moment(String(date)).format("DD-MM-YYYY");

    const { asset, assetType } = this.props.match.params;
    delete queryParams.asset_type;
    globalExportService(assetType == 2 ? `/camo/ope_hist/${asset}/${assetType}/export/` : `/camo/ope_hist/${asset}/${assetType}/export/`, {
        ...queryParams,
    }).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            this.setState({ pageLoader: false });
            downloadFileType(response.data, ` Op. History${todayeDate}`, queryParams.download);
            this.props.enqueueSnackbar("Download of Op. History List successful", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            trackActivity("Item Exported", {
                page_title: "Asset Listing",
            });
        }
    });
}

export function deleteOpAttachment(id, props) {
    globalDeleteService(`camo/attachment/${id}/delete/`).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            if (response) {
                props.enqueueSnackbar("Attachment Deleted Successfully", {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                });
            }
        }
    });
}

export function importOpFileApi(formData, props, isXLSX, per_page) {
    if (isXLSX) {
        this.setState({ pageLoader: true });
        const { asset, assetType } = this.props.match.params;

        globalFileUploadService(`camo/ope_hist/${asset}/${assetType}/import/`, formData)
            .then((response) => {
                this.setState({ pageLoader: false });
                if (response?.data?.statusCode === 200) {
                    props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                }
                this.getOpHistoryApi({ per_page: per_page }, "skeletonLoader", props);

            })
            .catch((err) => {
                console.error(err);
                props.enqueueSnackbar("Please import proper file", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            });
    } else {
        props.enqueueSnackbar("Please Import only .XLSX File", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
    }
}

export function DownloadStSampleFile(queryParams = {}, loaderType, props = {}) {
    const { assetType } = props.match.params
    globalExportService(`camo/ope_hist/sample-file/`).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            downloadFileType(response.data, `SampleFile Op History`, queryParams.download);
            trackActivity("Item Exported", {
                page_title: "Asset Listing",
            });
        }
    });
}
export function getOperatorList() {
    globalGetService(`console/lessees/`).then((response) => {
        if (response.status === 200) {
            this.setState({ operatorList: response?.data?.data?.lessees })
        };
    })
};
export function allDropDownList() {
    this.setState({ pageLoader: true })
    globalGetService(`camo/all_dropdown_list/`)
      .then(response => {
        this.setState({ pageLoader: false })
        if (response?.status === 200) {
            this.setState({positionDropDownList:response.data.position_list})
        }
      })
  }
  export function getAllOppThrust(props,engine_type) {
    globalGetService(`camo/current_thrust/${props.assetType}/?engine_family_engine_type=${engine_type?.engine_family_type_id}`,)
      .then(response => {
        if (response?.status === 200) {
          this.setState({ operatingThrust: response?.data })
        }
      })
  }

  export function exportMasterApi(queryParams = {}, loaderType, props = {}) {
	this.setState({ pageLoader: true });
	const date = new Date();
	const todayeDate = moment(String(date)).format("DD-MM-YYYY");

	const { asset, assetType } = this.props.match.params;
	delete queryParams.asset_type;
	globalExportService(`/camo/smbc_master_excel/${asset}/${assetType}/export/`)
		.then((response) => {
			if (response.status === 500) {
				props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
			} else {
				this.setState({ pageLoader: false });
				downloadFileType(response.data, `Master_Export_${todayeDate}`, queryParams.download);
				this.props.enqueueSnackbar("Master Export Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				trackActivity("Master Export", {
					page_title: "Asset Listing",
				});
			}
		});
}

export async function DownloadAllApi(queryParams = {}, loaderType, props = {}) {
    this.setState({ pageLoader: true })
    const attachment_list = queryParams.item.download_attachments;
    for (var i = 0; i < attachment_list.length; i++) {
        var encodedURL = attachment_list[i][1];
        var decodedURL = decodeURIComponent(encodedURL);
        attachment_list[i][1] = decodedURL;
    }
    const zip = new JSZip();
    const folder = zip.folder('DownloadedFiles');
    try {
        const downloadPromises = attachment_list.map(async (entry) => {
            const name = entry[0];
            const url = entry[1] || '';

            const res = await fetch(url);

            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }

            const blob = await res.blob();
            folder.file(name, blob);
        });

        await Promise.all(downloadPromises);

        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'Op. History Time.zip');
        this.setState({ pageLoader: false })
    } catch (error) {
        console.error('Error:', error);
    }
}







