import { IconButton, TableCell, TableRow, Tooltip, Dialog, } from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import LLpTaskForms from "./LLpTaskForms";
import { fillLLpEditFormApi } from "../apiService";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
const LLpList = ({ item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, headerTsn, headerCsn, uuid, downloadAllApi, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name }) => {
	const [open, setOpen] = useState(false);
	const [openEditForm, setEditForm] = useState(false);
	const [editFormId, setEditFormId] = useState(null);
	const [editFormData, setEditFormData] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [attachments, setAttachments] = useState(null);
	const [showFullDescription, setShowFullDescription] = useState({});
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] = useState(null)
	const [moduleId, setmoduleId] = useState(null)

	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const closeAddForm = () => {
		setEditForm(false);
	};

	const handleEditRow = () => {
		fillLLpEditFormApi(item, props).then((response) => {
			setEditFormData(response.data.data);
			setEditFormId(response.data.data.id);
			setAttachments(response.data.data.attachments);
			setFormTitle("Update Life Limited Parts (LLPs)");
			setEditForm(true);
		});
	};
	const downAllAttach = () => {
		fillLLpEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id)
		})
		if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
			getAttachments([...item.attachments, ...item.checklist.files]);
		} else if (item.attachments?.length > 0) {
			getAttachments([...item.attachments]);
		} else {
			getAttachments([...item.checklist.files]);
		}
	}

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	}
	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments)
		setAttachmentsDialogue(true)
	}
	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false)
	}

	const removeAttachment = (item, checklist, fileId, uuid) => {
		const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
		const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
		if (uuid) {
			let payload = {
				delete: true,
				file_ids: attchementRemoveID.filter((id) => id !== fileId).toString()
			};
			globalPutService(`camo/llp/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillLLpEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							} else if (item.attachments?.length > 0) {
								getAttachments([...response.data.data.attachments]);
							} else {
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillLLpEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							if (item.attachments?.length > 0 && item.checklist.files?.length > 0) {
								getAttachments([...response.data.data.attachments, ...response.data.data.checklist.files]);
							} else if (item.attachments?.length > 0) {
								getAttachments([...response.data.data.attachments]);
							} else {
								getAttachments([...response.data.data.checklist.files]);
							}
							getResponseBack()
						});
					}
				}
			});
		}
	};
	const formatDate = (date) => {
		return moment(date).format("DD-MM-YYYY");
	};
	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		});
	};

	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const llpurl = `audit/camo/lifelimitedpart/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`


	return (
		<>
			<TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"} >
				<TableCell> {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U", }) ? (
					<Tooltip title="Edit">
						<IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
				) : null}
					{permissionCheckFnforCamo({
						primaryKey: "ad_status",
						keyIndex: "D",
					}) ||
						permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "OD", userId: item.created_by, }) ? (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					<RevisionHistory buttonType={true} url={llpurl} />
				</TableCell>
				<TableCell>{item.module_type || "--"}</TableCell>
				<TableCell style={{ textAlign: "left" }}>
					{item.description && item.description.length > 50 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span> {item.description}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >..less </a>
								</span>
							) : (
								<span> {item.description.substring(0, 50)}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
								</span>
							)}
						</div>
					) : item.description && item.description.length <= 50 ? (
						<span>{item.description}</span>
					) : (
						"--"
					)}
				</TableCell>
				<TableCell>{item.part_number || "--"}</TableCell>
				<TableCell>{item.serial_number || "--"}</TableCell>
				<TableCell>{item.tsn || "--"}</TableCell>
				<TableCell>{item.csn || "--"}</TableCell>
				<TableCell>{item.COOTR_thrust_a}</TableCell>
				<TableCell>{item.COOTR_thrust_b}</TableCell>
				<TableCell>{item.COOTR_thrust_c}</TableCell>
				<TableCell>{item.COOTR_thrust_d}</TableCell>
				<TableCell>{item.COOTR_thrust_e}</TableCell>
				<TableCell>{item.life_limit_thrust_a}</TableCell>
				<TableCell>{item.life_limit_thrust_b}</TableCell>
				<TableCell>{item.life_limit_thrust_c}</TableCell>
				<TableCell>{item.life_limit_thrust_d}</TableCell>
				<TableCell>{item.life_limit_thrust_e}</TableCell>
				<TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_a}</TableCell>
				<TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_b}</TableCell>
				<TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_c}</TableCell>
				<TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_d}</TableCell>
				<TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_e}</TableCell>
				<TableCell>{item.remarks ||  "--"}</TableCell>
				<TableCell>
					{item.attachments?.length > 0 || item.checklist?.files?.length > 0 ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => { downAllAttach(); }} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{(item.attachments?.length > 0 && item.checklist?.files?.length > 0) ? (item.attachments?.length + item.checklist?.files?.length) : item.attachments?.length > 0 ? item.attachments?.length : item.checklist?.files?.length > 0 && item.checklist?.files?.length}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
				onClose={closeAddForm}
			>
				<LLpTaskForms
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					uuid={uuid}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>
			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					removeAttachment={removeAttachment}
					item={item}
				/>
			</Dialog>
		</>
	);
};
export default withRouter(LLpList);
