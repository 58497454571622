import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel, FormControlLabel, Radio, Dialog, Tooltip, TextareaAutosize } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/SbTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment";
import axios from 'axios';
import MomentUtils from "@date-io/moment";
import { backendDateFormat, fieldDateFormat } from "../../../../constants";
import { addSbFormApi, deleteAdAttachment } from "../apiService";
import SbConfirmDeleteAttachment from "./SbConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import { checkApiStatus, getLocalStorageInfo } from "../../../../utils";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import { globalPutService } from "../../../../utils/globalApiServices";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import config from "../../../../config";
import { trackActivity } from '../../../../utils/mixpanel';

class SbTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			csn: this.props.headerData.csn,
			tsn: this.props.headerData.tsn,
			showSuccessUpload: false,
			linkFile: false,
			uploadFile: false
		}
		this.addSbFormApi = addSbFormApi.bind(this)
		this.dropDownChange = this.dropDownChange.bind(this)
		this.dropDownChange1 = this.dropDownChange1.bind(this)
		this.dropDownChange2 = this.dropDownChange2.bind(this)
	}

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'Service Bulletins(SB) Add Task Form',
			})
	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};


	dropDownChange(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				type: e.target.value,
			},
		}))
	}

	dropDownChange1(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				sb_interval_type: e.target.value,
			},
		}))
	}

	dropDownChange2(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				sb_compliance_status: e.target.value,
			},
		}))
	}

	onFieldChange = (keyParam, value) => {
		const { data } = this.state

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				[keyParam]: value,
			},
		}))
	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)
		validationInputs = {
			...validationInputs,
			ata_chapter: smbcInstance ? "" : taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number, })],
			description: smbcInstance ? "" : taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
			revision: smbcInstance ? "" : taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision, })],
			revision_date: smbcInstance ? "" : taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date, })],
			// service_bulletin: taskErrorCode["service_bulletin"][fieldValidation({ ...taskErrorCode["service_bulletin_obj"], fieldval: data.service_bulletin, })],
			last_done_fc: smbcInstance ? "" : taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
			last_done_fh: smbcInstance ? "" : taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
		}

		if (data.sb_interval_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.sb_interval_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		}
		else if (data.sb_interval_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}
		if (data.sb_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: smbcInstance ? "" : taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
				sb_compliance_csn: smbcInstance ? "" : taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
				sb_compliance_date: smbcInstance ? "" : taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
			}
		}
		if (
			Object.keys(validationInputs).every((k) => {
				return validationInputs[k] === ""
			})
		) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))

			// add task api
			const props = this.props.props

			const SbData = data
			delete SbData.ad_due
			delete SbData.remaining_val
			SbData.asset_id = props.match.params.asset
			SbData.asset_type_id = props.match.params.assetType
			SbData.last_done_fc =
				SbData && SbData.last_done_fc === "" ? null : SbData.last_done_fc
			SbData.last_done_fh =
				SbData && SbData.last_done_fh === "" ? null : SbData.last_done_fh
			addSbFormApi(SbData, props).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'Service Bulletins(SB)',
						item_id: uploadId ? uploadId : '',
						request_body: SbData ? SbData : '',
						response_msg: response.data.message ? response.data.message : ''
					  })
				}
			})
			if (this.state.btnStatus !== "addAnother") {
				this.props.closeAddForm()
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						sb_interval: "",
					},
				}))
			} else {
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						sb_interval: "",
					},
				}))
				return false
			}
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'Service Bulletins(SB)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}
	}

	// edit task form
	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const updateId = this.props.editFormId
		const updatedData = this.state.data
		const { data } = this.state
		updatedData.last_done_fc =
			updatedData && updatedData.last_done_fc === ""
				? null
				: updatedData.last_done_fc
		updatedData.last_done_fh =
			updatedData && updatedData.last_done_fh === ""
				? null
				: updatedData.last_done_fh
		const props = this.props.props
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)
		validationInputs = {
			...validationInputs,
			ata_chapter: smbcInstance ? "" : taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter !== null ? String(data.ata_chapter) : "", })],
			sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number !== null ? String(data.sb_ref_number) : "", })],
			description: smbcInstance ? "" : taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description !== null ? String(data.description) : "", })],
			revision: smbcInstance ? "" : taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision !== null ? String(data.revision) : "", })],
			revision_date: smbcInstance ? "" : taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date !== null ? String(data.revision_date) : "", })],
			// service_bulletin: taskErrorCode["service_bulletin"][fieldValidation({ ...taskErrorCode["service_bulletin_obj"], fieldval: data.service_bulletin !== null ? String(data.service_bulletin) : "", })],
			last_done_fc: smbcInstance ? "" : taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
			last_done_fh: smbcInstance ? "" : taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],

		}
		if (data.sb_interval_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.sb_interval_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}

		if (data.sb_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: smbcInstance ? "" : taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
				sb_compliance_csn: smbcInstance ? "" : taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
				sb_compliance_date: smbcInstance ? "" : taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
			}
		}

		if (
			Object.keys(validationInputs).every((k) => {
				return validationInputs[k] === ""
			})
		) {
			delete updatedData.ad_due
			delete updatedData.remaining_val
			updatedData.asset_id = props.match.params.asset
			updatedData.asset_type_id = props.match.params.assetType

			await this.uploadFileTest(updateId, props, true)
			await this.props.updateFormData(updateId, updatedData, props)
			this.setState((prevState) => ({
				error: {
					...prevState.error,
					sb_interval: "",
				},
			}))
			this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'Service Bulletins(SB)',
				item_id: updateId ? updateId : '',
				request_body: updatedData ? updatedData : ''
			  })
		} else {
			// Validations failed, update error state
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'Service Bulletins(SB)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 5
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append("[" + key + "]file", this.state.newUploadedAttachments[key])
				formdata.append("[" + key + "]file_type", this.state.newUploadedAttachments[key].type)
				formdata.append("[" + key + "]file_name", this.state.newUploadedAttachments[key].name)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}

	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 5,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
				asset_id: this.props.props.match.params.asset,
				asset_type_id: this.props.props.match.params.assetType
			}
			existingAttachments.push(objc)
		})

		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}
	nextDueValue = () => {
		const { data } = this.state
		if (data.sb_interval_type === "D") {
			data.sb_due = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
		} else if (data.sb_interval_type === "FC") {
			data.sb_due = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
		} else if (data.sb_interval_type === "FH") {
			data.sb_due = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
		} else if (data.sb_interval_type === "FC,D") {
			const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
			const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
			data.sb_due = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue  || ''
		} else if (data.sb_interval_type === "FH,D") {
			const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
			const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
			data.sb_due = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
		} else if (data.sb_interval_type === "FC,FH,D") {
			const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || '' 
			const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
			const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''

			if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
				data.sb_due = fhDue || ''
			} else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
				data.sb_due = fcDue;
			} else {
				data.sb_due = dateDue;
			}
		} else if (data.sb_interval_type === "FC,FH") {
			const fcDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
			const fhDue = Number.isInteger(+data.last_done_fc + +data.sb_interval) && (+data.last_done_fc + +data.sb_interval).toFixed(2) || ''
			data.sb_due = fcDue < fhDue ? fcDue : fhDue;
		}
		return data.sb_due
	}
	remainingVal = () => {
		const { data } = this.state
		var date = new Date()
		const lDate = moment(data.last_done_date)
		const todayD = moment(date)
		let remDay = lDate.diff(todayD, "days")
		if (data.sb_interval_type === "D") {
			data.remaining_val = moment(remDay).add(data.sb_interval) || ''
		} else if (data.sb_interval_type === "FC") {
			data.remaining_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2) || ''
		} else if (data.sb_interval_type === "FH") {
			data.remaining_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2) || ''
		} else if (data.sb_interval_type === "FC,D") {
			const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2) || ''
			const dRem_val = moment(remDay).add(data.dimension_interval_days)
			data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
		} else if (data.sb_interval_type === "FH,D") {
			const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2) || ''
			const dRem_val = moment(remDay).add(data.dimension_interval_days)
			data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
		} else if (data.sb_interval_type === "FC,FH,D") {
			const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2) || ''
			const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2) || ''
			const dRem_val = moment(remDay).add(data.dimension_interval_days)

			if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fhRem_val;
			} else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fcRem_val;
			} else {
				data.remaining_val = dRem_val;
			}
		} else if (data.sb_interval_type === "FC,FH") {
			const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2) || ''
			const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2) || ''
			data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
		}
		return data.remaining_val || ''
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAdAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		}
	}

	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]

		var filteredArray = []

		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		// Set the state with the new filtered array
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {
			delete: true,
			file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
		};
		globalPutService(`camo/sb/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'Service Bulletins(SB)',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				  })
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}
	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {
				file_ids: data.checklist.map(item => item.id).filter(item => item) && data.checklist.map(item => item.id).filter(item => item).join(','),
			};
			globalPutService(`camo/sb/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/sb/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}

	render() {
		const fileTypes = [".GIF", ".PDF", ".DOC", ".DOCX", ".XLSX", ".TXT", ".XLS", "image/*",]
		const { data, error, attachments } = this.state
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(config?.env?.key) ? 465 : 532)
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="HtTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											required={!smbcInstance}
											variant="outlined"
											label="ATA Chapter"
											name="ata_chapter"
											id="ata_chapter"
											value={data.ata_chapter}
											error={error.ata_chapter ? true : false}
											helperText={error.ata_chapter ? error.ata_chapter : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 15 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("ata_chapter", input)
													this.onRestErrorKey("ata_chapter")
												} else if (
													input.length > 15 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 15)
													this.onFieldChange("ata_chapter", truncatedInput)
													this.onRestErrorKey("ata_chapter")
												} else {
													e.preventDefault()
												}
											}}
											onKeyPress={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 15 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("ata_chapter", input)
													this.onRestErrorKey("ata_chapter")
												} else if (
													input.length > 15 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 15)
													this.onFieldChange("ata_chapter", truncatedInput)
													this.onRestErrorKey("ata_chapter")
												} else {
													e.preventDefault()
												}
											}}
											onPaste={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 15 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("ata_chapter", input)
													this.onRestErrorKey("ata_chapter")
												} else if (
													input.length > 15 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 15)
													this.onFieldChange("ata_chapter", truncatedInput)
													this.onRestErrorKey("ata_chapter")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required
											label="SB Reference Number"
											name="sb_ref_number"
											id="sb_ref_number"
											value={data.sb_ref_number}
											error={error.sb_ref_number ? true : false}
											helperText={error.sb_ref_number ? error.sb_ref_number : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("sb_ref_number", input)
													this.onRestErrorKey("sb_ref_number")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("sb_ref_number", truncatedInput)
													this.onRestErrorKey("sb_ref_number")
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="SB Issued by"
											name="sb_issued_by"
											id="sb_issued_by"
											value={data.sb_issued_by}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("sb_issued_by", input)
													this.onRestErrorKey("sb_issued_by")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("sb_issued_by", truncatedInput)
													this.onRestErrorKey("sb_issued_by")
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment>SB Applicability</React.Fragment>}
											name="sb_applicability"
											id="sb_applicability"
											value={data.sb_applicability}
											error={error.sb_applicability ? true : false}
											helperText={error.sb_applicability ? error.sb_applicability : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("sb_applicability", input)
													this.onRestErrorKey("sb_applicability")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("sb_applicability", truncatedInput)
													this.onRestErrorKey("sb_applicability")
												}
											}}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={12}>
										<TextField
											required={!smbcInstance}
											variant="outlined"
											label="SB Description"
											name="description"
											id="description"
											multiline
											minRows={5}
											value={data.description}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 255) {
													this.onFieldChange("description", e.target.value)
												} else {
													const truncatedInput = input.slice(0, 255)
													this.onFieldChange("description", truncatedInput)
													this.onRestErrorKey("description")
												}
											}}
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
										/>
									</Grid>
								</Grid>

								<div className="border-grey"></div>

								<Grid spacing={1} container>
									<Grid item xs={4}>
										<Grid container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Type
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														variant="outlined"
														value={data.type}
														onChange={this.dropDownChange}
													>
														<MenuItem value={"ALERT"}>Alert</MenuItem>
														<MenuItem value={"STANDARD"}>Standard</MenuItem>
														<MenuItem value={"OPTIONAL"}>Optional</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="SB Effectivity"
											name="effectivity"
											id="effectivity"
											value={data.effectivity}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("effectivity", input)
													this.onRestErrorKey("effectivity")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("effectivity", truncatedInput)
													this.onRestErrorKey("effectivity")
												}
											}}
										/>
									</Grid>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="effectivity_date"
												label={<React.Fragment>SB Effective Date</React.Fragment>}
												format={fieldDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.effectivity_date}
												error={error.effectivity_date ? true : false}
												helperText={error.effectivity_date ? error.effectivity_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("effectivity_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("effectivity_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>

								<Grid spacing={1} container>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="last_done_date"
												label="Last Done Date"
												format={fieldDateFormat}
												inputVariant="outlined"
												fullWidth
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.last_done_date}
												error={error.last_done_date ? true : false}
												helperText={error.last_done_date ? error.last_done_date : ""}
												onChange={(data, value) => this.onFieldChange("last_done_date", moment(data).format(backendDateFormat))}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FH"
											name="last_done_fh"
											id="last_done_fh"
											value={data.last_done_fh}
											error={error.last_done_fh ? true : false}
											helperText={error.last_done_fh ? error.last_done_fh : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fh", input)
													this.onRestErrorKey("last_done_fh")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fh", truncatedInput)
													this.onRestErrorKey("last_done_fh")
												} else {
													e.preventDefault()
												}
											}}
											onKeyPress={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fh", input)
													this.onRestErrorKey("last_done_fh")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fh", truncatedInput)
													this.onRestErrorKey("last_done_fh")
												} else {
													e.preventDefault()
												}
											}}
											onPaste={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fh", input)
													this.onRestErrorKey("last_done_fh")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fh", truncatedInput)
													this.onRestErrorKey("last_done_fh")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FC"
											name="last_done_fc"
											id="last_done_fc"
											value={data.last_done_fc}
											error={error.last_done_fc ? true : false}
											helperText={error.last_done_fc ? error.last_done_fc : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fc", input)
													this.onRestErrorKey("last_done_fc")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fc", truncatedInput)
													this.onRestErrorKey("last_done_fc")
												} else {
													e.preventDefault()
												}
											}}
											onKeyPress={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fc", input)
													this.onRestErrorKey("last_done_fc")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fc", truncatedInput)
													this.onRestErrorKey("last_done_fc")
												} else {
													e.preventDefault()
												}
											}}
											onPaste={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("last_done_fc", input)
													this.onRestErrorKey("last_done_fc")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("last_done_fc", truncatedInput)
													this.onRestErrorKey("last_done_fc")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
								</Grid>

								<div className="border-grey"></div>
								{/* copied */}
								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label>INTERVAL</label>
										<Grid spacing={2} container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Compliance Period
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="sb_interval_type"
														variant="outlined"
														value={data.sb_interval_type}
														onChange={this.dropDownChange1}
													>
														<MenuItem value={"D"}>Days</MenuItem>
														<MenuItem value={"FH"}>FH</MenuItem>
														<MenuItem value={"FC"}>FC</MenuItem>
														<MenuItem value={"FC,D"}>FC & D</MenuItem>
														<MenuItem value={"FH,D"}>FH & D</MenuItem>
														<MenuItem value={"FC,FH,D"}>FC,FH & D</MenuItem>
														<MenuItem value={"FC,FH"}>FC & FH</MenuItem>

													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid id="dimensions-input" item xs={3}>
										<Grid spacing={2} container>
											{data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
												item
												xs={data.sb_interval_type == "FC" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
													name="sb_interval"
													id="sb_interval"
													value={data.dimension_interval_fc}
													InputLabelProps={{ shrink: true }}
													onChange={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("dimension_interval_fc", input)
															this.onRestErrorKey("dimension_interval_fc")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval_fc", truncatedInput)
															this.onRestErrorKey("dimension_interval_fc")
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onKeyPress={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("dimension_interval_fc", input)
															this.onRestErrorKey("dimension_interval_fc")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval_fc", truncatedInput)
															this.onRestErrorKey("dimension_interval_fc")
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onPaste={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("dimension_interval_fc", input)
															this.onRestErrorKey("dimension_interval_fc")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval_fc", truncatedInput)
															this.onRestErrorKey("dimension_interval_fc")
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														}
													}}
													autoComplete="off"
													error={error.dimension_interval_fc ? true : false}
													helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
												/>
											</Grid> : null}
											{data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
												item
												xs={data.sb_interval_type == "FH" ? 12 : data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
													name="sb_interval"
													id="sb_interval"
													value={data.sb_interval}
													InputLabelProps={{ shrink: true }}
													onChange={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("sb_interval", input)
															this.onRestErrorKey("sb_interval")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("sb_interval", truncatedInput)
															this.onRestErrorKey("sb_interval")
														} else {
															e.preventDefault()
															this.setState({ error: { sb_interval: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onKeyPress={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("sb_interval", input)
															this.onRestErrorKey("sb_interval")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("sb_interval", truncatedInput)
															this.onRestErrorKey("sb_interval")
														} else {
															e.preventDefault()
															this.setState({ error: { sb_interval: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onPaste={(e) => {
														const input = e.target.value
														if (
															regexConstants.onlyNumeric.test(input) &&
															input.length <= 5
														) {
															this.onFieldChange("sb_interval", input)
															this.onRestErrorKey("sb_interval")
														} else if (
															regexConstants.onlyNumeric.test(input) &&
															input.length > 5
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("sb_interval", truncatedInput)
															this.onRestErrorKey("sb_interval")
														} else {
															e.preventDefault()
															this.setState({ error: { sb_interval: "Please enter a 5 digit integer value.", }, })
														}
													}}
													autoComplete="off"
													error={error.sb_interval ? true : false}
													helperText={error.sb_interval ? error.sb_interval : ""}
												/>
											</Grid> : null}
											{data.sb_interval_type == "D" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" ||
												data.sb_interval_type == "FH,D" ? (
												<Grid item
													xs={data.sb_interval_type == "D" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FH,D" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
												>
													<TextField
														variant="outlined"
														required={!smbcInstance}
														label="Days"
														name="dimension_interval_days"
														id="dimension_interval_days"
														value={data.dimension_interval_days}
														InputLabelProps={{ shrink: true }}
														onChange={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange("dimension_interval_days", truncatedInput)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															} else {
																e.preventDefault()
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															}
														}}
														onKeyPress={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange(
																	"dimension_interval_days",
																	truncatedInput
																)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															} else {
																e.preventDefault()
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															}
														}}
														onPaste={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange("dimension_interval_days", truncatedInput)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({
																	error: {
																		dimension_interval_days:
																			"Please enter a 5 digit integer value.",
																	},
																})
															} else {
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
																e.preventDefault()
															}
														}}
														autoComplete="off"
														error={error.dimension_interval_days ? true : false}
														helperText={
															error.dimension_interval_days
																? error.dimension_interval_days
																: ""
														}
													/>
												</Grid>
											) : null}
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>REMAINING</label>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={data.sb_interval_type === "D" ? "Days" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" :
														data.sb_interval_type === "FH,D" ? "FH/Days" : data.sb_interval_type === "FC,D" ? "FC/Days" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Days" : data.sb_interval_type === "FC,FH" ? "FC/FH" : "Days"}
													name="remaining_val"
													id="remaining_val"
													value={this.remainingVal()}
													onChange={(e, value) => this.onFieldChange("remaining_val", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>NEXT DUE </label>
										<Grid spacing={2} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={data.sb_interval_type === "D" ? "Date" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" : data.sb_interval_type === "FH,D" ? "FH/Date" :
														data.sb_interval_type === "FC,D" ? "FC/Date" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Date" : data.sb_interval_type === "FC,FH" ? "FC/FH " : "Date"}
													name="ad_due"
													id="ad_due"
													value={this.nextDueValue()}
													onChange={(e, value) => this.onFieldChange("ad_due", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

								<div className="border-grey"></div>

								<Grid spacing={1} container>
									<Grid item xs={3}>
										<Grid container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Compliance Status{" "}
														<span style={{ color: "red" }}> *</span>
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														variant="outlined"
														value={data.sb_compliance_status}
														onChange={this.dropDownChange2}
													>
														<MenuItem value={"OPEN"}>OPEN</MenuItem>
														<MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
														<MenuItem value={"FACTORY COMPLIED"}>
															FACTORY COMPLIED
														</MenuItem>
														<MenuItem value={"PARTIALLY COMPLIED"}>
															PARTIALLY COMPLIED
														</MenuItem>
														<MenuItem value={"INFORMATION ONLY"}>
															INFORMATION ONLY
														</MenuItem>
														<MenuItem value={"CLOSED"}>CLOSED</MenuItem>
														<MenuItem value={"NOT APPLICABLE"}>
															NOT APPLICABLE
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> SB Compliance TSN {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
											name="sb_compliance_tsn"
											id="sb_compliance_tsn"
											value={data.sb_compliance_tsn}
											disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
											error={error.sb_compliance_tsn ? true : false}
											helperText={error.sb_compliance_tsn ? error.sb_compliance_tsn : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("sb_compliance_tsn", input)
													this.onRestErrorKey("sb_compliance_tsn")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("sb_compliance_tsn", truncatedInput)
													this.onRestErrorKey("sb_compliance_tsn")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> SB Compliance CSN {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
											name="sb_compliance_csn"
											id="sb_compliance_csn"
											value={data.sb_compliance_csn}
											error={error.sb_compliance_csn ? true : false}
											disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
											helperText={error.sb_compliance_csn ? error.sb_compliance_csn : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("sb_compliance_csn", input)
													this.onRestErrorKey("sb_compliance_csn")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("sb_compliance_csn", truncatedInput)
													this.onRestErrorKey("sb_compliance_csn")
												} else {
													e.preventDefault()
												}
											}}
											onKeyPress={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("sb_compliance_csn", input)
													this.onRestErrorKey("sb_compliance_csn")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("sb_compliance_csn", truncatedInput)
													this.onRestErrorKey("sb_compliance_csn")
												} else {
													e.preventDefault()
												}
											}}
											onPaste={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("sb_compliance_csn", input)
													this.onRestErrorKey("sb_compliance_csn")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange(
														"sb_compliance_csn",
														truncatedInput
													)
													this.onRestErrorKey("sb_compliance_csn")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
									<Grid item xs={3} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="sb_compliance_date"
												label={<React.Fragment> SB Compliance Date {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												format={fieldDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.sb_compliance_date}
												error={error.sb_compliance_date ? true : false}
												helperText={error.sb_compliance_date ? error.sb_compliance_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("sb_compliance_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("sb_compliance_date")
												}}
												onFocusCapture={() => this.onFocusValidation("sb_compliance_date")}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>

								<Grid spacing={1} container>
									{smbcInstance ? <Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Category"
											value={data.sb_category}
											margin="normal"
											onChange={(e) => this.onFieldChange("sb_category", e.target.value)}
										/>
									</Grid> :
										<Grid item xs={4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> Service Bulletin <span style={{ color: "red" }}> *</span> </React.Fragment>}
												name="service_bulletin"
												id="service_bulletin"
												value={data.service_bulletin}
												error={error.service_bulletin ? true : false}
												helperText={error.service_bulletin ? error.service_bulletin : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (input.length <= 20) {
														this.onFieldChange("service_bulletin", input)
														this.onRestErrorKey("service_bulletin")
													} else {
														const truncatedInput = input.slice(0, 20)
														this.onFieldChange("service_bulletin", truncatedInput)
														this.onRestErrorKey("service_bulletin")
													}
												}}
											/>
										</Grid>
									}
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Revision"
											id="revision"
											required={!smbcInstance}
											value={data.revision}
											error={error.revision ? true : false}
											helperText={error.revision ? error.revision : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 10) {
													this.onFieldChange("revision", input)
													this.onRestErrorKey("revision")
												} else {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("revision", truncatedInput)
													this.onRestErrorKey("revision")
												}
											}}
										/>
									</Grid>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="revision_date"
												required={!smbcInstance}
												label="Revision Date"
												format={fieldDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.revision_date}
												error={error.revision_date ? true : false}
												helperText={error.revision_date ? error.revision_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("revision_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("revision_date")
												}}

											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>

								<Grid spacing={1} container>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="AD"
											name="ad_no"
											id="ad_no"
											value={data.ad_no}
											error={error.ad_no ? true : false}
											helperText={error.ad_no ? error.ad_no : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("ad_no", input)
													this.onRestErrorKey("ad_no")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("ad_no", truncatedInput)
													this.onRestErrorKey("ad_no")
												}
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Subject"
											name="subject"
											id="subject"
											value={data.subject}
											error={error.subject ? true : false}
											helperText={error.subject ? error.subject : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 30) {
													this.onFieldChange("subject", input)
													this.onRestErrorKey("subject")
												} else {
													const truncatedInput = input.slice(0, 30)
													this.onFieldChange("subject", truncatedInput)
													this.onRestErrorKey("subject")
												}
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label={<React.Fragment>Work Order</React.Fragment>}
											name="workorder"
											id="workorder"
											value={data.workorder}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 25) {
													this.onFieldChange("workorder", input)
													this.onRestErrorKey("workorder")
												} else {
													const truncatedInput = input.slice(0, 25)
													this.onFieldChange("workorder", truncatedInput)
													this.onRestErrorKey("workorder")
												}
											}}
											error={error.workorder ? true : false}
											helperText={error.workorder ? error.workorder : ""}
										/>
									</Grid>
								</Grid>

								<div className="border-grey"></div>
								<Grid id="data-container" spacing={3} container>
									<Grid item xs={3}>
										<label>SB Compliance DFP</label>
										<FormControl
											error={error.sb_compliance_dfp ? true : false}
											helperText={
												error.sb_compliance_dfp ? error.sb_compliance_dfp : ""
											}
										>
											<RadioGroup
												required
												row
												aria-label="sb_compliance_dfp"
												name="sb_compliance_dfp"
												defaultValue="top"
												value={data.sb_compliance_dfp}
												onChange={(e, value) => this.onFieldChange("sb_compliance_dfp", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
											{error.sb_compliance_dfp && (
												<FormLabel component="sb_compliance_dfp">
													<span style={{ color: "red" }}>
														{error.sb_compliance_dfp}
													</span>
												</FormLabel>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={3}>
										<label>Release Certificate</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="release_certificate"
												name="release_certificate"
												defaultValue="top"
												value={data.release_certificate}
												onChange={(e, value) => this.onFieldChange("release_certificate", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={3}>
										<label>Certificate of Conformity</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="certificate_of_conformity"
												name="certificate_of_conformity"
												defaultValue="top"
												value={data.certificate_of_conformity}
												onChange={(e, value) => this.onFieldChange("certificate_of_conformity", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								<div className="border-grey"></div>

								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextareaAutosize
											aria-label="minimum height"
											placeholder="Remark"
											name="remark"
											id="remark"
											style={{ height: "70px", width: "560px", padding: '3px' }}
											value={data.remark ? data.remark : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 255) {
													this.onFieldChange("remark", e.target.value)
												} else {
													const truncatedInput = input.slice(0, 255)
													this.onFieldChange("remark", truncatedInput)
													this.onRestErrorKey("remark")
												}
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid spacing={1} container>
										{this.props.actionType === 'add' ?
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>
											:
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.files?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button type="button" className="btn btn-primary" onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)} >
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>
								{this.props.actionType === "add" ? (
									<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<SbConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() =>
										this.deleteAttachmentFile(this.state.deleteAttachmentId)
									}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="sb"
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									uploadFileInfo={data}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="sb"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default SbTaskForm
